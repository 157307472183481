import React from "react";
import fblogo from "../../assets/images/fb.png";
import twitterlogo from "../../assets/images/twitter.png";
import linklogo from "../../assets/images/linkedIn.png";

import "./app-footer.css";
const AppFooter = () => {
  return (
    <footer className="ibm__app-bar do-not-print">
      <div className="ibm__app-bar-top">
        <div className="ibm__max-width">
          <div className="ibm__app-bar-section">
            <div className="ibm__app-bar-header" role="main">
              &copy; {new Date().getFullYear()} | My Course Planner. All Rights Reserved
            </div>
          </div>
          <div className="ibm__app-bar-section">
            <div className="ibm__app-bar-section">
              <a href=" https://www.facebook.com/unimelb">
                <img src={fblogo} alt="Facebook Page" className="ibm__app-bar-footerlogo" />
              </a>
              <a href="https://twitter.com/unimelb">
                <img src={twitterlogo} alt="Twitter Page" className="ibm__app-bar-footerlogo" />
              </a>
              <a href="https://www.linkedin.com/school/university-of-melbourne/">
                <img src={linklogo} alt="LinkedIn Page" className="ibm__app-bar-footerlogo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
