import { zipObject } from "lodash";
import { ComponentType, IComponent, ILimits, IMMSLimits } from "store/types";

const isSelected = (mms: IComponent, selectedMMSIds: string[]) =>
  !!selectedMMSIds.find((mmsRecordId) => mmsRecordId === mms.recordId);
const withinLimits = (n: number, limits?: ILimits) => n <= (limits?.maximum ?? Number.MAX_SAFE_INTEGER);

const count = (components: IComponent[], type: ComponentType) => components.filter((mms) => mms.type === type).length;

export const isMMSEnabled = (
  mms: IComponent,
  allowedComponents: IComponent[],
  selectedMMS: string[],
  mmsLimits: IMMSLimits,
) => {
  // If it's selected - always enable, so we can deselect it
  if (isSelected(mms, selectedMMS)) {
    return true;
  }

  // Hash to look up more easily
  const componentsMap = zipObject(
    allowedComponents.map((c) => c.recordId),
    allowedComponents,
  );

  // Is mms a formal specialisation under a major/minor?
  const parent = componentsMap[mms.parent?.recordId];
  const siblingSpecialisations = !parent
    ? []
    : allowedComponents.filter((c) => c.parent?.recordId === parent?.recordId);

  // If another formal specialisation of the same major is selected - disable
  if (siblingSpecialisations.find((sibling) => isSelected(sibling, selectedMMS))) {
    return false;
  }

  // Which mmses will be selected, if this is one gets selected
  const postAssignmentMMS = [mms, parent, ...selectedMMS.map((mmsId) => componentsMap[mmsId])].filter(Boolean);

  // Out of that - remove formal specialisations (i.e. which have a component parent)
  const postAssignmentTopLevelMMS = postAssignmentMMS.filter(
    (mms) =>
      !componentsMap[mms.parent?.recordId] && mms.type !== ComponentType.ENTRY && mms.type !== ComponentType.STREAM,
  );

  return (
    withinLimits(postAssignmentTopLevelMMS.length, mmsLimits) &&
    withinLimits(count(postAssignmentTopLevelMMS, ComponentType.MAJOR), mmsLimits?.majors) &&
    withinLimits(count(postAssignmentTopLevelMMS, ComponentType.MINOR), mmsLimits?.minors) &&
    withinLimits(count(postAssignmentTopLevelMMS, ComponentType.INFORMAL_SPEC), mmsLimits?.informalSpecs)
  );
};
