import { Theme } from "@mui/material/styles";
import { brandBlue, lightBlue } from "./colors";
import styled from "styled-components";
import { color } from "csx";

export const commonStyles = (theme: Theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  blueButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    justifyContent: "space-between",
    width: "150px",
    paddingLeft: "10px",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  link: {
    margin: "0 auto",
    display: "inline-flex",
    width: "fit-content",
    color: theme.palette.primary.main,
  },
  linkIcon: {
    marginLeft: "8px",
    fill: theme.palette.primary.main,
  },
  warningChip: {
    background: theme.palette.warning.main,
    color: theme.palette.common.white,
  },
  subjectTableOutlined: {
    border: lightBlue.toString(),
    borderStyle: "solid",
    borderWidth: "1px",
    marginTop: "14px",
    marginBottom: "14px",
  },
  subjectTableCellOutlined: {
    border: lightBlue.toString(),
    borderStyle: "solid",
    borderWidth: "1px",
  },
  subjectTable: {
    marginBottom: "1rem",
    marginTop: "0.5rem",
  },
  subjectTableRow: {
    height: "66px",
  },
  subjectTableName: {
    color: brandBlue.toString(),
    fontWeight: "bold",
  },
  drawerPaper: {
    width: "min(30%, 500px)",
    padding: "2rem",
    background: "#f7f7f7",
    height: "calc(100% - 4rem)",
  },
});

export const useSearchStyles = (theme: Theme) => ({
  appBar: {
    position: "relative",
    root: {
      paddingRight: "0px",
    },
  },
  textField: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.white,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.common.white,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.common.white,
      },
    },
  },
  textFieldInputRoot: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    // borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    // "&:hover": {
    //   borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    // },
    // "&:focus": {
    //   borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    // },
    "& input::placeholder": {
      color: theme.palette.primary.contrastText,
      fontStyle: "italic",
      opacity: 0.85,
    },
  },
  adornment: {},
  icon: {
    fill: theme.palette.primary.contrastText,
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
});

export const useSearchDrawerStyles = () => ({
  searchDrawerPaper: {
    width: "min(35%, 600px)",
    padding: "0rem 0rem 0.5rem 0rem",
    background: "#f7f7f7",
    height: "calc(100% - 0.5rem)",
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    padding: "0px 0px 0px 2px",
    margin: "0px",
  },
});

export const useFabStyles = (theme: Theme, props: any) => ({
  fab: {
    position: "fixed",
    [theme.breakpoints.up("xs")]: {
      right: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      right: "calc(4vw + 1rem)",
    },
    [theme.breakpoints.up("md")]: {
      right: "calc(4vw + 1rem)",
    },
    [theme.breakpoints.up("lg")]: {
      right: "calc(8vw + 1rem)",
    },
    [theme.breakpoints.up("xl")]: {
      right: "calc(16vw + 2rem)",
    },
    bottom: `${(props as any)?.bottomOffset ?? "2rem"}`,
    // background: "#012a58",
    background: `${(props as any)?.backgroundColor ?? "#012a58"}`,
    "&:hover": {
      backgroundColor: `${color((props as any)?.backgroundColor ?? "#012a58")
        .darken(0.1)
        .toHexString()}`,
      //color().darken(0.1).toHexString(),
    },
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  fabIcon: {
    fill: theme.palette.common.white,
    marginRight: "0.25rem",
  },
});

export const SearchDrawerPaddedContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const SearchDrawerResultsContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  height: 100%;
`;

export const SearchDrawerResultsHeaderContainer = styled.div`
  width: calc(100% - 6px);
  margin: 0px;
  padding: 0px;
`;
