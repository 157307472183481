import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IProps {
  open: boolean;
  onAccept: () => any;
  onCancel: () => any;
}

export const AutoAssignCoreDialog = React.memo(({ open, onAccept, onCancel }: IProps) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="add-core-subjects-dialog-title"
    aria-describedby="add-core-subjects-dialog-description"
  >
    <DialogTitle id="add-core-subjects-dialog-title">Add core subjects?</DialogTitle>
    <DialogContent>
      <DialogContentText id="add-core-subjects-dialog-description">
        Would you like to add all Core subjects to your plan?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant="contained">
        Cancel
      </Button>
      <Button onClick={onAccept} color="primary" variant="contained" autoFocus>
        Agree
      </Button>
    </DialogActions>
  </Dialog>
));
