import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";
import { IState, TemplateStage } from "store/types";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { validateValuePresence } from "lib/validation";
import { ICreatePlanPayload, createEnrollment } from "actions/enrollment";
import { CreatePlanForm } from "./CreatePlanForm";
import { useNavigate } from "@reach/router";
import { featureToggles } from "../config/featureToggles";
import { getAllCompEligibility, getAllDiscontinuedMMS } from "../actions/admin";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { defaultTheme } from "theme/material";

const H1 = styled.h1`
  text-align: center;
  font-size: xxx-large;
`;
const HelpVideoHint = styled.p`
  display: flex;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  margin-bottom: 6px;
  color: ${defaultTheme.palette.grey[700]};
  i {
    font-size: 8px;
  }
`;
const OuterGrid = styled(Grid)`
  padding: 2.5vh 1rem 1rem 1rem;
  background-color: #f7f7f7;
  justify-content: center;
`;

const validateFormState = (values: ICreatePlanPayload) => {
  const errors: any = {};
  validateValuePresence(errors, values, "planCourse");
  validateValuePresence(errors, values, "planName");
  validateValuePresence(errors, values, "planYear");
  return errors;
};

interface IProps {
  path?: string;
  mode?: "plan" | "template";
}

export const CreatePlan = (props: IProps) => {
  const config = useSelector((state: IState) => state.planCreationConfig);
  const admin = useSelector((state: IState) => state.admin);
  const mode = props.mode ?? "plan";
  const authorisedCourseCodes = admin?.authorisedCourseCodes;
  const role = admin?.role;

  const dispatch = useDispatch();

  useEffect(() => {
    if (featureToggles.componentEligibility) {
      dispatch(getAllCompEligibility());
    }
    if (featureToggles.discontinuedMMS) {
      dispatch(getAllDiscontinuedMMS());
    }
  }, [dispatch]);
  // Sanity check
  if (!admin && mode === "template") {
    throw new Error(`Only admin can create templates`);
  }

  const initialValues = useMemo(
    () =>
      ({
        planName: mode === "plan" ? "My Course Plan" : "Standard",
        planCourse: "",
        planCourseLevel: "",
        planYear: "",
        yearIntake: "",
        isMidyearEntry: false,
        streamRecordId: "",
        entryRecordId: "",
      } as ICreatePlanPayload),
    [mode],
  );

  const navigate = useNavigate();
  const onSubmit = useCallback(
    (values: ICreatePlanPayload, helpers: FormikHelpers<ICreatePlanPayload>) => {
      const payload: ICreatePlanPayload = { ...values };
      if (mode === "template") {
        payload.template = {
          user: admin?.user,
          state: TemplateStage.DRAFT,
        };
      }
      payload.isNewCreation = true;
      const submitResult = dispatch(createEnrollment(payload as ICreatePlanPayload)) as any;
      submitResult
        .then((response: any) => {
          navigate(
            mode === "plan"
              ? `/plan/${response.action.payload.plan.id}`
              : `/staff/template/${response.action.payload.plan.id}`,
          );
        })
        .catch(() => helpers.setSubmitting(false));
    },
    [admin?.user, dispatch, mode, navigate],
  );

  if (!config) {
    return null;
  }

  return (
    <OuterGrid container spacing={0} alignItems="center">
      <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
        <H1>Create a new {mode}</H1>

        <HelpVideoHint>
          <PlayCircleOutlineIcon fontSize="small" />
          <span>
            First time using My Course Planner? Please watch our{" "}
            <a
              href="https://students.unimelb.edu.au/your-course/manage-your-course/planning-your-course-and-subjects/faculty-course-planning-resources/my-course-planner"
              target="_"
            >
              {/* <YouTubeIcon fontSize="small" /> */}
              introductory video
            </a>
            .
          </span>
        </HelpVideoHint>

        <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
          <CreatePlanForm config={config} mode={mode} role={role} authorisedCourseCodes={authorisedCourseCodes} />
        </Formik>
      </Grid>
    </OuterGrid>
  );
};
