import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface IProps {
  open: boolean;
  close: () => any;
  onChangeOverload: () => any;
}

export const OverloadDialog = ({ open, close, onChangeOverload }: IProps) => {
  return (
    <Dialog open={open} onClose={close} aria-describedby="diploma-dialog-description" maxWidth="sm" fullWidth>
      <DialogTitle id="overload-plan-dialog-title">Overload</DialogTitle>
      <DialogContent>
        <p>
          If you want to enrol in more subjects than the standard amount in a semester, you will need to apply to{" "}
          <a
            href="https://students.unimelb.edu.au/your-course/manage-your-course/planning-your-course-and-subjects/study-load/overloading"
            target="_blank"
            rel="noreferrer"
          >
            overload
          </a>
          &nbsp;.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={onChangeOverload}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
