interface IFeatureToggles {
  discoverySimilarSubjects?: boolean;
  dynamicYearIntake?: boolean;
  templateAuth: boolean;
  secondaryMMSColour?: boolean;
  highlightSubjectsFromChecklist?: boolean;
  editNotes?: boolean;
  refreshButton?: boolean;
  allowBreadthSubjectMMSAssignment?: boolean;
  removeCourse?: boolean;
  eligibilityRule?: boolean;
  componentEligibility?: boolean;
  allowStandAloneDiploma?: boolean;
  semesterOverloads?: boolean;
  sampleTemplate?: boolean;
  planAuth?: boolean;
  siteCrawl?: boolean;
  streamAndEntry?: boolean;
  discontinuedMMS?: boolean;
}

const allFeatureToggles: Record<string, IFeatureToggles> = {
  localDev: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: true,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: true,
    semesterOverloads: true,
    sampleTemplate: true,
    streamAndEntry: true,
    discontinuedMMS: true,
  },
  test: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: true,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: false,
    semesterOverloads: true,
    sampleTemplate: true,
    planAuth: true,
    siteCrawl: true,
    streamAndEntry: true,
    discontinuedMMS: true,
  },
  staging: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: false,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    allowStandAloneDiploma: false,
    semesterOverloads: true,
    sampleTemplate: true,
    planAuth: true,
    siteCrawl: true,
    streamAndEntry: true,
    discontinuedMMS: true,
  },
  production: {
    discoverySimilarSubjects: true,
    dynamicYearIntake: true,
    templateAuth: false,
    secondaryMMSColour: true,
    highlightSubjectsFromChecklist: true,
    editNotes: true,
    refreshButton: true,
    allowBreadthSubjectMMSAssignment: true,
    removeCourse: true,
    eligibilityRule: true,
    componentEligibility: true,
    semesterOverloads: true,
    sampleTemplate: true,
    streamAndEntry: true,
    discontinuedMMS: true,
  },
};
allFeatureToggles["default"] = allFeatureToggles.development;

const { DEPLOYMENT_ENVIRONMENT } = ((window ?? {}) as any).env ?? {};
const { NODE_ENV } = process.env;
const envKey = NODE_ENV === "development" ? "localDev" : DEPLOYMENT_ENVIRONMENT!;

export const featureToggles = allFeatureToggles[envKey];
