import * as React from "react";
import { useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

interface IProps {
  field: any;
  form: any;
}

const toolBarOptions = {
  options: ["inline", "link", "blockType", "fontSize", "textAlign", "history"],
  inline: {
    inDropdown: true,
    options: ["bold", "italic", "underline", "strikethrough"],
  },
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
  },
  textAlign: { inDropdown: true },
  link: { inDropdown: true },
  history: { inDropdown: true },
  fontSize: {
    options: [12, 14, 16, 18, 24, 30],
  },
};

const WYSIWYGEditor = ({ field, form }: IProps) => {
  const contentBlock = htmlToDraft(field.value);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    let fieldValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    //Editor output <p></p> at empty stage
    if (editorState.getCurrentContent().getPlainText() === "") {
      fieldValue = "";
    }
    form.setFieldValue(field.name, fieldValue);
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName="wrapper-class"
      toolbar={toolBarOptions}
    />
  );
};

export default WYSIWYGEditor;
