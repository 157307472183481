import { IComponent, ISlot, IState, ISubject } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import { ListItemIcon, ListItemText } from "@mui/material";
import Done from "@mui/icons-material/Done";
import React, { useCallback, useMemo } from "react";
import ListIcon from "@mui/icons-material/List";
import {
  getGroupedComponents,
  getMajorsAndMinors,
  getMMSWithSpecialisationsForSubject,
  getSpecialisations,
} from "../../../lib/components";
import { useDispatch, useSelector } from "react-redux";
import { changeAssignment } from "../../../actions/enrollment";

interface IProps {
  subject: ISubject | undefined;
  selectedSubjectRecordId: string | undefined;
  selectedCoursesIds: Array<string>;
  selectedComponents: IComponent[] | [];
  subjectMeta: any;
  slot: ISlot;
}

export const AssignMMSSubMenu = (props: IProps) => {
  const { selectedComponents, subject, selectedSubjectRecordId, subjectMeta, slot } = props;
  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const planId = plan?.id;
  const slotMMSRecordId = slot?.mmsRecordId;

  const mmsAssignableSubjects = useSelector((s: IState) => s.enrollment?.validation?.mmsAssignableSubjects || {});

  const groupedComponents = getGroupedComponents(selectedComponents);
  const majorsAndMinors = useMemo(() => getMajorsAndMinors(groupedComponents), [groupedComponents]);
  const specialisations = useMemo(() => getSpecialisations(groupedComponents), [groupedComponents]);

  const majorsAndMinorsWithSpecialisations = useMemo(() => {
    return subject?.recordId
      ? getMMSWithSpecialisationsForSubject(majorsAndMinors, specialisations, subject.recordId, mmsAssignableSubjects)
      : [];
  }, [majorsAndMinors, mmsAssignableSubjects, specialisations, subject?.recordId]);

  const dispatch = useDispatch();

  const doChangeMMS = useCallback(
    (mmsRecordId: string) => () => {
      if (selectedSubjectRecordId) {
        dispatch(
          changeAssignment(
            planId || "",
            selectedSubjectRecordId,
            // MMS subjects can't be cross credit or diploma
            [plan?.courseRecordId ?? ""].filter(Boolean),
            mmsRecordId === slotMMSRecordId ? null : mmsRecordId,
            subjectMeta?.canBeDiscipline,
          ),
        );
      }
    },
    [selectedSubjectRecordId, dispatch, planId, slotMMSRecordId, plan?.courseRecordId, subjectMeta?.canBeDiscipline],
  );

  return (
    <>
      {
        <SubMenu
          key={`mms-menu-${selectedSubjectRecordId}`}
          label={() => (
            <>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Assign to Major, Minor or Specialisation" />
            </>
          )}
        >
          {majorsAndMinorsWithSpecialisations.map((mms) =>
            mms.children.length > 0 ? (
              mms.children.map((s: IComponent) => (
                <MenuItem
                  aria-pressed={slot.mmsRecordId === s.recordId}
                  checked={slot.mmsRecordId === s.recordId}
                  type="checkbox"
                  key={s.recordId}
                  onClick={doChangeMMS(s.recordId)}
                >
                  <ListItemIcon>{slot.mmsRecordId === s.recordId && <Done />}</ListItemIcon>
                  <ListItemText primary={s.name} />
                </MenuItem>
              ))
            ) : (
              <MenuItem
                aria-pressed={slot.mmsRecordId === mms.recordId}
                checked={slot.mmsRecordId === mms.recordId}
                type="checkbox"
                key={mms.recordId}
                onClick={doChangeMMS(mms.recordId)}
              >
                <ListItemIcon>{slot.mmsRecordId === mms.recordId && <Done />}</ListItemIcon>
                <ListItemText primary={`${mms.name}, ${mms.type}`} />
              </MenuItem>
            ),
          )}
        </SubMenu>
      }
    </>
  );
};
