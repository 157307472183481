import { get } from "lib/fetch";

export const GET_CONFIG = "planCreationConfig/GET";
export const CHANGE_COURSE = "planCreationConfig/GET_ALLOWED_YEARS";
export const CHANGE_YEAR = "planCreationConfig/GET_ALLOWED_COMPONENTS";
export const GET_ALL_MMS = "planCreationConfig/GET_ALL_MMS";

export const getConfig = () => {
  return {
    type: GET_CONFIG,
    payload: get("/v1/planCreationConfig"),
  };
};

export const getAllowedMMS = (selectedCourseId: string, selectedYear: number) => {
  return {
    type: CHANGE_YEAR,
    payload: get(`/v1/planCreationConfig/allowedComponents?recordId=${selectedCourseId}&year=${selectedYear}`).then(
      (componentsForSelectedCourse) => ({ componentsForSelectedCourse, selectedCourseId, selectedYear }),
    ),
  };
};

export const getAllMMS = () => {
  return {
    type: GET_ALL_MMS,
    payload: get(`/v1/planCreationConfig/allComponents`).then((allComponents) => ({
      allComponents,
    })),
  };
};
