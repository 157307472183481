import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/types";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { ResponsiveCenter } from "components/spinner/Center";
import { orderBy } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { DefaultColumnFilterDefinition, FilterTypes, NoFilter } from "./table";
import { AdminAppHeader } from "containers/app-bar/AdminAppBar";
import { Aligned } from "components/aligned/alignedDiv";
import { getAllCompEligibility, getAllDiscontinuedMMS } from "actions/admin";
import { DeleteEligibilityDialog } from "../eligibility/DeleteEligibilityDialog";
import { EditEligibilityDialog } from "../eligibility/EditEligibilityDialog";
import { AddEligibilityDialog } from "../eligibility/AddEligibilityDialog";
import { featureToggles } from "../../config/featureToggles";
import AddOutlined from "@mui/icons-material/AddOutlined";

interface IProps {
  path?: string;
  id?: string;
  children?: any | null;
}

const useStyles = (theme: Theme) => ({
  table: {
    marginTop: "2rem",
  },
  header: {
    "& strong": {
      fontSize: "110%",
    },
    whiteSpace: "nowrap",
  },
  span: {
    fontSize: "14px",
  },
  navIcon: {
    fill: theme.palette.common.black,
  },
  navButton: {
    color: theme.palette.common.black,
    marginTop: "24px",
  },
});

export const Eligibility = (_props: IProps) => {
  const admin = useSelector((state: IState) => state.admin);
  const theme = useTheme();
  const styles = useStyles(theme);

  const eligibilites = useMemo(() => admin?.compEligibilities ?? [], [admin?.compEligibilities]);
  const data = useMemo(() => orderBy(eligibilites ?? [], ["name"], ["asc"]), [eligibilites]);

  const noEligibilites = eligibilites.length === 0;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCompEligibility());
    if (featureToggles.discontinuedMMS) {
      dispatch(getAllDiscontinuedMMS());
    }
  }, [dispatch]);

  const [addEligibilityFlag, setAddEligibilityFlag] = useState<boolean>(false);
  const closeAddEligibilityDialog = useCallback(() => setAddEligibilityFlag(false), []);
  const openAddEligibilityDialog = useCallback(() => setAddEligibilityFlag(true), []);

  const [editEligibility, setEditEligibility] = useState<string | null>(null);
  const closeEligibilityDialog = useCallback(() => setEditEligibility(null), []);
  const openEditNotesDialog = useCallback((key: string) => setEditEligibility(key), []);

  const [deleteEligibility, setDeleteEligibility] = useState<string | null>(null);
  const closeDeleteDialog = useCallback(() => setDeleteEligibility(null), []);
  const openDeleteDialog = useCallback((id: string) => setDeleteEligibility(id), []);

  const columns: any[] = useMemo(
    () => [
      {
        Header: "Key",
        accessor: "displayKeyComp",
        sortable: true,
        filter: "includesIgnoreCase",
        minWidth: 150,
        Cell: (cellProps: any) => {
          const row = cellProps.row.original;
          const eligibility = eligibilites.find((k: any) => k.recordKey === row.recordKey);
          if (!eligibility.displayKeyComp?.course && !!eligibility.displayKeyComp?.mms?.name) {
            return <>{eligibility.displayKeyComp?.mms?.name}</>;
          }
          if (!!eligibility.displayKeyComp?.course?.name && !eligibility.displayKeyComp?.mms) {
            return <>{eligibility.displayKeyComp?.course?.name}</>;
          }
          if (!!eligibility.displayKeyComp?.course?.name && !!eligibility.displayKeyComp?.mms?.name) {
            return <>{`${eligibility.displayKeyComp?.course?.name}/${eligibility.displayKeyComp?.mms?.name}`}</>;
          }
          return "";
        },
      },
      {
        Header: "DisAllowed Components",
        accessor: "disAllowedComp",
        sortable: true,
        filter: "includesIgnoreCase",
        minWidth: 150,
        Cell: (cellProps: any) => {
          const row = cellProps.row.original;
          const eligibility = eligibilites.find((k: any) => k.recordKey === row.recordKey);
          if (!eligibility) {
            return "";
          }
          return (
            <>
              {eligibility?.disAllowedComp
                .map((c: any) => {
                  if (!!c.mms?.name && !!c.course?.name) {
                    return c.course.name + "/" + c.mms.name;
                  } else if (!c.course && !!c.mms?.name) {
                    return c.mms.name;
                  } else {
                    return c.course?.name;
                  }
                })
                .join(" | ")}
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "recordKey",
        sortable: false,
        Filter: NoFilter,
        width: 55,
        Cell: ({ value }: any) => (
          <Aligned>
            <Tooltip arrow title="Edit Eligibility" aria-label="Edit Eligibility" placement="top">
              <IconButton size="small" onClick={(_e: any) => openEditNotesDialog(value)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Delete Eligibility" aria-label="Delete Eligibility" placement="top">
              <IconButton size="small" onClick={(_e: any) => openDeleteDialog(value)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Aligned>
        ),
      },
    ],
    [eligibilites, openDeleteDialog, openEditNotesDialog],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilterDefinition,
      initialState: { pageIndex: 0, pageSize: 20 },
      filterTypes: FilterTypes,
    } as any,
    useFilters,
    useSortBy,
    usePagination,
  ) as any;

  const handleChangePage = useCallback((_e: any, newPage: number) => gotoPage(newPage), [gotoPage]);
  const handleChangeRowsPerPage = useCallback((e: any) => setPageSize(Number(e.target.value)), [setPageSize]);

  if (!admin) {
    return null;
  }
  return (
    <>
      <AdminAppHeader path="eligibility" />
      <ResponsiveCenter>
        <Aligned style={{ justifyContent: "right" }}>
          <Button
            sx={styles.navButton}
            variant="text"
            startIcon={<AddOutlined sx={styles.navIcon} fontSize="small" />}
            onClick={openAddEligibilityDialog}
          >
            Add&nbsp;Eligibility
          </Button>
        </Aligned>
        {noEligibilites ? (
          <p>No Eligibility available.</p>
        ) : (
          <div>
            <Table {...getTableProps()} sx={styles.table} size="small">
              <colgroup>
                {columns.map((c, i) => (
                  <col
                    key={i}
                    width={c.width ?? "auto"}
                    style={{
                      minWidth: c.minWidth,
                      maxWidth: c.maxWidth,
                    }}
                  />
                ))}
              </colgroup>
              <TableHead>
                {headerGroups.map((headerGroup: any, headerGroupIdx: number) => (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id ?? headerGroupIdx}
                    sx={styles.header}
                  >
                    {headerGroup.headers.map((column: any, thIdx: number) => {
                      return (
                        <TableCell
                          {...column.getHeaderProps(column.sortable ? column.getSortByToggleProps() : undefined)}
                          key={column.id ?? thIdx}
                        >
                          <strong>{column.render("Header")}</strong>
                          {column.sortable && (
                            <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"} />
                          )}

                          {/* Render the columns filter UI */}
                          {/* <div onClick={stopPropagationHandler}>
                        {(column as any).canFilter ? column.render("Filter") : null}
                      </div>*/}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {(page as any[]).map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} key={row.id ?? rowIndex}>
                      {row.cells.map((cell: any, cellIdx: number) => {
                        return (
                          <TableCell {...cell.getCellProps()} key={cellIdx}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </ResponsiveCenter>
      <AddEligibilityDialog open={!!addEligibilityFlag} close={closeAddEligibilityDialog} />
      <EditEligibilityDialog
        open={!!editEligibility}
        close={closeEligibilityDialog}
        eligibility={eligibilites.find((t) => t.recordKey === editEligibility)}
      />
      <DeleteEligibilityDialog
        open={!!deleteEligibility}
        close={closeDeleteDialog}
        eligibility={eligibilites.find((t) => t.recordKey === deleteEligibility)}
      />
    </>
  );
};
