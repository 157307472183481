import { Field, useFormikContext } from "formik";
import React from "react";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import { TextField } from "formik-mui";
import { Autocomplete } from "@mui/material";
import { FormControl } from "@mui/material";

interface IProps {
  index: number;
  courses: any;
  components: any;
}

function getYearList() {
  const createdDate = new Date("2019-01-01T04:22:22.148Z");
  const currentDate = new Date();
  const yearList = [];

  while (createdDate.getFullYear() <= currentDate.getFullYear() + 1) {
    yearList.push(createdDate.getFullYear().toString());
    createdDate.setFullYear(createdDate.getFullYear() + 1);
  }
  return yearList;
}

export const ValueGroup = (props: IProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const components = props.components;
  const years = getYearList();
  const index = props.index;

  return (
    <div>
      <InputLabel
        aria-label="Select year forward"
        htmlFor={`year-select-${index}`}
        id={`year-select-label-${index}`}
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        Select year forward
      </InputLabel>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          id={`year-select-${index}`}
          options={years}
          style={{ width: "100%" }}
          renderInput={(params: any) => (
            <Field component={TextField} {...params} name={`discontinuedMMS.${index}.year`} variant="outlined" />
          )}
          value={years.find((c: any) => {
            return c === values.discontinuedMMS[index].year ?? null;
          })}
          onChange={(_e: any, year: string | null) => {
            setFieldValue(`discontinuedMMS.${index}.year`, year ?? null);
          }}
        />
      </FormControl>
      <InputLabel
        aria-label="Select mms"
        htmlFor={`mms-select-${index}`}
        id={`mms-select-label-${index}`}
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        Select mms
      </InputLabel>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          id={`mms-select-${index}`}
          options={components.filter(
            (c: any) =>
              c.parentCourse.code === values.course?.code &&
              c.publishedYears.indexOf(values.discontinuedMMS[index].year) >= 0,
          )}
          getOptionLabel={(option: any) => `${option.name} (${option.code})`}
          style={{ width: "100%" }}
          renderInput={(params: any) => (
            <Field component={TextField} {...params} name={`discontinuedMMS.${index}`} variant="outlined" />
          )}
          value={components.find((c: any) => {
            return c.code === values.discontinuedMMS[index].code ?? null;
          })}
          onChange={(_e: any, mms: any | null) => {
            setFieldValue(
              `discontinuedMMS.${index}`,
              { name: mms?.name, code: mms?.code, year: values.discontinuedMMS[index].year } ?? null,
            );
          }}
        />
      </FormControl>
    </div>
  );
};
