import React, { useCallback, useMemo, useRef, useState } from "react";
import { ResponsiveCenter } from "components/spinner/Center";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ComponentType, HintToolTip, IComponent, ISecondaryCourseOption, IState, UserMode } from "store/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { ShareLinkModal } from "./ShareLinkModal";
import { ProgressChart } from "./ProgressChart";
import { Aligned } from "components/aligned/alignedDiv";
import { useMenuState } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import {
  getEntries,
  getGroupedComponents,
  getMajorsAndMinors,
  getNestedMMS,
  getSpecialisations,
  getStreams,
  isCourseLevelInformalSpec,
} from "lib/components";
import { Hint } from "./Hint";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { capitalize, uniq } from "lodash";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { featureToggles } from "../config/featureToggles";
import { ClearAllTemplateDialog } from "./planAction/ClearAllTemplateDialog";
import { allSelectedStudyAreaIds } from "lib/plan";
import { pickByIds } from "lib/recordIds";
import { PlanActionMenu } from "./planAction/menuItems/PlanActionMenu";

const Background = styled.div`
  background-color: #012a58;
  width: 100%;
`;
const H1 = styled.h1`
  margin-top: 0rem;
  color: black;
`;
const H3 = styled.h3`
  color: white;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0rem;
`;

const DontBreak = styled.span`
  display: inline-block;
  color: white;
`;

// Based on https://stackoverflow.com/questions/1367409/how-to-make-button-look-like-a-link
const MMSActionTitle = styled.button`
  border: none;
  color: #cbe8ef;
  border: none;
  padding: 0 !important;
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  perspective-origin: 0 0;
  text-align: start;
  text-decoration: underline;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  // -webkit-logical-height: 1em;
  -webkit-logical-width: auto;
`;

const useStyles = () => ({
  menuIcon: {
    fill: "white",
  },
  clearAllButton: {
    marginTop: "10px",
    marginBottom: "10px",
    textTransform: "none",
  },
});

const getDisplayMMSPlacholder = (
  allowedComponents: IComponent[],
  allowedSecondaryCourses: ISecondaryCourseOption[],
  selectedStudyAreas: IStudyArea[],
) => {
  const majorsAllowed = allowedComponents.find(({ type }) => type === ComponentType.MAJOR);
  const minorsAllowed = allowedComponents.find(({ type }) => type === ComponentType.MINOR);
  const specialisations = allowedComponents.find(({ type }) => type.match(/specialisation/i));

  const selectedComponents = selectedStudyAreas
    .map((area) => [...allowedComponents, ...allowedSecondaryCourses].find((c) => area.recordId === c.recordId))
    .filter(Boolean) as IComponent[];
  const selectedMajor = selectedComponents.find(({ type }) => type === ComponentType.MAJOR);
  if (selectedComponents.length > 0 && majorsAllowed && !selectedMajor) {
    return `Select Major`;
  }

  if (allowedComponents.length > 0 && selectedStudyAreas.length === 0) {
    if (majorsAllowed) {
      return `Select Major`;
    } else if (minorsAllowed) {
      return `Select Minor`;
    } else if (!majorsAllowed && !minorsAllowed && specialisations) {
      return `Select Specialisation`;
    }
  }
  return "";
};

// Course Unit is either an MMS or a course (e.g. diploma)
interface IStudyArea {
  recordId: string;
  name: string;
  children?: IStudyArea[];
}

export const AppSubheading = () => {
  const enrollment = useSelector((state: IState) => state.enrollment!);
  const { plan, allowedComponents } = enrollment;
  const { template } = plan;
  const allowedSecondaryCourses = useMemo(() => enrollment.allowedSecondaryCourses ?? [], [
    enrollment.allowedSecondaryCourses,
  ]);

  // Available MMSs
  const groupedComponents = getGroupedComponents(allowedComponents);
  const majorsAndMinors = useMemo(() => getMajorsAndMinors(groupedComponents), [groupedComponents]);
  const specialisations = useMemo(() => getSpecialisations(groupedComponents), [groupedComponents]);
  // One stream or entry per plan
  const allowedStreams = useMemo(() => getStreams(groupedComponents), [groupedComponents]);
  const allowedEntries = useMemo(() => getEntries(groupedComponents), [groupedComponents]);
  const stream = allowedStreams.find((s: IComponent) => plan.mmsRecordIds.indexOf(s.recordId) >= 0);
  const entry = allowedEntries.find((e: IComponent) => plan.mmsRecordIds.indexOf(e.recordId) >= 0);

  const showActionMenu = useMemo(
    () => majorsAndMinors.length > 0 || specialisations.length > 0 || allowedSecondaryCourses.length > 0,
    [majorsAndMinors, specialisations, allowedSecondaryCourses],
  );

  const majorsAndMinorsWithSpecialisations = useMemo(() => getNestedMMS(majorsAndMinors, specialisations), [
    majorsAndMinors,
    specialisations,
  ]);
  const majorsAndMinorsRecordIds = majorsAndMinors.map((s) => s.recordId);
  const courseLevelSpecialisations = specialisations.filter(
    (s) => !majorsAndMinorsRecordIds.includes(s.parentRecordId) && !isCourseLevelInformalSpec(s),
  );

  // What's selected - nested or not
  const allSelectedIds = allSelectedStudyAreaIds(plan);
  const selectedSecondaryCourses: IStudyArea[] = pickByIds(allowedSecondaryCourses, allSelectedIds).map((c) => ({
    ...c,
    children: c.allowedComponents,
  }));
  let selectedStudyAreas: IStudyArea[] = pickByIds(
    [...majorsAndMinorsWithSpecialisations, ...courseLevelSpecialisations, ...selectedSecondaryCourses],
    allSelectedIds,
  );
  selectedStudyAreas = selectedStudyAreas.map((area) => ({
    ...area,
    children: pickByIds(area.children ?? [], allSelectedIds),
  }));

  const actionTitle = uniq(
    selectedStudyAreas.map((area) => {
      let specSuffix = (area.children ?? []).map((c) => c.name).join(" ");
      specSuffix = specSuffix && `(${specSuffix})`;
      return `${area.name} ${specSuffix}`.trim();
    }),
  ).join(" / ");

  const ref = useRef(null);
  const titleRef = useRef(null);
  const [anchorElRef, setAnchorElRef] = useState<React.Ref<any>>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  const [menuAnchorPoint, setMenuAnchorPoint] = useState({ x: 0, y: 0 });
  const styles = useStyles();

  const [clearAllTemplateDialogOpen, setClearAllTemplateDialogOpen] = useState(false);
  const flipClearAllTemplateDialog = useCallback(() => {
    setClearAllTemplateDialogOpen((old) => !old);
  }, []);

  // Menu actions
  const openNewPlanActionMenu = useCallback(
    (event: any) => {
      setMenuAnchorPoint({ x: event.clientX, y: event.clientY });
      setAnchorElRef(ref);
      openMenu();
    },
    [ref, setAnchorElRef, openMenu],
  );

  const openSubjectActionMenu = useCallback(
    (event: any) => {
      setMenuAnchorPoint({ x: event.clientX, y: event.clientY });
      setAnchorElRef(titleRef);
      openMenu();
    },
    [titleRef, setAnchorElRef, openMenu],
  );

  const closeMenus = useCallback(() => {
    setAnchorElRef(null);
    closeMenu();
  }, [setAnchorElRef, closeMenu]);

  /** Dialog actions */
  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const handleOpenDialog = useCallback(() => {
    closeMenus();
    setOpenDialog(true);
  }, [setOpenDialog, closeMenus]);

  const { adminPath, userMode, readOnlyPlan } = usePlanPermissions();
  const showState = adminPath && userMode === UserMode.ADMIN && template;

  const placeholder = readOnlyPlan
    ? ""
    : getDisplayMMSPlacholder(allowedComponents, allowedSecondaryCourses, selectedStudyAreas);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{enrollment === null ? "My Course Planner" : `${enrollment.course.code} - My Course Planner`}</title>
        </Helmet>
      </HelmetProvider>
      <Background className="app-subheading">
        <ResponsiveCenter>
          <Aligned style={{ justifyContent: "space-between" }}>
            <div>
              <Row>
                <H3>
                  {enrollment.plan.name.trim() || "N/A"} {showState && `(${capitalize(template?.state ?? "")})`}{" "}
                </H3>
                <Hint hintType={HintToolTip.ADD_MMS} hideHint={menuProps.state === "open"} placement="right">
                  <IconButton
                    className="do-not-print"
                    ref={ref}
                    aria-label="Course Plan menu"
                    id="plan-action-menu-btn"
                    onClick={openNewPlanActionMenu}
                    // TODO Review
                    // TouchRippleProps={{ sx: rippleStyles }}
                    size="large"
                  >
                    <MoreVertIcon sx={styles.menuIcon} />
                  </IconButton>
                </Hint>
                {featureToggles.refreshButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={styles.clearAllButton}
                    className={`do-not-print`}
                    onClick={flipClearAllTemplateDialog}
                  >
                    Clear plan
                  </Button>
                )}
                <PlanActionMenu
                  anchorRef={anchorElRef}
                  anchorPoint={menuAnchorPoint}
                  closeMenu={closeMenu}
                  openDialog={handleOpenDialog}
                  majorsAndMinors={majorsAndMinors}
                  specialisations={specialisations}
                  courseSpecialisations={courseLevelSpecialisations}
                  {...menuProps}
                />
              </Row>
              <H1>
                <DontBreak>{enrollment?.course?.name ?? ""}</DontBreak>{" "}
                {featureToggles.streamAndEntry && (
                  <>
                    {stream && stream.name && <DontBreak>{stream.name}&nbsp;</DontBreak>}
                    {entry && entry.name && <DontBreak>{entry.name}&nbsp;</DontBreak>}
                  </>
                )}
                {showActionMenu && (
                  <DontBreak>
                    <Hint hintType={HintToolTip.B_DES_MMS_WARNING} hideHint={(menuProps as any).isOpen}>
                      <MMSActionTitle
                        ref={titleRef}
                        className={selectedStudyAreas.length === 0 ? "do-not-print" : undefined}
                        aria-label="select-major-menu"
                        onClick={openSubjectActionMenu}
                      >
                        {[placeholder, actionTitle].filter(Boolean).join(" / ")}
                      </MMSActionTitle>
                    </Hint>
                  </DontBreak>
                )}
              </H1>
              <ShareLinkModal open={openDialog} handleClose={handleCloseDialog} />
            </div>

            <ProgressChart />
          </Aligned>
        </ResponsiveCenter>
      </Background>
      <ClearAllTemplateDialog open={clearAllTemplateDialogOpen} close={flipClearAllTemplateDialog} />
    </>
  );
};
