import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import { Formik, FormikHelpers } from "formik";
import { validateValuePresence } from "lib/validation";
import { Button, FormControl, Grid } from "@mui/material";
import { TextField } from "formik-mui";
import { isEmpty } from "lodash";
import { adminAuth } from "actions/admin";
import { FormActionWrapper, FormHeading, FormOuterGrid } from "../components/forms";

interface IAuthForm {
  user: string;
  password: string;
}

const initialValues: IAuthForm = {
  user: "",
  password: "",
};

const validateFormState = (values: IAuthForm) => {
  const errors: any = {};
  validateValuePresence(errors, values, "user");
  validateValuePresence(errors, values, "password");
  return errors;
};

export const PlanAuthContainer = () => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: IAuthForm, helpers: FormikHelpers<IAuthForm>) => {
      const submitResult = dispatch(adminAuth(values)) as any;
      submitResult
        .then((_response: any) => {
          helpers.setSubmitting(false);
        })
        .catch(() => helpers.setSubmitting(false));
    },
    [dispatch],
  );

  return (
    <>
      <FormOuterGrid container alignItems="center">
        <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
          <FormHeading>Log in to manage course plan</FormHeading>
          <br />
          <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
            {(props) => {
              const disableSubmit = props.isSubmitting || !isEmpty(props.errors);

              return (
                <form onSubmit={props.handleSubmit}>
                  <FormControl fullWidth={true} variant="outlined" required>
                    <Field
                      component={TextField}
                      name="user"
                      label="Email"
                      fullWidth={true}
                      variant="outlined"
                      type="email"
                      required={true}
                      inputProps={{
                        // Changing based on accessibility feedback CPT-535
                        "aria-label": "University of Melbourne Admin Email",
                        required: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>

                  <br />
                  <br />

                  <FormControl fullWidth={true} variant="outlined" required>
                    <Field
                      component={TextField}
                      name="password"
                      label="Password"
                      fullWidth={true}
                      variant="outlined"
                      type="password"
                      required={true}
                      inputProps={{
                        // Changing based on accessibility feedback CPT-535
                        "aria-label": "Password",
                        required: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>

                  <br />
                  <br />

                  <FormActionWrapper>
                    <Button variant="contained" color="primary" type="submit" disabled={disableSubmit}>
                      Log In
                    </Button>
                  </FormActionWrapper>
                </form>
              );
            }}
          </Formik>
        </Grid>
      </FormOuterGrid>
    </>
  );
};
