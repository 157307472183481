import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "store/types";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { ResponsiveCenter } from "components/spinner/Center";
import { orderBy } from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { DefaultColumnFilterDefinition, FilterTypes, NoFilter } from "./table";
import { AdminAppHeader } from "containers/app-bar/AdminAppBar";
import { Aligned } from "components/aligned/alignedDiv";
import { getAllDiscontinuedMMS } from "actions/admin";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { AddDiscontinuedMMSDialog } from "../discontinuedMMS/AddDiscontinuedMMSDialog";
import { DeleteDiscontinuedMMSDialog } from "../discontinuedMMS/DeleteDiscontinuedMMSDialog";
import { EditDiscontinuedMMSDialog } from "../discontinuedMMS/EditDiscontinuedMMSDialog";

interface IProps {
  path?: string;
  id?: string;
  children?: any | null;
}

const useStyles = (theme: Theme) => ({
  table: {
    marginTop: "2rem",
  },
  header: {
    "& strong": {
      fontSize: "110%",
    },
    whiteSpace: "nowrap",
  },
  span: {
    fontSize: "14px",
  },
  navIcon: {
    fill: theme.palette.common.black,
  },
  navButton: {
    color: theme.palette.common.black,
    marginTop: "24px",
  },
});

export const DiscontinuedMMS = (_props: IProps) => {
  const admin = useSelector((state: IState) => state.admin);
  const theme = useTheme();
  const classes = useStyles(theme);

  const discontinuedRecords = useMemo(() => admin?.discontinuedRecords ?? [], [admin?.discontinuedRecords]);
  const data = useMemo(() => orderBy(discontinuedRecords ?? [], ["name"], ["asc"]), [discontinuedRecords]);

  const noDiscontinuedMMS = discontinuedRecords.length === 0;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllDiscontinuedMMS());
  }, [dispatch]);

  const [addDiscontinuedMMSFlag, setAddDiscontinuedMMSFlag] = useState<boolean>(false);
  const closeAddDiscontinuedMMSDialog = useCallback(() => setAddDiscontinuedMMSFlag(false), []);
  const openAddDiscontinuedMMSDialog = useCallback(() => setAddDiscontinuedMMSFlag(true), []);

  const [editDiscontinuedMMS, setEditDiscontinuedMMS] = useState<string | null>(null);
  const closeDiscontinuedMMSDialog = useCallback(() => setEditDiscontinuedMMS(null), []);
  const openEditDiscontinuedMMSDialog = useCallback((key: string) => setEditDiscontinuedMMS(key), []);

  const [deleteDiscontinuedMMS, setDeleteDiscontinuedMMS] = useState<string | null>(null);
  const closeDeleteDialog = useCallback(() => setDeleteDiscontinuedMMS(null), []);
  const openDeleteDialog = useCallback((id: string) => setDeleteDiscontinuedMMS(id), []);

  const columns: any[] = useMemo(
    () => [
      {
        Header: "Course",
        accessor: "displayKeyComp",
        sortable: true,
        filter: "includesIgnoreCase",
        minWidth: 150,
        Cell: (cellProps: any) => {
          const row = cellProps.row.original;
          const discontinuedRecord = discontinuedRecords.find((k: any) => k.recordKey === row.recordKey);

          if (!!discontinuedRecord.course?.name) {
            return <>{discontinuedRecord.course?.name}</>;
          }
          return "";
        },
      },
      {
        Header: "Discontinued MMS (Year From)",
        accessor: "disContinuedMMS",
        sortable: true,
        filter: "includesIgnoreCase",
        minWidth: 150,
        Cell: (cellProps: any) => {
          const row = cellProps.row.original;
          const discontinuedRecord = discontinuedRecords.find((k: any) => k.recordKey === row.recordKey);
          if (!discontinuedRecord) {
            return "";
          }
          return (
            <>
              {discontinuedRecord?.discontinuedMMS
                .map((c: any) => {
                  return c.name + " (" + c.year + ")";
                })
                .join(" | ")}
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "recordKey",
        sortable: false,
        Filter: NoFilter,
        width: 55,
        Cell: ({ value }: any) => (
          <Aligned>
            <Tooltip arrow title="Edit Discontinued MMS" aria-label="Edit Discontinued MMS" placement="top">
              <IconButton size="small" onClick={(_e: any) => openEditDiscontinuedMMSDialog(value)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Delete Discontinued MMS" aria-label="Delete Discontinued MMS" placement="top">
              <IconButton size="small" onClick={(_e: any) => openDeleteDialog(value)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Aligned>
        ),
      },
    ],
    [discontinuedRecords, openDeleteDialog, openEditDiscontinuedMMSDialog],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: DefaultColumnFilterDefinition,
      initialState: { pageIndex: 0, pageSize: 20 },
      filterTypes: FilterTypes,
    } as any,
    useFilters,
    useSortBy,
    usePagination,
  ) as any;

  const handleChangePage = useCallback((_e: any, newPage: number) => gotoPage(newPage), [gotoPage]);
  const handleChangeRowsPerPage = useCallback((e: any) => setPageSize(Number(e.target.value)), [setPageSize]);

  if (!admin) {
    return null;
  }
  return (
    <>
      <AdminAppHeader path="discontinued-mms" />
      <ResponsiveCenter>
        <Aligned style={{ justifyContent: "right" }}>
          <Button
            sx={classes.navButton}
            variant="text"
            startIcon={<AddOutlined sx={classes.navIcon} fontSize="small" />}
            onClick={openAddDiscontinuedMMSDialog}
          >
            Add&nbsp;Discontinued MMS
          </Button>
        </Aligned>
        {noDiscontinuedMMS ? (
          <p>No Discontinued MMS available.</p>
        ) : (
          <div>
            <Table {...getTableProps()} className={classes.table} size="small">
              <colgroup>
                {columns.map((c, i) => (
                  <col
                    key={i}
                    width={c.width ?? "auto"}
                    style={{
                      minWidth: c.minWidth,
                      maxWidth: c.maxWidth,
                    }}
                  />
                ))}
              </colgroup>
              <TableHead>
                {headerGroups.map((headerGroup: any, headerGroupIdx: number) => (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id ?? headerGroupIdx}
                    className={classes.header}
                  >
                    {headerGroup.headers.map((column: any, thIdx: number) => {
                      return (
                        <TableCell
                          {...column.getHeaderProps(column.sortable ? column.getSortByToggleProps() : undefined)}
                          key={column.id ?? thIdx}
                        >
                          <strong>{column.render("Header")}</strong>
                          {column.sortable && (
                            <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"} />
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {(page as any[]).map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} key={row.id ?? rowIndex}>
                      {row.cells.map((cell: any, cellIdx: number) => {
                        return (
                          <TableCell {...cell.getCellProps()} key={cellIdx}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={data.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </ResponsiveCenter>
      <AddDiscontinuedMMSDialog open={!!addDiscontinuedMMSFlag} close={closeAddDiscontinuedMMSDialog} />
      <DeleteDiscontinuedMMSDialog
        open={!!deleteDiscontinuedMMS}
        close={closeDeleteDialog}
        discontinuedRecord={discontinuedRecords.find((t) => t.recordKey === deleteDiscontinuedMMS)}
      />
      <EditDiscontinuedMMSDialog
        open={!!editDiscontinuedMMS}
        close={closeDiscontinuedMMSDialog}
        discontinuedRecord={discontinuedRecords.find((t) => t.recordKey === editDiscontinuedMMS)}
      />
    </>
  );
};
