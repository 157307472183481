import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Collapse,
  AppBar,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import { ComponentType, IExpansionState, IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchDrawerResultsContainer,
  SearchDrawerResultsHeaderContainer,
  useSearchDrawerStyles,
  useSearchStyles,
} from "theme/styles";
import { Aligned } from "components/aligned/alignedDiv";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import GetAppIcon from "@mui/icons-material/GetApp";
import CloseIcon from "@mui/icons-material/Close";
import { SearchOutlined } from "@mui/icons-material";
import { LoadTemplateDialog } from "components/templates/LoadTemplateDialog";
import { loadTemplate } from "actions/enrollment";
import { fromPairs, orderBy } from "lodash";
import { templateName } from "lib/templates";
import { SubjectSearchResults } from "containers/search/common";
import styled from "styled-components";
import { useDebounce } from "lib/useDebounce";
import { SearchResultsHeader } from "containers/search/SearchResultsHeader";
import { featureToggles } from "../../config/featureToggles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IProps {
  open: boolean;
  close: () => any;
  mmsRecordIds?: any;
}

const UL = styled.ul`
  margin-top: 0.5rem;
  margin-left: 0rem;
  padding-left: 1.25rem;
  & li {
    margin-bottom: 0.85rem;
  }
`;

const ExpandCollapseButton = styled(IconButton)``;

export const TemplatesDrawer = (props: IProps) => {
  // State of the search box
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 150);
  const onSearchTextChange = useCallback(
    (e: any) => setSearchText(e.target.value.trim().toLowerCase()),
    [setSearchText],
  );
  const disableActions = useSelector((state: IState) => state.loading || state.submittingChanges);

  //Expend
  const [expandedIds, setExpandedIds] = React.useState<IExpansionState>({});
  const makeExpansionHandler = (id: string) => () => {
    setExpandedIds((old) => ({ ...old, [id]: !(old[id] ?? false) }));
  };

  useEffect(() => {
    setExpandedIds({});
  }, []);

  // What we need from the Redux state
  let allTemplates = useSelector((state: IState) => state.enrollment?.matchingTemplates ?? []);
  const planId = useSelector((state: IState) => state.enrollment?.plan?.id);
  const allowedComponents = useSelector((state: IState) => state?.enrollment?.allowedComponents);
  const componentHash = useMemo(
    () => fromPairs((allowedComponents ?? []).map((c) => [c.recordId, c])),
    [allowedComponents],
  );

  const components = allowedComponents?.filter((ac) => props.mmsRecordIds?.includes(ac.recordId));
  const streamOrEntryPointComponent = components?.find(
    (component) => component.type === ComponentType.STREAM || component.type === ComponentType.ENTRY,
  );

  if (!!streamOrEntryPointComponent) {
    allTemplates = allTemplates.filter((t) => t.mmsRecordIds.includes(streamOrEntryPointComponent.recordId));
  }

  let templates = useMemo(
    () =>
      orderBy(
        allTemplates.filter((t) => templateName(t, componentHash).toLowerCase().includes(debouncedSearchText)),
        (t) => templateName(t, componentHash),
      ),
    [allTemplates, componentHash, debouncedSearchText],
  );

  if (props.mmsRecordIds && props.mmsRecordIds.length > 0) {
    templates = templates.filter((t) => !!t.mmsRecordIds.find((m) => props.mmsRecordIds?.includes(m)));
  }

  const userIsDragging = useSelector((s: IState) => !!s.draggedSubjectId);

  const [templateToClone, setTemplateToClone] = useState<string | null>(null);
  const selectTemplateToClone = useCallback((id: string) => () => setTemplateToClone(id), []);
  const clearTemplateToClone = useCallback(() => setTemplateToClone(null), []);

  const dispatch = useDispatch();
  const cloneTemplate = useCallback(() => {
    if (templateToClone && planId) {
      clearTemplateToClone();
      dispatch(loadTemplate(planId, templateToClone));
      props.close();
    }
  }, [clearTemplateToClone, dispatch, planId, props, templateToClone]);

  const theme = useTheme();
  const styles = useSearchStyles(theme);
  const searchDrawerStyles = useSearchDrawerStyles();

  if (allTemplates.length === 0) {
    return null;
  }
  return (
    <>
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={props.close}
        PaperProps={{
          sx: searchDrawerStyles.searchDrawerPaper,
        }}
        ModalProps={{
          disableEscapeKeyDown: userIsDragging,
        }}
        keepMounted={false}
      >
        <AppBar sx={styles.appBar}>
          <Toolbar sx={searchDrawerStyles.toolbar}>
            {/* <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close"></IconButton> */}
            <TextField
              sx={styles.textField}
              autoFocus={true}
              fullWidth={true}
              onChange={onSearchTextChange}
              placeholder="Search Templates"
              variant="outlined"
              InputLabelProps={{ sx: styles.label }}
              inputProps={{ "aria-label": "Search Templates" }}
              InputProps={{
                // TODO Review!
                // xs: styles.textFieldInputRoot,
                // classes: { label: styles.textFieldNativeInputLabel },
                startAdornment: (
                  <InputAdornment position="start" sx={styles.adornment}>
                    <SearchOutlined sx={styles.icon} />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton color="inherit" aria-label="Close" onClick={props.close} size="large">
              <CloseIcon sx={styles.icon} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <SearchDrawerResultsContainer>
          <SearchDrawerResultsHeaderContainer>
            <SearchResultsHeader>
              <p>Available templates</p>
              <p>{`showing ${templates.length} out of ${allTemplates.length}`}</p>
            </SearchResultsHeader>
          </SearchDrawerResultsHeaderContainer>
          <SubjectSearchResults>
            <UL>
              {templates.map((t) => (
                <li key={t.id}>
                  <Aligned style={{ alignItems: "start", justifyContent: "space-between" }}>
                    <div>{templateName(t, componentHash)}&nbsp;&nbsp;</div>
                    <Aligned>
                      {featureToggles.editNotes && !!t.notes && (
                        <Tooltip
                          arrow
                          aria-label={"View plan notes"}
                          title={expandedIds[`${t.id}`] ? "Collapse plan notes" : "Expand plan notes"}
                          placement="top"
                        >
                          <ExpandCollapseButton
                            size="small"
                            color="inherit"
                            aria-label={expandedIds[`${t.id}`] ? "Collapse plan notes" : "Expand plan notes"}
                            onClick={makeExpansionHandler(t.id)}
                          >
                            {expandedIds[`${t.id}`] ? (
                              <ExpandLessIcon fontSize="small" />
                            ) : (
                              <ExpandMoreIcon fontSize="small" />
                            )}
                          </ExpandCollapseButton>
                        </Tooltip>
                      )}

                      <Tooltip
                        arrow
                        aria-label={"Load template into current plan"}
                        title={"Load template into current plan"}
                        placement="top"
                      >
                        <IconButton size="small" onClick={selectTemplateToClone(t.id)} disabled={disableActions}>
                          <GetAppIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        arrow
                        aria-label={"View template(opens new page)"}
                        title={"View template(opens new page)"}
                        placement="top"
                      >
                        <IconButton
                          size="small"
                          href={`${window.location.origin}/plan/${t.id}`}
                          target="_blank"
                          disabled={disableActions}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Aligned>
                  </Aligned>
                  <Collapse in={expandedIds[`${t.id}`] ?? false} timeout="auto" unmountOnExit>
                    <div dangerouslySetInnerHTML={{ __html: !!t.notes ? t.notes : "" }} />
                  </Collapse>
                </li>
              ))}
            </UL>
          </SubjectSearchResults>
        </SearchDrawerResultsContainer>
      </Drawer>
      <LoadTemplateDialog open={!!templateToClone} onCancel={clearTemplateToClone} onAccept={cloneTemplate} />
    </>
  );
};
