import React, { useCallback, useRef, useState } from "react";
import { IState, ISubjectQuery, ISubjectQuerySort } from "store/types";
import { useSelector } from "react-redux";
import max from "lodash/max";
import { Aligned } from "components/aligned/alignedDiv";
import { Badge, IconButton, Tooltip } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { useMenuState } from "@szhsin/react-menu";
import { SubjectSortActionMenu } from "./SubjectSortActionMenu";
import { SearchResultsHeader } from "./SearchResultsHeader";
import { InfoOutlined } from "@mui/icons-material";

const pluraliseSubject = (count: number) => (count === 1 ? "subject" : "subjects");

interface IProps {
  query: ISubjectQuery;
  searchYear: number;
  sortSubjects: (sort: ISubjectQuerySort | null) => void;
}

export const SubjectResultsHeader = (props: IProps) => {
  // What we need from the Redux state
  const { results, totalCount, query, searchYear } = useSelector((state: IState) => state.subjectSearch);
  const displayResults = results.filter((s) => !s.discoveryResult);

  const maxPoints = max(query?.points || []);
  const showPoints = !!maxPoints && maxPoints < 50;

  const menuButtonRef = useRef(null);
  const [anchorElRef, setAnchorElRef] = useState<React.Ref<any>>(null);
  const [menuAnchorPoint, setMenuAnchorPoint] = useState({ x: 0, y: 0 });

  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  // Menu actions
  const openSortMenu = useCallback(
    (event: any) => {
      setMenuAnchorPoint({ x: event.clientX, y: event.clientY });
      setAnchorElRef(menuButtonRef);
      openMenu();
    },
    [menuButtonRef, setAnchorElRef, openMenu],
  );

  let txt =
    displayResults.length === 0
      ? `No matching subjects found`
      : displayResults.length >= totalCount
      ? `Showing all ${displayResults.length} ${pluraliseSubject(displayResults.length)}`
      : `Showing ${displayResults.length} (out of ${totalCount})  ${pluraliseSubject(displayResults.length)}`;
  if (showPoints) {
    txt += ` with ${maxPoints} points or less`;
  }
  txt += ` for ${searchYear}`;

  return (
    <SearchResultsHeader>
      <Aligned>
        <p>{txt}&nbsp;</p>
        <Tooltip
          arrow
          placement="top"
          title="Subjects shown are determined by the the year selected and the latest subject data available."
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Aligned>

      {displayResults.length > 0 && (
        <Aligned>
          {query?.sort && (
            <p>
              Sorted by {query.sort.property} ({query.sort.direction === "ASC" ? "ascending" : "descending"})
            </p>
          )}
          &nbsp;
          <Tooltip arrow title="Sort subjects" aria-label={"Sort subjects"} placement="top">
            <Badge
              invisible={!query?.sort}
              overlap="circular"
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <IconButton
                ref={menuButtonRef}
                aria-label="Sort subjects"
                id="sort-subjects-btn"
                size="small"
                onClick={openSortMenu}
              >
                <SortIcon fontSize="small" />
              </IconButton>
            </Badge>
          </Tooltip>
          <SubjectSortActionMenu
            anchorRef={anchorElRef}
            anchorPoint={menuAnchorPoint}
            closeMenu={closeMenu}
            {...menuProps}
            {...props}
          />
        </Aligned>
      )}
    </SearchResultsHeader>
  );
};
