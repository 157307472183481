import React from "react";

import { uniq } from "lodash";
import { Input, Select } from "@mui/material";

// Define a default UI for filtering - typing input
export const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }: any) => {
  const count = preFilteredRows.length;

  return (
    <Input
      value={filterValue || ""}
      fullWidth
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} templates...`}
    />
  );
};

// Custom filter UI for selecting
// Calculate the options for filtering using the preFilteredRows
export const SelectColumnFilter = (props: any) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, useCellInFilter, Cell },
  } = props;
  const options: any[] = React.useMemo(
    () => uniq(preFilteredRows.map((row: any) => row.values[id])),
    [id, preFilteredRows],
  );

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      fullWidth
      native
      variant={"standard"}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {useCellInFilter ? Cell({ value: option }) : option}
        </option>
      ))}
    </Select>
  );
};

export const NoFilter = () => <div style={{ height: "32px" }} />;

export const FilterTypes = () => ({
  includesIgnoreCase: (rows: any[], id: any, filterValue: string) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue).toLowerCase().includes(String(filterValue).trim().toLowerCase())
        : true;
    });
  },
});

export const DefaultColumnFilterDefinition = {
  // Let's set up our default Filter UI
  Filter: DefaultColumnFilter,
};

export const stopPropagationHandler = (e: any) => e.stopPropagation();
