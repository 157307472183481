import React, { useCallback } from "react";
import ListItemText from "@mui/material/ListItemText";
import UpIcon from "@mui/icons-material/ArrowUpward";
import DownIcon from "@mui/icons-material/ArrowUpward";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "../actionMenu.css";
import { ISubject, ISubjectQuery, ISubjectQuerySort } from "store/types";
import { Aligned } from "components/aligned/alignedDiv";

interface IProps {
  closeMenu: () => any;
  anchorRef: React.Ref<any>;
  anchorPoint: any;
  query: ISubjectQuery;
  sortSubjects: (sort: ISubjectQuerySort | null) => void;
}

const sortIcon = (prop: keyof ISubject, sort?: ISubjectQuerySort | null) =>
  sort?.property === prop ? (
    sort.direction === "ASC" ? (
      <DownIcon fontSize="small" />
    ) : (
      <UpIcon fontSize="small" />
    )
  ) : null;
const nextSort = (property: keyof ISubject, sort?: ISubjectQuerySort | null | undefined): ISubjectQuerySort | null => {
  if (property !== sort?.property) {
    return {
      property,
      direction: "ASC",
    };
  } else {
    return { ...sort!, direction: sort.direction === "DESC" ? "ASC" : "DESC" };
  }
};

export const SubjectSortActionMenu = (props: IProps) => {
  const { closeMenu, anchorRef, anchorPoint, query, sortSubjects: doSort, ...menuProps } = props;
  const sort = query?.sort;

  const onSort = useCallback(
    (prop: keyof ISubject) => () => {
      doSort(nextSort(prop, sort));
    },
    [doSort, sort],
  );

  const menuItems: any[] = [
    <MenuItem onClick={onSort("code")} key="sort-by-code">
      <ListItemText
        primary={
          <Aligned>
            <span>Subject Code</span>
            &nbsp;&nbsp;
            {sortIcon("code", sort)}
          </Aligned>
        }
      />
    </MenuItem>,
    <MenuItem onClick={onSort("name")} key="sort-by-name">
      <ListItemText
        primary={
          <Aligned>
            <span>Name</span>
            &nbsp;&nbsp;
            {sortIcon("name", sort)}
          </Aligned>
        }
      />
    </MenuItem>,
    <MenuItem onClick={onSort("points")} key="sort-by-points">
      <ListItemText
        primary={
          <Aligned>
            <span>Points</span>
            &nbsp;&nbsp;
            {sortIcon("points", sort)}
          </Aligned>
        }
      />
    </MenuItem>,
  ];

  return (
    <ControlledMenu {...menuProps} anchorRef={anchorRef} anchorPoint={anchorPoint} onClose={closeMenu}>
      {menuItems}
    </ControlledMenu>
  );
};
