import initialState from "store/initialState";
import { GET_SIMILAR_SUBJECTS, RESET_SIMILAR_SUBJECTS } from "actions/similarSubjectsSearch";
import { failed, fulfilled, pending } from "lib/promiseMiddlewareTypes";

const similarSubjectsSearch = (state = initialState.similarSubjectsSearch, action: any) => {
  // console.log(action.payload);
  switch (action.type) {
    case RESET_SIMILAR_SUBJECTS:
      return initialState.similarSubjectsSearch;
    case fulfilled(GET_SIMILAR_SUBJECTS):
      return { ...state, ...action.payload, searchInProgress: false };
    case pending(GET_SIMILAR_SUBJECTS):
      return { ...state, results: [], searchInProgress: true };
    case failed(GET_SIMILAR_SUBJECTS):
      return { ...state, results: [], searchInProgress: false };
    default:
      return state;
  }
};

export default similarSubjectsSearch;
