import React from "react";
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HelpRounded from "@mui/icons-material/HelpRounded";
import { IRuleInvocation, IRuleInvocationGroup } from "store/types";
import { validationRed, validationGreen, brandMedGrey1, darkYellow } from "theme/colors";
import {
  getParentGroups,
  isConstraintRule,
  isGroupSuccessful,
  isInfoGroup,
  isInfoOrConstraintGroup,
  isInfoRule,
} from "lib/invocations";

const useStyles = () => ({
  errorIcon: {
    fill: validationRed.toString(),
  },
  successIcon: {
    fill: validationGreen.toString(),
  },
  infoIcon: {
    fill: brandMedGrey1.toString(),
  },
  selfEvalIcon: {
    fill: darkYellow.toString(),
  },
});

export const SuccessIcon = () => {
  const styles = useStyles();
  return <CheckCircleRounded fontSize="small" sx={styles.successIcon} />;
};

export const ErrorIcon = () => {
  const styles = useStyles();
  return <CancelRoundedIcon fontSize="small" sx={styles.errorIcon} />;
};

export const GrayedOutErrorIcon = () => {
  const styles = useStyles();
  return <CancelRoundedIcon fontSize="small" sx={styles.infoIcon} />;
};

export const InfoIcon = () => {
  const styles = useStyles();
  return <InfoRoundedIcon fontSize="small" sx={styles.infoIcon} />;
};

export const SelfEvalIcon = () => {
  const styles = useStyles();
  return <HelpRounded fontSize="small" sx={styles.selfEvalIcon} />;
};

export const ConstraintIcon = () => {
  const styles = useStyles();
  return <CheckCircleRounded fontSize="small" sx={styles.infoIcon} />;
};

export const iconForRule = (
  invocation: IRuleInvocation,
  allGroups: IRuleInvocationGroup[],
  checklistInvocations: IRuleInvocation[],
) => {
  const isInfo = isInfoRule(invocation);
  if (isInfo && invocation.selfEvaluated) {
    return invocation.result ? <SuccessIcon /> : <SelfEvalIcon />;
  } else if (isInfo) {
    return <InfoIcon />;
  } else if (invocation.result && isConstraintRule(invocation)) {
    return <ConstraintIcon />;
  } else if (invocation.result) {
    return <SuccessIcon />;
  } else {
    const parents = getParentGroups(allGroups, invocation);
    const successfulParent = !!parents.find((g) => isGroupSuccessful(g, allGroups, checklistInvocations));

    return successfulParent ? <GrayedOutErrorIcon /> : <ErrorIcon />;
  }
};

export const iconForGroup = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  ruleInvocations: IRuleInvocation[],
) => {
  const success = isGroupSuccessful(group, allGroups, ruleInvocations);
  const constraint = isInfoOrConstraintGroup(group, allGroups, ruleInvocations);
  const info = isInfoGroup(group, allGroups, ruleInvocations);
  if (success && info) {
    return <InfoIcon />;
  } else if (success && constraint) {
    return <ConstraintIcon />;
  } else if (success) {
    return <SuccessIcon />;
  } else {
    const parents = getParentGroups(allGroups, group);
    const successfulParent = !!parents.find((g) => isGroupSuccessful(g, allGroups, ruleInvocations));

    return successfulParent ? <GrayedOutErrorIcon /> : <ErrorIcon />;
  }
};
