import { SET_DRAGGED_SUBJECT } from "actions/draggedSubjectId";
import initialState from "store/initialState";

const draggedSubjectId = (state = initialState.draggedSubjectId, action: any) => {
  switch (action.type) {
    case SET_DRAGGED_SUBJECT:
      return action.payload;
    default:
      return state;
  }
};

export default draggedSubjectId;
