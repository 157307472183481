import store from "store";
import { IState } from "store/types";

export const REMOVE_INVOCATION_FOCUS = "focusInvocationId/REMOVE_FOCUS";
export const SET_INVOCATION_FOCUS = "focusInvocationId/SET_FOCUS";

export const flipInvocationFocus = (newFocusnvocationId?: string | null) => {
  const { focusInvocationId } = store.getState() as IState;

  return newFocusnvocationId === focusInvocationId || !newFocusnvocationId
    ? removeInvocationFocus()
    : setInvocationFocus(newFocusnvocationId);
};

export const removeInvocationFocus = () => {
  return {
    type: REMOVE_INVOCATION_FOCUS,
    payload: {},
  };
};

export const setInvocationFocus = (id: string) => {
  return {
    type: SET_INVOCATION_FOCUS,
    payload: {
      id,
    },
  };
};
