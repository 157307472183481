import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { addCompEligibility } from "../../actions/admin";
import { AddEligibilityForm } from "./AddEligibilityForm";

interface IProps {
  open: boolean;
  close: () => any;
}

export const AddEligibilityDialog = ({ open, close }: IProps) => {
  const dispatch = useDispatch();
  const initialValues: any = { displayKeyComp: "", disAllowedComp: [{ course: "", mms: "" }] };

  const onSubmit = useCallback(
    (values: any, helpers: FormikHelpers<any>) => {
      close();

      let displayKeyComp = {};
      let recordKey = "";

      if (!!values.courseKey && !!values.mmsKey) {
        displayKeyComp = { course: values.courseKey, mms: values.mmsKey };
        recordKey = `${values.courseKey.code}-${values.mmsKey.code}`;
      } else if (!!values.courseKey && !values.mmskey) {
        displayKeyComp = { course: values.courseKey };
        recordKey = values.courseKey.code;
      } else {
        displayKeyComp = { mms: values.mmsKey };
        recordKey = values.mmsKey.code;
      }
      const submitResult = dispatch(
        addCompEligibility({
          recordKey: recordKey,
          displayKeyComp: displayKeyComp,
          disAllowedComp: values.disAllowedComp,
        }),
      ) as any;
      submitResult.catch(() => helpers.setSubmitting(false));
    },
    [close, dispatch],
  );

  if (!open) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="add-eligibility-dialog-title"
          aria-describedby="add-eligibility-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="add-eligibility-dialog-title">Add Eligibility Rule</DialogTitle>
          <DialogContent>
            <AddEligibilityForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="edit-eligibility-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
