import { ComponentType, IComponent, IEnrollment, IState } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import { brandGrey } from "../../../theme/colors";
import { Checkbox, Icon } from "@mui/material";
import { ReactComponent as MMSIcon } from "../MMS.svg";
import ListItemText from "@mui/material/ListItemText";
import {
  EligibilityValidateType,
  isValidNoDupComponentRule,
  isDupMMSAgainstSecondaryCourseRule,
  isValidQualificationLevelCombinationRule,
} from "../../../lib/componentsEligibilityRules";
import { featureToggles } from "../../../config/featureToggles";
import { intersection } from "lodash";
import { usePlanPermissions } from "../../../lib/usePlanPermissions";
import { useSelector } from "react-redux";
import { isDiscontinued } from "../../../lib/discontinuedMMSRules";

interface IProps {
  enrollment: IEnrollment;
  majorsAndMinors: IComponent[];
  specialisations: IComponent[];
  selectedMinors: IComponent[];
  selectedDiplomas: any;
  selectedSpecialisations: Array<string>;
  isComponentEnabled: (c: IComponent) => any;
  doChangeMSSEnrollment: (mmsRecordIds: string[]) => any;
}

export const MajorsSubMenu = (props: IProps) => {
  const {
    enrollment,
    majorsAndMinors,
    specialisations,
    selectedMinors,
    selectedDiplomas,
    selectedSpecialisations,
    isComponentEnabled,
    doChangeMSSEnrollment,
  } = props;
  const { readOnlyPlan } = usePlanPermissions();
  const course = enrollment.course;
  const mmsRecordIds = enrollment.plan?.mmsRecordIds;
  const enrollmentYear = enrollment.plan?.year;

  const admin = useSelector((state: IState) => state.admin!);
  const compEligibilites = admin?.compEligibilities;
  const discontinuedRecords = admin?.discontinuedRecords;

  let majors = majorsAndMinors.filter((m: IComponent) => m.type === ComponentType.MAJOR);
  if (featureToggles.discontinuedMMS) {
    majors = majors.filter((m: IComponent) => !isDiscontinued(discontinuedRecords, m, enrollmentYear));
  }

  const majorsWithSpecialisations = majors.map((m: IComponent) => ({
    ...m,
    children: (specialisations || []).filter((s: IComponent) => s.parentRecordId === m.recordId),
  }));

  return (
    <>
      {majors && majors.length > 0 && !readOnlyPlan && (
        <SubMenu
          key="select-major-menu-item"
          overflow="auto"
          label={() => (
            <React.Fragment>
              <ListItemIcon style={{ color: brandGrey.toHexString() }}>
                <Icon component={MMSIcon} />
              </ListItemIcon>
              <ListItemText primary="Select Major" />
            </React.Fragment>
          )}
        >
          {majorsWithSpecialisations.map((m: IComponent) => {
            const dup = selectedMinors.find((minor: any) => !isValidNoDupComponentRule(minor, m));
            const dupWithDiploma = isDupMMSAgainstSecondaryCourseRule(selectedDiplomas, m);

            const isValidQualificationLevelCombination = isValidQualificationLevelCombinationRule(
              compEligibilites,
              course,
              selectedDiplomas,
              m,
              EligibilityValidateType.Major,
            );

            const disabledMajor =
              !isComponentEnabled(m) ||
              (!!dup && featureToggles.eligibilityRule) ||
              (featureToggles.componentEligibility && (!isValidQualificationLevelCombination || dupWithDiploma)); //||
            return (m?.children || []).length > 0 ? (
              <SubMenu
                key={m.recordId}
                disabled={disabledMajor}
                label={() => (
                  <React.Fragment>
                    <ListItemIcon style={{ paddingLeft: "11px" }}>
                      <Checkbox
                        edge="start"
                        checked={mmsRecordIds.includes(m.recordId)}
                        tabIndex={-1}
                        className={disabledMajor ? "list-checkbox-disabled" : undefined}
                        disabled={disabledMajor}
                        disableRipple
                        inputProps={{
                          "aria-label": `${disabledMajor ? "Disabled" : ""} Checkbox ${m.name}`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={m.name.replace(new RegExp(/major/gi), "")} />
                  </React.Fragment>
                )}
              >
                {m.children?.map((s: IComponent) => {
                  const numSpecialisations = intersection(
                    mmsRecordIds,
                    (m?.children || []).map((s) => s.recordId),
                  ).length;
                  const selected = selectedSpecialisations.includes(s.recordId);
                  const updateMajor = (selected && numSpecialisations === 1) || (!selected && numSpecialisations === 0);
                  const disabledSpecialisation = !isComponentEnabled(s);
                  return (
                    <MenuItem
                      type="checkbox"
                      key={s.recordId}
                      tabIndex={0}
                      checked={mmsRecordIds.includes(s.recordId)}
                      // disabled={disabledSpecialisation}  not used so that users using assistive tech can still tab to it
                      aria-disabled={disabledSpecialisation}
                      className={disabledSpecialisation ? "menu-list-item-disabled" : undefined}
                      onClick={
                        disabledSpecialisation
                          ? undefined
                          : doChangeMSSEnrollment(updateMajor ? [m.recordId, s.recordId] : [s.recordId])
                      }
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={mmsRecordIds.includes(s.recordId)}
                          tabIndex={-1}
                          className={disabledSpecialisation ? "list-checkbox-disabled" : undefined}
                          disableRipple
                          disabled={disabledSpecialisation}
                          inputProps={{
                            "aria-label": `${disabledSpecialisation ? "Disabled" : ""} Checkbox ${s.name}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={s.name} />
                    </MenuItem>
                  );
                })}
              </SubMenu>
            ) : (
              <MenuItem
                type="checkbox"
                tabIndex={0}
                checked={mmsRecordIds.includes(m.recordId)}
                key={m.recordId}
                // disabled={disabledMajor} not used so that users using assistive tech can still tab to it
                aria-disabled={disabledMajor}
                className={disabledMajor ? "menu-list-item-disabled" : undefined}
                onClick={disabledMajor ? undefined : doChangeMSSEnrollment([m.recordId])}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={mmsRecordIds.includes(m.recordId)}
                    tabIndex={-1}
                    disableRipple
                    className={disabledMajor ? "list-checkbox-disabled" : undefined}
                    disabled={disabledMajor}
                    inputProps={{ "aria-label": `${disabledMajor ? "Disabled" : ""} Checkbox ${m.name}` }}
                  />
                </ListItemIcon>
                <ListItemText primary={m.name.replace(new RegExp(/major/gi), "")} />
              </MenuItem>
            );
          })}
        </SubMenu>
      )}
    </>
  );
};
