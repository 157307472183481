import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { IState } from "../../store/types";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackBar } from "actions/snackBar";
import "./snackbar.css";

const icons: any = {
  error: <i className="material-icons ibm__snackbar-icon">error</i>,
  success: <i className="material-icons ibm__snackbar-icon">check_circle</i>,
};

export const SnackBar = () => {
  const snackState = useSelector((state: IState) => state.snackBarState);
  const dispatch = useDispatch();
  const doCloseSnackBar = React.useCallback(() => {
    dispatch(closeSnackBar());
  }, [dispatch]);

  return snackState ? (
    <Snackbar
      className={`ibm__snack_container ibm__snack-${snackState.type}`}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={snackState !== null && snackState !== undefined}
      autoHideDuration={3000}
      onClose={doCloseSnackBar}
      ContentProps={{
        "aria-describedby": "ibm__snack_bar_message_id",
        className: `ibm__snack_container ibm__snack-${snackState.type}`,
      }}
      message={
        <div id="ibm__snack_bar_message_id">
          <div>{icons[snackState.type]}</div>
          <div>&nbsp;{snackState.message}</div>
        </div>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={doCloseSnackBar} size="large">
          <i className="material-icons">close</i>
        </IconButton>,
      ]}
    />
  ) : null;
};
