import React, { useCallback } from "react";
import { Button, Theme, Tooltip, useTheme } from "@mui/material";
import { BreadthDisciplineScope, IRuleInvocation, IRuleInvocationGroup, IState, RuleTemplateType } from "store/types";
import { iconForRule } from "./InvocationsIcons";
import { isInfoRule } from "lib/invocations";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { flipInvocationFocus } from "actions/focusInvocationId";
import { featureToggles } from "config/featureToggles";

const ActionButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  text-align: right;
`;

export const useStyles = (theme: Theme) => ({
  tooltipActionBtn: {
    color: theme.palette.common.white,
    fontSize: "12px",
    textDecoration: "underline",
  },
  tooltipActionBtnIcon: {
    fill: theme.palette.common.white,
  },
});

const invocationDescription = (invocation: IRuleInvocation) => {
  // Same tooltip for info rules
  if (isInfoRule(invocation) && invocation.selfEvaluated) {
    return `Self assessed eligibility.`;
  } else if (invocation.progress) {
    const { ruleType } = invocation;
    const { currentCount, minimum, maximum } = invocation.progress;
    const suffix = [
      RuleTemplateType.PointsConstraint,
      RuleTemplateType.TotalCoursePoints,
      RuleTemplateType.CrossCreditCoursePoints,
      RuleTemplateType.Progression,
    ].includes(ruleType as RuleTemplateType)
      ? " points"
      : "";
    let progressMessage = `You have ${currentCount}${suffix} assigned to your plan.`;

    if (isInfoRule(invocation) && invocation.selfEvaluated) {
      progressMessage = `Self assessed eligibility.`;
    } else if (minimum && maximum && currentCount >= minimum && currentCount < maximum) {
      // progressMessage += ` You may add ${maximum - currentCount}${suffix} more.`;
      progressMessage = `You have ${currentCount} of ${maximum}${suffix} assigned to your plan.`;
    } else if (minimum && currentCount < minimum) {
      // progressMessage += ` Please add ${minimum - currentCount}${suffix} to your plan.`;
      progressMessage = `You have ${currentCount} of ${minimum}${suffix} assigned to your plan.`;
    } else if (maximum && currentCount > maximum && ruleType !== RuleTemplateType.Progression) {
      progressMessage += ` Please remove ${currentCount - maximum}${suffix} from your plan.`;
    } else if (maximum && currentCount < maximum) {
      progressMessage = `You have ${currentCount} of ${maximum}${suffix} assigned to your plan.`;
    }

    const scope = invocation?.matchingDetails?.breadthDisciplineScope;
    if (scope) {
      switch (scope) {
        case BreadthDisciplineScope.Breadth:
          progressMessage += " Only breadth subjects are considered.";
          break;
        case BreadthDisciplineScope.Discipline:
          progressMessage += " Only discipline subjects are considered.";
          break;
        case BreadthDisciplineScope.Breadth_and_Discipline:
          progressMessage += " Both breadth and discipine subjects are considered.";
          break;
      }
    }

    return progressMessage;
  }
};

interface IProps {
  invocation: IRuleInvocation;
  allGroups: IRuleInvocationGroup[];
  checklistInvocations: IRuleInvocation[];
  style?: React.CSSProperties;
}

export const InvocationIcon = ({ invocation, allGroups, checklistInvocations, style }: IProps) => {
  const invDescription = invocationDescription(invocation);
  // const accessibleTooltipText = `${invocation.result ? "Success" : "Failure"} ${invDescription}`;
  const icon = iconForRule(invocation, allGroups, checklistInvocations);

  const matchingSubjects = invocation?.matchingDetails?.recordIds ?? [];
  const theme = useTheme();
  const styles = useStyles(theme);

  const dispatch = useDispatch();
  const focusInvocationId = useSelector((s: IState) => s.focusInvocationId);
  const isInvocationFocused = !!focusInvocationId && focusInvocationId === invocation.ruleId;
  const onRuleFocusClicked = useCallback(
    (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      dispatch(flipInvocationFocus(invocation.ruleId));
    },
    [dispatch, invocation.ruleId],
  );

  if (invDescription || matchingSubjects.length > 0) {
    return (
      <Tooltip
        arrow
        placement="top"
        // aria-label={accessibleTooltipText}
        title={
          <div>
            {invDescription}
            {matchingSubjects.length > 0 && featureToggles.highlightSubjectsFromChecklist && (
              <ActionButtonContainer>
                {isInvocationFocused && (
                  <Button
                    onClick={onRuleFocusClicked}
                    sx={styles.tooltipActionBtn}
                    startIcon={<VisibilityOffIcon fontSize="small" sx={styles.tooltipActionBtnIcon} />}
                  >
                    <strong>{"Cancel subject highlight"}</strong>
                  </Button>
                )}
                {!isInvocationFocused && (
                  <Button
                    onClick={onRuleFocusClicked}
                    sx={styles.tooltipActionBtn}
                    startIcon={<VisibilityIcon fontSize="small" sx={styles.tooltipActionBtnIcon} />}
                  >
                    <strong>{"Highlight subjects"}</strong>
                  </Button>
                )}
              </ActionButtonContainer>
            )}
          </div>
        }
        style={{ ...style }}
      >
        <div>{icon}</div>
      </Tooltip>
    );
  }

  if (style) {
    return <div style={{ ...style }}>{icon}</div>;
  }

  return icon;
};
