import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { IState, ITemplateSummary } from "store/types";
import { removeTemplate } from "../../actions/admin";
import { useNavigate } from "@reach/router";

interface IProps {
  open: boolean;
  close: () => any;
  templateSummary?: ITemplateSummary;
}

export interface INewNameFormVals {
  name: string;
}

export const DeleteTemplateDialog = ({ open, close, templateSummary }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plan = useSelector((s: IState) => s.enrollment?.plan);

  let planId = "";
  let planName = "";

  if (!!templateSummary) {
    planId = templateSummary.id;
    planName = templateSummary.name;
  } else {
    planId = plan?.id ?? "";
    planName = plan?.name ?? "";
  }

  const onSubmit = useCallback(() => {
    close();
    if (planId) {
      const res = dispatch(removeTemplate(planId)) as any;
      if (Promise.resolve(res) === res) {
        res.then(() => navigate(`/staff/templates`));
      } else {
        navigate(`/staff/templates`);
      }
    }
  }, [close, dispatch, planId, navigate]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="delete-plan-dialog-title"
      aria-describedby="delete-plan-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="delete-plan-dialog-title">Delete template</DialogTitle>
      <DialogContent>
        <p>Are you sure to delete template {planName}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={onSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
