import { useMediaQuery, useTheme } from "@mui/material";
import {
  getChecklistInvocations,
  isGroupSuccessful,
  getParentGroups,
  isInfoRule,
  isConstraintRule,
} from "lib/invocations";
import React from "react";
import { useSelector } from "react-redux";
import { PieChart, Pie, Cell, Label } from "recharts";
import { HintToolTip, IRuleInvocation, IState } from "store/types";
import { Hint } from "./Hint";

const COLORS = ["#429a67", "#c3c3c3"];

const chartRadius = (belowSm: boolean, belowMd: boolean, belowLg: boolean) => {
  if (belowSm) {
    return 45;
  } else if (belowMd) {
    return 50;
  } else if (belowLg) {
    return 60;
  } else {
    return 60;
  }
};

const isIgnored = (i: IRuleInvocation) => ((isInfoRule(i) && !i.selfEvaluated) || isConstraintRule(i)) && i.result;
const isCounted = (i: IRuleInvocation) => !isIgnored(i);

export const ProgressChart = () => {
  // Get the data (TODO extract this selctor logic somewhere ...)
  const validation = useSelector((s: IState) => s.enrollment?.validation);
  const allGroups = validation?.groups || [];
  const checklistInvocations = useSelector((s: IState) => getChecklistInvocations(s.enrollment));

  const theme = useTheme();
  const mediaOpts = { noSsr: true };
  const belowSm = useMediaQuery(theme.breakpoints.down("xl"), mediaOpts);
  const belowMd = useMediaQuery(theme.breakpoints.down("xl"), mediaOpts);
  const belowLg = useMediaQuery(theme.breakpoints.down("xl"), mediaOpts);

  // Compute chart and label sizes
  const r = chartRadius(belowSm, belowMd, belowLg);
  const diameter = r * 2;
  const mainLabelFontSize = Math.trunc(r * 0.5);
  const secondaryLabelFontSize = Math.trunc(r * 0.3);

  if (checklistInvocations.length === 0 || !validation) {
    return null;
  }

  // Need to ignore some rules
  const countedInvocations = checklistInvocations.filter(isCounted);
  const successfullInvocations = countedInvocations.filter((i) => i.result).length;

  // Count as failed invocations, which do not have failed parent groups
  const failedInvocations = countedInvocations
    .filter((i) => !i.result)
    .filter((i) => {
      const parents = getParentGroups(allGroups, i);
      const successfulParent = !!parents.find((g) => isGroupSuccessful(g, allGroups, checklistInvocations));
      return !successfulParent;
    }).length;

  const data = [
    { name: "Completed Rules", value: successfullInvocations },
    { name: "Failed Rules", value: failedInvocations },
  ];
  const pcnt = Math.round((100 * data[0].value) / (data[0].value + (data[1]?.value || 0)));

  return (
    <Hint hintType={HintToolTip.COMPLETION} hideHint={failedInvocations > 0} placement="left">
      <div>
        <PieChart width={diameter + 10} height={diameter + 20} aria-label={`${pcnt}% Planned`} aria-live="polite">
          <Pie
            isAnimationActive={false}
            data={data}
            dataKey="value"
            cx={r + 5}
            cy={r + 5}
            outerRadius={r - 1}
            fill="white"
          />

          <Pie
            labelLine={false}
            data={data}
            dataKey={"value"}
            cx={r + 5}
            cy={r + 5}
            innerRadius={r - 5}
            outerRadius={r}
            paddingAngle={0}
            // animationEasing="linear"
          >
            <Label
              value={`${pcnt}%`}
              aria-hidden="true"
              position="centerBottom"
              className="label-top"
              fontSize={`${mainLabelFontSize}px`}
              fill="black"
              dy={-5}
            />
            <Label
              value="Planned"
              aria-hidden="true"
              position="centerTop"
              className="label"
              fontSize={`${secondaryLabelFontSize}px`}
              fill="black"
              dy={5}
            />
            {data.map((_entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} stroke={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </Hint>
  );
};
