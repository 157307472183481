import React, { useCallback, useState } from "react";
import { Divider, IconButton, Paper, Tooltip } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { IState } from "store/types";
import { useSelector } from "react-redux";
import { NestedRules } from "./NestedRules";
import { getChecklistInvocations } from "lib/invocations";
import { useBreakpoint } from "lib/useBreakpoint";
import { Aligned, AlignedTop } from "components/aligned/alignedDiv";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { featureToggles } from "../../config/featureToggles";
import InfoIcon from "@mui/icons-material/Info";

const H3 = styled.h3`
  margin-top: 1rem;
  margin-bottom: 6px;
  color: #575757;
  font-size: 1rem;
`;
const ExpandCollapseButton = styled(IconButton)`
  margin-top: 8px;
  margin-left: 6px;
`;

const WrapperPaper = styled(Paper)`
  height: 100%;
  max-height: 100vh;
  background: #f7f7f7;
  margin-left: 1rem;
  position: sticky;
  top: 0;
`;
const HeadContainer = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const PlanNotesContainer = styled.div`
  padding: 1rem 0.5rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
`;

const PlanNotesContent = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const PlanNotesIcon = styled.div`
  padding-top: 15px;
`;

const RulesContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 70px);
`;

interface IProps {
  close: () => any;
}

const openChecklistSizes = {
  xl: "400px",
  lg: "380px",
  md: "380px",
  sm: "320px",
  xs: "240px",
};

export const ExpandedChecklist = (props: IProps) => {
  const checklistInvocations = useSelector((s: IState) => getChecklistInvocations(s.enrollment));
  const enrollment = useSelector((state: IState) => state.enrollment!);
  const { plan } = enrollment;

  const breakpoint = useBreakpoint();

  const [expandedByDefault, setExpandedByDefault] = useState(false);
  const flipExpansion = useCallback(() => setExpandedByDefault((old) => !old), []);
  const expansionHint = expandedByDefault ? "Collapse all rules" : "Expand all rules";

  return (
    <WrapperPaper elevation={2} sx={{ width: openChecklistSizes[breakpoint] }}>
      {/* <pre>{breakpoint} {openChecklistSizes[breakpoint]}</pre> */}
      <HeadContainer>
        <Aligned>
          <H3>PLAN CHECKLIST</H3>
          <Tooltip arrow aria-label={expansionHint} title={expansionHint} placement="top">
            <ExpandCollapseButton size="small" color="inherit" aria-label={expansionHint} onClick={flipExpansion}>
              {expandedByDefault ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
            </ExpandCollapseButton>
          </Tooltip>
        </Aligned>
        <IconButton color="inherit" aria-label="Close" onClick={props.close} size="large">
          <CloseIcon />
        </IconButton>
      </HeadContainer>

      <Divider />

      <RulesContainer>
        {featureToggles.editNotes && plan.notes && plan.notes !== "" && (
          <PlanNotesContainer>
            <AlignedTop>
              <PlanNotesIcon>
                <InfoIcon />
              </PlanNotesIcon>

              <PlanNotesContent>
                <div dangerouslySetInnerHTML={{ __html: plan.notes }} />
              </PlanNotesContent>
            </AlignedTop>
          </PlanNotesContainer>
        )}
        <NestedRules invocations={checklistInvocations} expandedByDefault={expandedByDefault} />
      </RulesContainer>
    </WrapperPaper>
  );
};
