import { DnDId, IState } from "store/types";
import store from "../store";

export const START_ASSIGNMENT = "optimisticUpdate/START_ASSIGNMENT";
export const MOVE_OPTIMISTICALLY = "optimisticUpdate/MOVE_OPTIMISTICALLY";

export const moveOptimistically = (from: DnDId, to: DnDId) => {
  const { enrollment } = store.getState() as IState;
  return {
    type: MOVE_OPTIMISTICALLY,
    payload: {
      enrollment,
      from,
      to,
    },
  };
};
