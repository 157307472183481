import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { updateCompEligibility } from "../../actions/admin";
import { EditEligibilityForm } from "./EditEligbilityForm";

interface IEligibility {
  recordKey: any;
  displayKeyComp: any;
  disAllowedComp: Array<string>;
}

interface IProps {
  open: boolean;
  close: () => any;
  eligibility?: IEligibility;
}

export const EditEligibilityDialog = ({ open, close, eligibility }: IProps) => {
  const dispatch = useDispatch();
  const initialValues: any =
    { displayKeyComp: eligibility?.displayKeyComp, disAllowedComp: eligibility?.disAllowedComp } || {};

  const onSubmit = useCallback(
    (values: Array<any>, helpers: FormikHelpers<Array<any>>) => {
      close();
      if (eligibility) {
        const submitResult = dispatch(
          updateCompEligibility({
            recordKey: eligibility.recordKey,
            displayKeyComp: eligibility.displayKeyComp,
            disAllowedComp: (values as any).disAllowedComp,
          }),
        ) as any;
        submitResult.catch(() => helpers.setSubmitting(false));
      }
    },
    [close, dispatch, eligibility],
  );

  if (!open) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="edit-eligibility-dialog-title"
          aria-describedby="edit-eligibility-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="edit-eligibility-dialog-title">Edit Eligibility Rule</DialogTitle>
          <DialogContent>
            <EditEligibilityForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="edit-eligibility-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
