import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./spinner.css";

interface IProps {
  loading: boolean;
  className?: string;
}

const Spinner = ({ loading }: IProps) => {
  return loading ? (
    <div
      className="ibm__spinner_container"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      role="status"
      aria-busy={true}
      aria-live="assertive"
      aria-label="Loading"
    >
      <CircularProgress size={50} thickness={2} />
    </div>
  ) : null;
};

export default Spinner;
