import React, { useCallback } from "react";
import { Drawer, Typography, useTheme } from "@mui/material";
import { IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { NestedRules } from "containers/checklist/NestedRules";
import { closeSubjectChecklist } from "actions/checklistDrawerForSubjectId";
import { SubjectChecklistWarning } from "containers/checklist/SubjectChecklistWarning";
import { commonStyles } from "theme/styles";
import { getSubjectStudyPeriod } from "lib/plan";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { max } from "lodash";

export const Title = styled.strong`
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

const BannerContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid lightgray;
  background: #eed202;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const currentYear = new Date().getFullYear();

export function SubjectChecklistDrawer() {
  // What we need from the Redux state
  const subjectRecordId = useSelector((s: IState) => s.checklistDrawerForSubjectId);
  const userIsDragging = useSelector((s: IState) => !!s.draggedSubjectId);
  const { plan, subjects, course } = useSelector((state: IState) => state.enrollment!);
  const latestCourseYear = max(course.allowedStartYears || []) || currentYear;
  const planId = plan?.id;
  const subject = (subjects || []).find((s) => s.recordId === subjectRecordId);

  const subjectStudyPeriod = getSubjectStudyPeriod(plan, subjectRecordId ?? undefined);
  let subjectIsInRightYear =
    subject && subjectStudyPeriod && subject.publishedYears.includes(String(subjectStudyPeriod.year));
  if (!subjectIsInRightYear && subject && subjectStudyPeriod && Number(subjectStudyPeriod.year) > latestCourseYear) {
    subjectIsInRightYear = subject.publishedYears.includes(String(latestCourseYear));
  }

  const theme = useTheme();
  const styles = commonStyles(theme);

  const dispatch = useDispatch();
  const closeDrawer = useCallback(() => {
    dispatch(closeSubjectChecklist());
  }, [dispatch]);

  const validation = useSelector((s: IState) => s.enrollment?.validation);
  const ruleInvocations = (validation?.invocations || []).filter((i) => i.recordId === subject?.recordId);

  const open = !!planId && !!subject;

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={closeDrawer}
      PaperProps={{
        sx: styles.drawerPaper,
      }}
      ModalProps={{
        disableEscapeKeyDown: userIsDragging,
      }}
      keepMounted
    >
      <Typography variant="h6" variantMapping={{ h6: "h4" }}>
        Rules for &quot;{subject?.name}&quot;
      </Typography>
      {subjectStudyPeriod && !subjectIsInRightYear && (
        <BannerContainer>
          <WarningOutlinedIcon fontSize="small" />
          &nbsp;
          <strong>Note:</strong> &nbsp; Subject is not available in {subjectStudyPeriod?.year}.
        </BannerContainer>
      )}
      <NestedRules invocations={ruleInvocations} expandedByDefault={true} />
      <SubjectChecklistWarning subjectRecordId={subject?.recordId} />
    </Drawer>
  );
}
