import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import Slide from "@mui/material/Slide";
import { DialogContent, Grid, InputAdornment, Switch, TextField, useTheme } from "@mui/material";
import {
  BreadthDisciplineScope,
  COURSE_GRID_SEMESTERS,
  HintToolTip,
  IState,
  ISubject,
  ISubjectQuery,
  Semester,
} from "store/types";
import { ResponsiveCenter } from "components/spinner/Center";
import { useDispatch, useSelector } from "react-redux";
import identity from "lodash/identity";
import Spinner from "components/spinner";
import { SubjectSlotSearchCard } from "containers/slotCard/subjectSlotSearchCard";
import { assignSubject, setSubjectSearchOpenState } from "actions/enrollment";
import {
  CheckboxControlLabel,
  MultiSelectField,
  PaginationContainer,
  ScrollDialogContent,
  SubjectSearchResults,
} from "./common";
import { InitialSearchQuery, Level } from "lib/searchConstants";
import { AutocompleteMultiSelect } from "./AutocompleteMultiSelect";
import { SubjectDetailsModal } from "containers/slotCard/SubjectDetailsModal";
import { getDefaultLevel } from "lib/search";
import { Hint } from "containers/Hint";
import { useBreakpoint } from "lib/useBreakpoint";
import { Breakpoint } from "@mui/material/styles";
import { useSearchStyles } from "theme/styles";
import { useSearch, useSearchPagination } from "./hooks";
import { SubjectResultsHeader } from "./SubjectResultsHeader";
import { computePointsOptions, findSlot, getSubjectRecordIds } from "lib/plan";
import { Pagination } from "@mui/material";
import { useNavigate } from "@reach/router";
import { analyseSubject, getSubject, isSubjectAssignable } from "lib/subjects";
import { useAssignedCourseRecordIds, useAssignedCourses } from "lib/assignment";
import { isGraduateCourse } from "lib/course";
import { orderBy } from "lodash";

const Transition = React.forwardRef(function Transition(
  props: { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tooltipSizes: Record<Breakpoint, string> = {
  xs: "0px",
  sm: "150px",
  md: "175px",
  lg: "200px",
  xl: "300px",
};
const tooltipMargin: Record<Breakpoint, string> = {
  xs: "0px",
  sm: "180px",
  md: "190px",
  lg: "175px",
  xl: "200px",
};

interface IProps {
  path?: string;
  id?: string;
  year?: number;
  semester?: Semester;
  slotIndex?: string;
}

export const SubjectSearchModal = ({ id, year, semester, slotIndex }: IProps) => {
  const navigate = useNavigate();
  const closeModal = useCallback(() => id && navigate(`../../../../../../`), [id, navigate]);
  const theme = useTheme();
  const styles = useSearchStyles(theme);
  const assignedCourses = useAssignedCourses();
  const assignedCourseIds = useAssignedCourseRecordIds();

  const searchSemester = semester && COURSE_GRID_SEMESTERS.includes(semester) ? semester : undefined;

  // Details modal - target subject
  const [detailedSubjectId, setDetailedSubjectId] = useState<string | null>(null);
  const onShowDetails = useCallback((id: string) => () => setDetailedSubjectId(id), [setDetailedSubjectId]);
  const onCloseDetails = useCallback(() => setDetailedSubjectId(null), [setDetailedSubjectId]);

  // What we need from the Redux state
  const {
    course: { recordId: courseRecordId, points: coursePoints },
    courseAreasOfStudy,
    plan,
    overloads,
    currentHint,
    subjects,
  } = useSelector((state: IState) => state.enrollment!);
  const isUndergrad = useSelector((s: IState) => !isGraduateCourse(s?.enrollment?.course));

  const areasOfStudy = useMemo(() => courseAreasOfStudy.map((a) => a.area), [courseAreasOfStudy]);
  const getAreaDescription = useCallback(
    (area: string) => courseAreasOfStudy.find((aos) => aos.area === area)?.description || area,
    [courseAreasOfStudy],
  );

  const searchResults = useSelector((state: IState) => state.subjectSearch.results);
  const { enablePagination, numPages } = useSearchPagination();
  const originalResults = (searchResults || []).filter((s: ISubject) => !s?.discoveryResult);
  const discoveryResults = (searchResults || []).filter((s: ISubject) => s?.discoveryResult);

  const planSubjectRecordIds = getSubjectRecordIds(plan);

  // Order results, so discovery results come last
  const subjectResults = [...originalResults, ...discoveryResults];
  const pointsOptions = computePointsOptions(
    { year, semester, slotSequence: slotIndex },
    plan,
    coursePoints,
    overloads,
  );

  // Subject details for subject in slot (if any)
  const slot = findSlot(
    {
      year: year!,
      semester: semester!,
      slotIndex: slotIndex === null || slotIndex === undefined ? 0 : Number(slotIndex!),
    },
    plan.studyPeriods,
  );
  const assignedSubject = useMemo(() => getSubject(subjects, slot?.subjectRecordId), [slot?.subjectRecordId, subjects]);
  const assignedSubjectAnalysis = useMemo(() => assignedSubject && analyseSubject(assignedSubject), [assignedSubject]);

  // Default search
  const initialQuery: ISubjectQuery = useMemo(
    () => ({
      ...InitialSearchQuery,
      points: pointsOptions,
      level: year && semester && isUndergrad ? [getDefaultLevel(year, semester, plan)].filter(Boolean) : [],
      studyPeriod: searchSemester ? [searchSemester] : [],
      courseRecordIds: assignedCourseIds,
      onlyBreadth: assignedSubjectAnalysis?.isBreadth ?? false,
      onlyDiscipline: assignedSubjectAnalysis?.isDiscipline ?? true,
      sort: {
        property: "code",
        direction: "ASC",
      },
    }),
    [
      isUndergrad,
      pointsOptions,
      year,
      semester,
      plan,
      assignedCourseIds,
      searchSemester,
      assignedSubjectAnalysis?.isBreadth,
      assignedSubjectAnalysis?.isDiscipline,
    ],
  );
  const {
    query,
    searchYear,
    setQuery,
    updateQuery,
    updateSort,
    onSearchTextChange,
    searching,
    toggleBreadth,
    toggleDiscipline,
    updatePage,
  } = useSearch(year, initialQuery);

  // Search whenever we change the selected slot
  useEffect(() => {
    if (semester && year) {
      setQuery((old) => ({
        ...old,
        level: isUndergrad ? [getDefaultLevel(year, semester, plan)].filter(Boolean) : [],
        studyPeriod: searchSemester ? [searchSemester] : [],
        page: 0,
        sort: null,
      }));
    }
  }, [isUndergrad, searchSemester, semester, year, plan, setQuery]);

  const dispatch = useDispatch();

  const assignAs = !!query.onlyBreadth && !query.onlyDiscipline ? BreadthDisciplineScope.Breadth : undefined;
  // Card click action
  const createAddSubject = (subjectRecordId: string) => () => {
    const s = subjectResults.find((s) => s.recordId === subjectRecordId);
    const assignableCourses = orderBy(
      assignedCourses.filter((c) => isSubjectAssignable(c.code, s)),
      (c) => (query.courseRecordIds.indexOf(c.recordId) >= 0 ? 0 : 1),
    );
    dispatch(
      assignSubject({
        planId: id!,
        courseRecordId,
        courseRecordIds: [assignableCourses[0]?.recordId ?? courseRecordId],
        subjectRecordId,
        year: year!,
        semester: semester!,
        slotIndex: parseInt(slotIndex! as any),
        assignAs,
      }),
    );
    closeModal();
  };

  // Hande the hints ...
  // Tell redux that the modal is open - we need this for the hints
  useEffect(() => {
    dispatch(setSubjectSearchOpenState(true));
    return () => {
      dispatch(setSubjectSearchOpenState(false));
    };
  }, [dispatch]);
  const breakpoint = useBreakpoint();

  if (!id || !semester || !year || slotIndex === undefined) {
    return null;
  }

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={closeModal}
        TransitionComponent={Transition}
        // For Accessibility - https://github.com/mui-org/material-ui/issues/18935#issuecomment-665835537
        TransitionProps={{ role: "presentation" } as any}
      >
        <AppBar sx={styles.appBar}>
          <ResponsiveCenter>
            <Toolbar>
              <TextField
                sx={styles.textField}
                autoFocus={true}
                fullWidth={true}
                onChange={onSearchTextChange}
                placeholder="Search Subjects"
                variant="outlined"
                InputLabelProps={{ sx: styles.label }}
                inputProps={{ "aria-label": "Search Subjects" }}
                InputProps={{
                  sx: styles.textFieldInputRoot,
                  startAdornment: (
                    <InputAdornment position="start" sx={styles.adornment}>
                      <SearchOutlined sx={styles.icon} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button color="inherit" onClick={closeModal} aria-label="Close">
                <CloseIcon sx={styles.icon} />
              </Button>
            </Toolbar>
          </ResponsiveCenter>
        </AppBar>
        <DialogContent>
          <ResponsiveCenter>
            <ScrollDialogContent
              style={{
                marginRight: currentHint === HintToolTip.SUBJECT_FINDER ? tooltipMargin[breakpoint] : "initial",
              }}
            >
              <Hint
                hintType={HintToolTip.SUBJECT_FINDER}
                width={tooltipSizes[breakpoint]}
                hideHint={breakpoint === "xs"}
                placement="right-start"
                disablePortal
              >
                <Grid container spacing={2} style={{ width: "100%" }}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <MultiSelectField
                      id="level-select"
                      name="level"
                      label="Level"
                      formValue={query.level}
                      allValues={Level}
                      placeholder="Select levels"
                      formatLabel={(l: string) => `Level ${l}`}
                      onChange={updateQuery("level")}
                      renderValue={(n) => `${n} levels selected`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <MultiSelectField
                      id="studyPeriod-select"
                      name="studyPeriod"
                      label="Study Period"
                      formValue={query.studyPeriod}
                      allValues={COURSE_GRID_SEMESTERS}
                      placeholder="Select study period"
                      formatLabel={identity}
                      onChange={updateQuery("studyPeriod")}
                      renderValue={(n) => `${n} study terms selected`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <AutocompleteMultiSelect
                      id="area-of-study-list"
                      label="Area of Study"
                      options={areasOfStudy}
                      formValue={query.areaOfStudy}
                      formatLabel={getAreaDescription}
                      onChange={updateQuery("areaOfStudy")}
                      placeholder="Area of Study"
                    />
                  </Grid>

                  {assignedCourses.length > 1 && (
                    <Grid item xs={12} sm={6} lg={4}>
                      <AutocompleteMultiSelect
                        id="assigned-courses-list"
                        label="Course"
                        options={assignedCourseIds}
                        formValue={query.courseRecordIds}
                        formatLabel={(v) => assignedCourses.find((c) => c.recordId === v)?.name ?? "N/A"}
                        onChange={updateQuery("courseRecordIds")}
                        placeholder="Courses"
                      />
                    </Grid>
                  )}

                  {isUndergrad && (
                    <Grid item xs={12} sm={6} lg={4}>
                      <CheckboxControlLabel
                        labelPlacement="end"
                        control={
                          <Switch
                            value={query.onlyBreadth}
                            checked={query.onlyBreadth}
                            onChange={toggleBreadth}
                            type="checkbox"
                            color="primary"
                          />
                        }
                        label="Breadth Subjects?"
                      />
                    </Grid>
                  )}
                  {isUndergrad && (
                    <Grid item xs={12} sm={6} lg={4}>
                      <CheckboxControlLabel
                        labelPlacement="end"
                        control={
                          <Switch
                            value={query.onlyDiscipline}
                            checked={query.onlyDiscipline}
                            onChange={toggleDiscipline}
                            type="checkbox"
                            color="primary"
                          />
                        }
                        label="Discipline Subjects?"
                      />
                    </Grid>
                  )}
                </Grid>
              </Hint>

              {!searching && <SubjectResultsHeader query={query} searchYear={searchYear} sortSubjects={updateSort} />}

              <SubjectSearchResults>
                {!searching && (
                  <Grid container spacing={2}>
                    {subjectResults.map((s) => (
                      <Grid item key={s.id} xs={12} sm={6} md={4} lg={3}>
                        <SubjectSlotSearchCard
                          key={s.id}
                          subject={s}
                          alreadyOnPlan={planSubjectRecordIds.indexOf(s.recordId) >= 0}
                          addToPlan={createAddSubject(s.recordId)}
                          showDetails={onShowDetails(s.recordId)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Spinner loading={searching} />
              </SubjectSearchResults>
              {(enablePagination || searching) && (
                <PaginationContainer>
                  <Pagination
                    count={numPages}
                    page={query.page + 1}
                    onChange={updatePage}
                    color="primary"
                    showFirstButton={numPages > 3}
                    showLastButton={numPages > 3}
                  />
                </PaginationContainer>
              )}
            </ScrollDialogContent>
          </ResponsiveCenter>
        </DialogContent>
      </Dialog>

      {detailedSubjectId && (
        <SubjectDetailsModal
          forYear={year}
          subjectRecordId={detailedSubjectId}
          addToPlan={createAddSubject(detailedSubjectId)}
          close={onCloseDetails}
        />
      )}
    </>
  );
};
