import { SubjectTag } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import { ListItemIcon, ListItemText } from "@mui/material";
import MenuBook from "@mui/icons-material/MenuBook";
import Done from "@mui/icons-material/Done";
import { getSubjectAssignmentLabel } from "../../../lib/subjects";
import React from "react";

interface IProps {
  subjectMeta: any;
  selectedSubjectRecordId: string | undefined;
  doChangeAssignment: (assignment: string) => any;
}

export const AssignSubjectSubMenu = (props: IProps) => {
  const { selectedSubjectRecordId, subjectMeta, doChangeAssignment } = props;
  return (
    <>
      {
        <SubMenu
          key={`subject-menu-assign-subject-sub-menu-${selectedSubjectRecordId}`}
          label={() => (
            <>
              <ListItemIcon>
                <MenuBook />
              </ListItemIcon>
              <ListItemText primary="Assign subject" />
            </>
          )}
        >
          {[SubjectTag.BREADTH, SubjectTag.CAN_BE_DISCIPLINE].map((subjectType) => {
            const selected =
              (subjectType.toString() === SubjectTag.CAN_BE_DISCIPLINE && subjectMeta.isDiscipline) ||
              (subjectType.toString() === SubjectTag.BREADTH && subjectMeta.isBreadth);
            const disabled = false;
            return (
              <MenuItem
                type="checkbox"
                key={subjectType}
                checked={selected}
                aria-pressed={selected}
                className={disabled ? "menu-list-item-disabled" : undefined}
                aria-disabled={disabled}
                // disabled={disabled} not used so that users using assistive tech can still tab to it
                onClick={disabled ? undefined : doChangeAssignment(subjectType)}
              >
                <ListItemIcon>
                  {selected && <Done className={disabled ? "menu-list-item-icon-disabled" : undefined} />}
                </ListItemIcon>
                <ListItemText
                  primary={getSubjectAssignmentLabel(subjectType)}
                  aria-label={`${getSubjectAssignmentLabel(subjectType)} ${disabled ? "Disabled," : ""}`}
                />
              </MenuItem>
            );
          })}
        </SubMenu>
      }
    </>
  );
};
