import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IAutoAssignmentResult {
  assignedRecordIds: string[];
  unassignedRecordIds: string[];
}

interface IProps {
  open: boolean;
  result: IAutoAssignmentResult | null;
  onConfirm: () => any;
}

export const AutoAssignmentSummaryDialog = React.memo(({ open, result, onConfirm }: IProps) => {
  // console.log("result ...", result);

  return (
    <Dialog
      open={open}
      onClose={onConfirm}
      aria-labelledby="add-core-subjects-summary-dialog-title"
      aria-describedby="add-core-subjects-summary-dialog-description"
    >
      <DialogTitle id="add-core-subjects-summary-dialog-title">
        ⚠️&nbsp;
        {result?.unassignedRecordIds?.length ? "Warning - Please check requirements" : "Added core subjects"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="add-core-subjects-summary-dialog-description">
          {result?.unassignedRecordIds?.length
            ? "Not all core subjects have been added to your plan. Please review the course rules to add subjects in manually."
            : "Your core subjects have been added, please check their requisites and ensure you meet the requirements."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});
