import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { BreadthDisciplineScope, IRuleInvocation, IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import flatten from "lodash/flatten";
import fromPairs from "lodash/fromPairs";
import { getSubjectRecordIds, isSelfEvalTicked } from "lib/plan";
import { SubjectChip } from "./SubjectChip";
import { isSubjectAssignable } from "lib/subjects";
import { isInfoRule } from "lib/invocations";
import { Aligned } from "components/aligned/alignedDiv";
import { Switch, Tooltip } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { SelfEvalDialog } from "./SelfEvalDialog";
import { flipSelfEvalSelection } from "actions/enrollment";
import { validationGreen } from "theme/colors";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { useAssignedCourseCodes } from "lib/assignment";

const InnerTextDiv = styled.div`
  p {
    margin: 0rem;
  }
`;

const SelfEvalSwitch = muiStyled(Switch)(() => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: validationGreen.toString(),
      "& + .MuiSwitch-track": {
        backgroundColor: validationGreen.toString(),
      },
    },
  },
}));

interface IProps {
  invocation: IRuleInvocation;
  mmsRecordId?: string;
  extraDndToken?: string;
  assignAs?: BreadthDisciplineScope;
}

const tokenizeByCode = (txt: string, code: string) => {
  const parts = txt.split(code);
  for (let i = parts.length - 1; i > 0; i--) {
    parts.splice(i, 0, code);
  }
  return parts;
};

const tokenizeByCodes = (txt: string, codes: string[]) => {
  let result = [txt];
  for (const code of codes) {
    result = flatten(result.map((e) => tokenizeByCode(e, code)));
  }
  return result;
};

export const ChecklistInvocationText = (props: IProps) => {
  const { invocation } = props;
  if (isInfoRule(invocation) && invocation.selfEvaluated) {
    return <SelfAssesInvocationText invocation={invocation} />;
  } else if (invocation.isHtml) {
    return <InnerTextDiv dangerouslySetInnerHTML={{ __html: invocation.text }} />;
  } else if (invocation.referredSubjectCodes.length === 0) {
    return <InnerTextDiv>{invocation.text}</InnerTextDiv>;
  } else {
    return <InvocationTextWithSubjects {...props} />;
  }
};

const SelfAssesInvocationText = ({ invocation }: { invocation: IRuleInvocation }) => {
  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const planId = plan?.id;
  const { ruleId, recordId } = invocation;
  const dispatch = useDispatch();

  const { readOnlyPlan } = usePlanPermissions();

  const selfEvalTicked = isSelfEvalTicked(plan, invocation);
  const [evalDialogOpen, setEvalDialogOpen] = useState(false);
  const onCancel = useCallback(() => setEvalDialogOpen(false), []);
  const flipSelection = useCallback(() => {
    dispatch(flipSelfEvalSelection(planId!, { ruleId, recordId }));
    setEvalDialogOpen(false);
  }, [dispatch, planId, ruleId, recordId]);

  const onSwitchChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setEvalDialogOpen(true);
      } else {
        flipSelection();
      }
    },
    [flipSelection],
  );

  return (
    <>
      <SelfEvalDialog open={evalDialogOpen} onCancel={onCancel} onAccept={flipSelection} />
      <Aligned style={{ justifyContent: "space-between", alignItems: "start", height: "100%" }}>
        {!invocation.isHtml && <InnerTextDiv>{invocation.text}</InnerTextDiv>}
        {invocation.isHtml && <InnerTextDiv dangerouslySetInnerHTML={{ __html: invocation.text }} />}

        {!readOnlyPlan && (
          <Tooltip arrow aria-label="accept or decline" title={"Do you plan to meet this requirement?"}>
            <div style={{ marginRight: "-10px", marginTop: "-8px" }}>
              <SelfEvalSwitch
                checked={selfEvalTicked}
                onChange={onSwitchChange}
                type="checkbox"
                color="secondary"
                name="accept-or-decline"
                inputProps={{ "aria-label": "accept-or-decline" }}
              />
            </div>
          </Tooltip>
        )}
      </Aligned>
    </>
  );
};

const InvocationTextWithSubjects = ({ invocation, mmsRecordId, extraDndToken, assignAs }: IProps) => {
  const referredSubjects = useSelector((s: IState) => {
    const subjects = s.enrollment?.referredSubjects || [];
    return fromPairs(subjects.map((s) => [s.code, s]));
  });

  const planSubjectRecordIds = useSelector((s: IState) => getSubjectRecordIds(s.enrollment?.plan));
  const courseCodes = useAssignedCourseCodes();

  const tokens = tokenizeByCodes(invocation.text, invocation.referredSubjectCodes);
  return (
    <InnerTextDiv aria-label={invocation.text} style={{ whiteSpace: "pre-line" }}>
      {tokens.map((token, i) =>
        referredSubjects[token] ? (
          <React.Fragment key={`${token}-${i}-${mmsRecordId}`}>
            <React.Fragment key={`${token}-${i}-${mmsRecordId}`}>
              <SubjectChip
                inPlan={planSubjectRecordIds.indexOf(referredSubjects[token]?.recordId) >= 0}
                isAssignable={isSubjectAssignable(courseCodes, referredSubjects[token])}
                subject={referredSubjects[token]}
                mmsRecordId={mmsRecordId}
                dndIdToken={`${invocation.groupId}-${invocation.ruleId}-${i}-referred-subject-${extraDndToken ?? ""}`}
                btnId={`${referredSubjects[token].recordId}-${mmsRecordId}-${invocation.ruleId}`}
                assignAs={assignAs}
              />{" "}
            </React.Fragment>
          </React.Fragment>
        ) : (
          token
        ),
      )}
    </InnerTextDiv>
  );
};
