import { BreadthDisciplineScope, ISlot, ISlotIdentifier, IState, Semester } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import { ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignSubject } from "../../../actions/enrollment";
import ControlCameraOutlinedIcon from "@mui/icons-material/ControlCameraOutlined";
import Add from "@mui/icons-material/AddCircleOutlineOutlined";
import { moveOptimistically } from "../../../actions/optimisticUpdate";
import { isSubjectAssignable, useAllKnownSubjects } from "lib/subjects";
import { orderBy } from "lodash";
import { useAssignedCourses } from "lib/assignment";

interface IProps {
  dragDestinations: any;
  selectedSubjectRecordId: string | undefined;
  slot?: ISlot | null;
  year?: number;
  semester?: Semester;
  slotIndex?: number;
  mmsRecordId?: string;
  assignAs?: BreadthDisciplineScope;
}

export const MoveSubjectSubMenu = (props: IProps) => {
  const { dragDestinations, selectedSubjectRecordId, slot, year, semester, slotIndex, mmsRecordId, assignAs } = props;

  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const allSubjects = useAllKnownSubjects();
  const query = useSelector((state: IState) => state.subjectSearch?.query);
  const assignedCourses = useAssignedCourses();
  const planId = plan?.id;

  const courseRecordId = useSelector((s: IState) => s.enrollment?.plan.courseRecordId);

  const dispatch = useDispatch();

  const subjectMover = (destination: ISlotIdentifier) => () => {
    if (planId && selectedSubjectRecordId && courseRecordId) {
      const s = allSubjects.find((s) => s.recordId === selectedSubjectRecordId);
      const assignableCourses = orderBy(
        assignedCourses.filter((c) => s && isSubjectAssignable(c.code, s)),
        (c) => ((query?.courseRecordIds ?? []).indexOf(c.recordId) >= 0 ? 0 : 1),
      );

      const source = {
        year,
        semester,
        slotIndex,
        selectedSubjectRecordId,
      };
      dispatch(moveOptimistically(source, destination));
      dispatch(
        assignSubject({
          planId,
          courseRecordId,
          courseRecordIds: slot?.courseRecordIds ?? [assignableCourses[0]?.recordId ?? courseRecordId],
          subjectRecordId: selectedSubjectRecordId,
          mmsRecordId,
          year: destination.year,
          semester: destination.semester,
          slotIndex: destination.slotIndex,
          assignAs,
        }),
      );
    }
  };

  return (
    <>
      {
        <SubMenu
          key={`subject-menu-move-sub-menu-${selectedSubjectRecordId}`}
          label={() => (
            <>
              <ListItemIcon>{slot ? <ControlCameraOutlinedIcon /> : <Add />}</ListItemIcon>
              <ListItemText primary={slot ? "Move" : "Add to plan"} />
            </>
          )}
        >
          {dragDestinations.map((dest: any) => (
            <MenuItem key={`${dest.year}-${dest.semester}`} onClick={subjectMover(dest)}>
              <ListItemText primary={`${dest.year}, ${dest.semester}`} />
            </MenuItem>
          ))}
        </SubMenu>
      }
    </>
  );
};
