import { IComponent, IEnrollment, IState } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import { brandGrey } from "../../../theme/colors";
import { Checkbox, Icon } from "@mui/material";
import { ReactComponent as MMSIcon } from "../MMS.svg";
import ListItemText from "@mui/material/ListItemText";
import { usePlanPermissions } from "../../../lib/usePlanPermissions";
import { featureToggles } from "../../../config/featureToggles";
import { isDiscontinued } from "../../../lib/discontinuedMMSRules";
import { useSelector } from "react-redux";

interface IProps {
  enrollment: IEnrollment;
  courseSpecialisations: IComponent[];
  isComponentEnabled: (c: IComponent) => any;
  doChangeMSSEnrollment: (mmsRecordIds: string[]) => any;
}

export const SpecialisationsSubMenu = (props: IProps) => {
  const { enrollment, isComponentEnabled, doChangeMSSEnrollment } = props;
  const entry = enrollment?.plan?.entry;
  const enrollmentYear = enrollment.plan?.year;
  let courseSpecialisations = props.courseSpecialisations;
  if (entry && entry.points) {
    courseSpecialisations = courseSpecialisations.filter(
      (c) => entry.points && c.name.includes(entry.points.toString()),
    );
  }
  const admin = useSelector((state: IState) => state.admin!);
  const discontinuedRecords = admin?.discontinuedRecords;
  const { readOnlyPlan } = usePlanPermissions();
  const mmsRecordIds = enrollment.plan?.mmsRecordIds;
  if (featureToggles.discontinuedMMS) {
    courseSpecialisations = courseSpecialisations.filter(
      (m: IComponent) => !isDiscontinued(discontinuedRecords, m, enrollmentYear),
    );
  }

  return (
    <>
      {courseSpecialisations && courseSpecialisations.length > 0 && !readOnlyPlan && (
        <SubMenu
          key="select-specialisation-menu-item"
          label={() => (
            <React.Fragment>
              <ListItemIcon style={{ color: brandGrey.toHexString() }}>
                <Icon component={MMSIcon} />
              </ListItemIcon>
              <ListItemText primary="Select Specialisation" />
            </React.Fragment>
          )}
        >
          {courseSpecialisations.map((m: IComponent) => {
            const disabledMajor = !isComponentEnabled(m);
            return (
              <MenuItem
                type="checkbox"
                tabIndex={0}
                checked={mmsRecordIds.includes(m.recordId)}
                key={m.recordId}
                aria-disabled={disabledMajor}
                className={disabledMajor ? "menu-list-item-disabled" : undefined}
                onClick={disabledMajor ? undefined : doChangeMSSEnrollment([m.recordId])}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={mmsRecordIds.includes(m.recordId)}
                    tabIndex={-1}
                    disableRipple
                    className={disabledMajor ? "list-checkbox-disabled" : undefined}
                    disabled={disabledMajor}
                    inputProps={{ "aria-label": `${disabledMajor ? "Disabled" : ""} Checkbox ${m.name}` }}
                  />
                </ListItemIcon>
                <ListItemText primary={m.name} />
              </MenuItem>
            );
          })}
        </SubMenu>
      )}
    </>
  );
};
