import { get } from "lib/fetch";
import { IState, ISubjectQuery } from "store/types";
import { isQueryEmpty } from "lib/search";
import store from "store";
import { getSubjectRecordIds } from "lib/plan";

export const SUBJECTS_SEARCH = "subjectSearch/SEARCH";
export const CLEAR_SEARCH = "subjectSearch/CLEAR_SEARCH";

export const searchSubjects = (searchYear: number, q: ISubjectQuery) => {
  const { enrollment } = store.getState() as IState;
  const allCourses = [enrollment?.course, ...(enrollment?.allowedSecondaryCourses ?? [])].filter(Boolean);
  const courseEffectiveYear = searchYear ?? enrollment?.course?.effectiveYear;
  const courseRecordId = enrollment?.course?.recordId;

  const { page, pageSize, sort, ...apiQuery } = q;
  const query = { ...apiQuery, courseCodes: [] as string[], courseEffectiveYear };

  // Need to send string array to the API
  const payloadQuery = { ...query };
  if (payloadQuery.points) {
    payloadQuery.points = payloadQuery.points.map(String) as any;
  }

  // Ensure we always provide course record and codes
  payloadQuery.courseRecordIds =
    payloadQuery.courseRecordIds.length === 0 ? [courseRecordId!] : payloadQuery.courseRecordIds;
  payloadQuery.courseRecordIds = payloadQuery.courseRecordIds.filter(Boolean);
  payloadQuery.courseCodes = payloadQuery.courseRecordIds
    .map((id) => allCourses.find((c) => c?.recordId === id)?.code)
    .filter(Boolean) as string[];

  const apiSortParam = sort ? { [sort.property]: sort.direction } : { code: "ASC" };
  const timeSearchAPICalled = Date.now();

  const state: IState = store.getState();
  const assignedSubjectRecordIds = getSubjectRecordIds(state.enrollment?.plan);

  return isQueryEmpty(query)
    ? {
        type: SUBJECTS_SEARCH,
        payload: Promise.resolve({
          results: [],
          totalCount: 0,
          query: { ...query, page, pageSize, sort },
          searchTimestamp: timeSearchAPICalled,
          searchYear,
          assignedSubjectRecordIds,
        }),
      }
    : {
        type: SUBJECTS_SEARCH,
        payload: get(
          `/v1/subject?year=${searchYear}&query=${JSON.stringify(payloadQuery)}&sort=${JSON.stringify(
            apiSortParam,
          )}&paging=${JSON.stringify({ page, pageSize })}`,
        ).then((payload) => ({
          ...payload,
          query: { ...query, page, pageSize, sort },
          page,
          pageSize,
          searchTimestamp: timeSearchAPICalled,
          searchYear,
          assignedSubjectRecordIds,
        })),
      };
};

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
  payload: {},
});
