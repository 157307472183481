import { ComponentType, IComponent, IEnrollment, IState } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import { brandGrey } from "../../../theme/colors";
import { Checkbox, Icon } from "@mui/material";
import { ReactComponent as MMSIcon } from "../MMS.svg";
import ListItemText from "@mui/material/ListItemText";
import { isDupMMSAgainstSecondaryCourseRule, isValidNoDupComponentRule } from "../../../lib/componentsEligibilityRules";
import { featureToggles } from "../../../config/featureToggles";
import { intersection } from "lodash";
import { usePlanPermissions } from "../../../lib/usePlanPermissions";
import { isDiscontinued } from "../../../lib/discontinuedMMSRules";
import { useSelector } from "react-redux";

interface IProps {
  enrollment: IEnrollment;
  majorsAndMinors: IComponent[];
  specialisations: IComponent[];
  selectedMajors: IComponent[];
  selectedSpecialisations: Array<string>;
  selectedDiplomas: any;
  isComponentEnabled: (c: IComponent) => any;
  doChangeMSSEnrollment: (mmsRecordIds: string[]) => any;
}

export const MinorsSubMenu = (props: IProps) => {
  const {
    enrollment,
    majorsAndMinors,
    specialisations,
    selectedMajors,
    selectedSpecialisations,
    selectedDiplomas,
    isComponentEnabled,
    doChangeMSSEnrollment,
  } = props;

  let minors = majorsAndMinors.filter((m: IComponent) => m.type === ComponentType.MINOR);
  const { readOnlyPlan } = usePlanPermissions();
  const mmsRecordIds = enrollment.plan?.mmsRecordIds;
  const enrollmentYear = enrollment.plan?.year;

  const minorsWithSpecialisations = minors.map((m: IComponent) => ({
    ...m,
    children: (specialisations || []).filter((s: IComponent) => s.parentRecordId === m.recordId),
  }));
  const admin = useSelector((state: IState) => state.admin!);
  const discontinuedRecords = admin?.discontinuedRecords;

  if (featureToggles.discontinuedMMS) {
    minors = minors.filter((m: IComponent) => !isDiscontinued(discontinuedRecords, m, enrollmentYear));
  }

  return (
    <>
      {minors && minors.length > 0 && !readOnlyPlan && (
        <SubMenu
          key="select-minor-menu-item"
          overflow="auto"
          label={() => (
            <React.Fragment>
              <ListItemIcon style={{ color: brandGrey.toHexString() }}>
                <Icon component={MMSIcon} />
              </ListItemIcon>
              <ListItemText primary="Select Minor" />
            </React.Fragment>
          )}
        >
          {minorsWithSpecialisations.map((m: IComponent) => {
            const dup = selectedMajors.find((major: any) => !isValidNoDupComponentRule(major, m));
            const dupWithDiploma = isDupMMSAgainstSecondaryCourseRule(selectedDiplomas, m);
            const disabledMinor =
              !isComponentEnabled(m) ||
              (!!dup && featureToggles.eligibilityRule) ||
              (featureToggles.componentEligibility && dupWithDiploma);
            return (m?.children || []).length > 0 ? (
              <SubMenu
                key={m.recordId}
                disabled={disabledMinor}
                label={() => (
                  <React.Fragment>
                    <ListItemIcon style={{ paddingLeft: "11px" }}>
                      <Checkbox
                        edge="start"
                        checked={mmsRecordIds.includes(m.recordId)}
                        tabIndex={-1}
                        className={disabledMinor ? "list-checkbox-disabled" : undefined}
                        disabled={disabledMinor}
                        disableRipple
                        inputProps={{
                          "aria-label": `${disabledMinor ? "Disabled" : ""} Checkbox ${m.name}`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={m.name.replace(new RegExp(/minor/gi), "")} />
                  </React.Fragment>
                )}
              >
                {m.children?.map((s: IComponent) => {
                  const numSpecialisations = intersection(
                    mmsRecordIds,
                    (m?.children || []).map((s) => s.recordId),
                  ).length;
                  const selected = selectedSpecialisations.includes(s.recordId);
                  const updateMajor = (selected && numSpecialisations === 1) || (!selected && numSpecialisations === 0);
                  const disabledSpecialisation = !isComponentEnabled(s);
                  return (
                    <MenuItem
                      type="checkbox"
                      key={s.recordId}
                      tabIndex={0}
                      checked={mmsRecordIds.includes(s.recordId)}
                      // disabled={disabledSpecialisation}  not used so that users using assistive tech can still tab to it
                      aria-disabled={disabledSpecialisation}
                      className={disabledSpecialisation ? "menu-list-item-disabled" : undefined}
                      onClick={
                        disabledSpecialisation
                          ? undefined
                          : doChangeMSSEnrollment(updateMajor ? [m.recordId, s.recordId] : [s.recordId])
                      }
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={mmsRecordIds.includes(s.recordId)}
                          tabIndex={-1}
                          className={disabledSpecialisation ? "list-checkbox-disabled" : undefined}
                          disableRipple
                          disabled={disabledSpecialisation}
                          inputProps={{
                            "aria-label": `${disabledSpecialisation ? "Disabled" : ""} Checkbox ${s.name}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={s.name} />
                    </MenuItem>
                  );
                })}
              </SubMenu>
            ) : (
              <MenuItem
                type="checkbox"
                tabIndex={0}
                checked={mmsRecordIds.includes(m.recordId)}
                key={m.recordId}
                // disabled={disabledMinor} not used so that users using assistive tech can still tab to it
                aria-disabled={disabledMinor}
                className={disabledMinor ? "menu-list-item-disabled" : undefined}
                onClick={disabledMinor ? undefined : doChangeMSSEnrollment([m.recordId])}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={mmsRecordIds.includes(m.recordId)}
                    tabIndex={-1}
                    disableRipple
                    className={disabledMinor ? "list-checkbox-disabled" : undefined}
                    disabled={disabledMinor}
                    inputProps={{ "aria-label": `${disabledMinor ? "Disabled" : ""} Checkbox ${m.name}` }}
                  />
                </ListItemIcon>
                <ListItemText primary={m.name.replace(new RegExp(/minor/gi), "")} />
              </MenuItem>
            );
          })}
        </SubMenu>
      )}
    </>
  );
};
