import { IComponent, ISecondaryCourseOption } from "../store/types";

export enum EligibilityValidateType {
  Major = "major",
  Minor = "minor",
  Diploma = "Diploma",
}

export function isValidNoDupComponentRule(component1: IComponent, component2: IComponent): boolean {
  if (
    component1.name &&
    component2.name &&
    component1.name.toLocaleLowerCase().includes(EligibilityValidateType.Minor)
  ) {
    const trimmedName = component1.name.toLocaleLowerCase().replace(EligibilityValidateType.Minor, "").trim();
    if (trimmedName === component2.name.toLocaleLowerCase()) {
      return false;
    }
  }

  if (
    component1.name &&
    component2.name &&
    component2.name.toLocaleLowerCase().includes(EligibilityValidateType.Minor)
  ) {
    if (
      component2.name.toLocaleLowerCase().replace(EligibilityValidateType.Minor, "").trim() ===
      component1.name.toLocaleLowerCase().trim()
    ) {
      return false;
    }
  }

  return component1.name.toLocaleLowerCase().trim() !== component2.name.toLocaleLowerCase().trim();
}

export function isDupMMSAgainstSecondaryCourseRule(selectedDiplomas: ISecondaryCourseOption[], mms: IComponent) {
  let isDup = false;
  if (!!selectedDiplomas) {
    selectedDiplomas.forEach((diploma: any) => {
      const diplomaSpecialization = diploma.selectedSecondCourseSpecialisations?.find((c: any) => {
        if (mms.name?.includes("minor")) {
          mms.name = mms.name.replace(EligibilityValidateType.Minor, "").trim();
        }
        if (c.name === mms.name) {
          return c;
        }
      });
      if (!!diplomaSpecialization) {
        isDup = true;
      }
    });
  }

  return isDup;
}

export function isDupSecondaryCourseAgainstMMSRule(
  selectedMajors: IComponent[],
  selectedMinors: IComponent[],
  iComponent: IComponent,
) {
  return (
    !!selectedMajors?.find((major: any) => major.name === iComponent.name) ||
    !!selectedMinors?.find((minor: any) => {
      if (minor.name?.includes("minor")) {
        minor.name = minor.name.replace(EligibilityValidateType.Minor, "").trim();
      }
      if (minor.name === iComponent.name) {
        return minor;
      }
    })
  );
}

export function isValidQualificationLevelCombinationRule(
  compEligibilites: Array<any>,
  course: any,
  selectedItems: Array<any>,
  itemToValidate: any,
  validationType: EligibilityValidateType,
) {
  if (validationType === EligibilityValidateType.Diploma) {
    const eligibilities = (compEligibilites ?? []).filter((c: any) => {
      if (c.displayKeyComp && c.displayKeyComp.course && c.displayKeyComp.mms) {
        return (
          c.displayKeyComp.course.code === course.code &&
          !!(selectedItems ?? []).find((s: any) => s.code === c.displayKeyComp.mms.code)
        );
      }

      if (c.displayKeyComp && !c.displayKeyComp.course && !!c.displayKeyComp.mms) {
        return !!(selectedItems ?? []).find((s: any) => s.code === c.displayKeyComp.mms.code);
      }

      if (c.displayKeyComp && !!c.displayKeyComp.course && !c.displayKeyComp.mms) {
        return c.displayKeyComp.course.code === course.code;
      }
    });
    let disAllowedComps: any = [];
    (eligibilities ?? []).forEach((e) => (disAllowedComps = disAllowedComps.concat(e.disAllowedComp)));
    const disAllowed = (disAllowedComps ?? []).find((c: any) => c.course?.code === itemToValidate.code);
    return !disAllowed;
  }

  if (validationType === EligibilityValidateType.Major) {
    const eligibility = (compEligibilites ?? [])?.find(
      (c: any) => !!(selectedItems ?? []).find((d: any) => d.code === c.displayKeyComp?.course?.code),
    );
    const disAllowedComps = eligibility?.disAllowedComp;
    const disAllowed = (disAllowedComps ?? []).find((c: any) => {
      if (!!c.course && !!c.mms) {
        return c.course.code === course.code && c.mms.code === itemToValidate.code;
      }
      return c.mms?.code === itemToValidate.code;
    });
    return !disAllowed;
  }
}
