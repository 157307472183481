import React, {ChangeEvent, useCallback} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { Checkbox, FormControl } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SelectLabel } from "./common";

const LISTBOX_PADDING = 8; // px

interface IAutocompleteMultiSelectProps {
  id: string;
  options: any[];
  label: string;
  formatLabel: (l: string) => string;
  onChange: (newVals: string[]) => any;
  formValue: any;
  placeholder: string;
}

const renderVirtualRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const useResetCache = (data: any) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  // const theme = useTheme();
  // const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  // const itemSize = smUp ? 36 : 48;
  const itemSize = 52;

  const OuterElementType = React.useMemo(
    () =>
      React.forwardRef<HTMLDivElement>((props, ref) => {
        return <div ref={ref} {...props} {...other} />;
      }),
    [other],
  );

  const getHeight = () => 5 + Math.min(8, itemData.length) * itemSize;
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <VariableSizeList
        itemData={itemData}
        height={getHeight() + 2 * LISTBOX_PADDING}
        width="100%"
        ref={gridRef}
        outerElementType={OuterElementType}
        innerElementType="ul"
        itemSize={() => itemSize}
        overscanCount={5}
        itemCount={itemCount}
      >
        {renderVirtualRow}
      </VariableSizeList>
    </div>
  );
}) as any;

const useStyles = () => ({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: "2px 0px",
      margin: 0,
    },
  },
  input: {
    "& input::placeholder": {
      opacity: "1 !important",
      fontStyle: "italic",
    },
  },
});

// Based on https://material-ui.com/components/autocomplete/#virtualization
export const AutocompleteMultiSelect = (props: IAutocompleteMultiSelectProps) => {
  const styles = useStyles();
  const { id, options, formatLabel, formValue, onChange, placeholder, label } = props;

  const doChange = useCallback(
    (_e: ChangeEvent<any>, newVal: string[]) => {
      onChange(newVal);
    },
    [onChange],
  );

  const virtualise = options.length > 25;

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <SelectLabel htmlFor={id}>{label}</SelectLabel>
      <Autocomplete
        multiple
        id={id}
        options={options}
        limitTags={2}
        value={formValue}
        disableCloseOnSelect
        onChange={doChange}
        getOptionLabel={(option) => formatLabel(option)}
        disableListWrap={virtualise ? true : undefined}
        ListboxComponent={virtualise ? ListboxComponent : undefined}
        ListboxProps={{ sx: styles.listbox } as any}
        renderOption={(props: any, option: any, { selected }) => (
          <li {...props} key={props.key}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {formatLabel(option)}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params: any) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} sx={styles.input} />
        )}
      />
    </FormControl>
  );
};
