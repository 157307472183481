import { fulfilled, failed } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import {
  ADD_ELIGIBILITY,
  ADMIN_AUTH,
  CLONE_TEMPLATE_BY_YEAR,
  GET_ALL_ELIGIBILITY,
  GET_TEMPLATE_DEFINITIONS,
  REMOVE_ELIGIBILITY,
  REMOVE_TEMPLATE,
  UPDATE_ELIGIBILITY,
  RENAME_PLAN,
  GET_ALL_DISCONTINUED_MMS,
  REMOVE_DISCONTINUED_MMS,
  UPDATE_DISCONTINUED_MMS,
  ADD_DISCONTINUED_MMS,
} from "actions/admin";
import { CHANGE_TEMPLATE_STATE, EDIT_NOTES } from "actions/enrollment";

const admin = (state = initialState.admin, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case fulfilled(ADMIN_AUTH):
      return {
        ...payload,
        templateSummaries: payload.templateSummaries ?? state?.templateSummaries ?? [],
        compEligibilities: payload.compEligibilities ?? state?.compEligibilities ?? [],
        discontinuedRecords: payload.discontinuedRecords ?? state?.discontinuedRecords ?? [],
      };
    case fulfilled(GET_TEMPLATE_DEFINITIONS):
      return { ...state, ...payload };
    case fulfilled(REMOVE_TEMPLATE):
      return {
        ...state,
        templateSummaries: state?.templateSummaries.filter((t) => t.id !== payload.id),
      };
    case fulfilled(CHANGE_TEMPLATE_STATE):
    case fulfilled(RENAME_PLAN):
    case fulfilled(EDIT_NOTES):
      return (
        state && {
          ...state,
          templateSummaries: state.templateSummaries.map((t) => (t.id === payload.id ? payload : t)),
        }
      );
    case fulfilled(CLONE_TEMPLATE_BY_YEAR):
      return (
        state && {
          ...state,
          templateSummaries: [...state.templateSummaries, payload],
        }
      );
    case fulfilled(GET_ALL_ELIGIBILITY):
      return { ...state, ...payload };
    case fulfilled(REMOVE_ELIGIBILITY):
      return (
        state && {
          ...state,
          compEligibilities: state.compEligibilities.filter((t) => t.recordKey !== payload),
        }
      );
    case fulfilled(UPDATE_ELIGIBILITY):
      return (
        state && {
          ...state,
          compEligibilities: state.compEligibilities.map((t) => (t.recordKey === payload.recordKey ? payload : t)),
        }
      );

    case fulfilled(ADD_ELIGIBILITY):
      return (
        state && {
          ...state,
          compEligibilities: [...state.compEligibilities, payload],
        }
      );
    // Discontinued MMS
    case fulfilled(GET_ALL_DISCONTINUED_MMS):
      return { ...state, ...payload };
    case fulfilled(REMOVE_DISCONTINUED_MMS):
      return (
        state && {
          ...state,
          discontinuedRecords: state.discontinuedRecords.filter((t) => t.recordKey !== payload),
        }
      );
    case fulfilled(UPDATE_DISCONTINUED_MMS):
      return (
        state && {
          ...state,
          discontinuedRecords: state.discontinuedRecords.map((t) => (t.recordKey === payload.recordKey ? payload : t)),
        }
      );

    case fulfilled(ADD_DISCONTINUED_MMS):
      return (
        state && {
          ...state,
          discontinuedRecords: [...state.discontinuedRecords, payload],
        }
      );

    case failed(ADMIN_AUTH):
      return null;
    default:
      return state;
  }
};

export default admin;
