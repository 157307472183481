/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo } from "react";
import { Field, useFormikContext } from "formik";
import {
  ComponentType,
  IComponent,
  ICourseSummary,
  IPlanCreationConfig,
  QualificationLevel,
  RoleType,
  StudyLevel,
  YearIntake,
} from "store/types";
import { Select, Switch, TextField } from "formik-mui";
import { Button, FormControl, FormHelperText, Grid, MenuItem, TextField as MUITextField } from "@mui/material";
import styled from "styled-components";

import { KeyboardArrowRight } from "@mui/icons-material";
import { capitalize, flatten, identity, isEmpty, orderBy, uniq } from "lodash";
import { ICreatePlanPayload } from "actions/enrollment";
import { Autocomplete } from "@mui/material";
import { FormActionWrapper, FormHeading, PaddedSelectLabel } from "../components/forms";
import { CheckboxControlLabel } from "./search/common";
import { featureToggles } from "config/featureToggles";
import { getAllowedMMS } from "../actions/planCreationConfig";
import { useDispatch } from "react-redux";

const StyledFormHelperText = styled(FormHelperText)`
  display: flex;
  i {
    font-size: 1rem;
    margin-right: 5px;
    padding-top: 1px;
  }
`;

const SelectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};

interface IProps {
  config: IPlanCreationConfig;
  mode: "plan" | "template";
  role?: string;
  authorisedCourseCodes?: Array<string>;
}

const getYearInteakes = (selectedCourse: ICourseSummary | undefined | null, year: string | number) =>
  orderBy(selectedCourse?.yearIntakes ? selectedCourse?.yearIntakes[String(year)] ?? [] : [], identity);

export const CreatePlanForm = (props: IProps) => {
  const dispatch = useDispatch();

  const { values, errors, handleSubmit, isSubmitting, setFieldValue } = useFormikContext<ICreatePlanPayload>(); // formikProps

  const mode = props.mode;
  const { courses, componentsForSelectedCourse } = props.config;
  const role = props.role;
  const authorisedCourseCodes = props.authorisedCourseCodes;

  const allAllowedCourses = useMemo(
    () =>
      courses.filter((c) => {
        return !featureToggles.removeCourse || !["MC-ADOLHW", "B-SCIEXT"].includes(c.code);
      }),
    [courses],
  );

  const distinctCourseLevels = useMemo(
    () =>
      orderBy(uniq(flatten(allAllowedCourses.map((c) => c.qualificationType.map((qt) => qt.studyLevel)))), (e) =>
        e === StudyLevel.UNDERGRADUATE ? 0 : 100,
      ),
    [allAllowedCourses],
  );

  let orderedCourses = useMemo(
    () =>
      orderBy(allAllowedCourses, "name").filter((c) =>
        c.qualificationType.find((qt) => qt.studyLevel === values.planCourseLevel),
      ),
    [allAllowedCourses, values.planCourseLevel],
  );

  const streams = orderBy(
    componentsForSelectedCourse?.filter((c: IComponent) => c.type === ComponentType.STREAM),
    (c) => c.name,
  );
  const entries = orderBy(
    componentsForSelectedCourse?.filter((c: IComponent) => c.type === ComponentType.ENTRY),
    (c) => c.name,
  );

  if (featureToggles.removeCourse) {
    const blockList = ["MC-ADOLHW", "B-SCIEXT"];
    orderedCourses = orderedCourses.filter((c) => !blockList.includes(c.code));
  }

  if (!featureToggles.allowStandAloneDiploma) {
    orderedCourses = orderedCourses.filter((c) => c.qualificationType[0]?.aqfLevel !== QualificationLevel.DIPLOMA);
  }

  if (
    featureToggles.templateAuth &&
    role !== RoleType.FULL &&
    authorisedCourseCodes &&
    authorisedCourseCodes.length > 0
  ) {
    orderedCourses = orderedCourses.filter((o) => authorisedCourseCodes.includes(o.code));
  }

  const selectedCourse = useMemo(
    () => orderedCourses.find((c) => c.recordId === values.planCourse) ?? null,
    [orderedCourses, values.planCourse],
  );
  const selectedYearIntakes = getYearInteakes(selectedCourse, values.planYear);

  const onCourseSelected = useCallback(
    (_e: any, course: ICourseSummary | null) => {
      setFieldValue("planCourse", course?.recordId ?? null);
    },
    [setFieldValue],
  );
  const allowedStartYears = selectedCourse?.allowedStartYears ?? [];
  const migratedStartYears = selectedCourse?.migratedYears ?? [];
  const selectedYearIsNotMigrated = values.planYear && migratedStartYears.indexOf(parseInt(values.planYear)) < 0;

  const missingVals =
    !values.planCourse ||
    !values.planName ||
    !values.planYear ||
    (featureToggles.dynamicYearIntake && !values.yearIntake);
  const disableSubmit = isSubmitting || missingVals || !isEmpty(errors);

  useEffect(() => {
    if (distinctCourseLevels.length === 1) {
      setFieldValue("planCourseLevel", distinctCourseLevels[0]);
    }
  }, [distinctCourseLevels, setFieldValue]);

  useEffect(() => {
    setFieldValue("planCourse", "");
    setFieldValue("planYear", "");
    setFieldValue("yearIntake", "");
  }, [values.planCourseLevel, setFieldValue]);

  useEffect(() => {
    setFieldValue("planYear", "");
    setFieldValue("yearIntake", "");
  }, [values.planCourse, setFieldValue]);
  useEffect(() => {
    setFieldValue("yearIntake", "");
  }, [values.planYear, setFieldValue]);

  useEffect(() => {
    if (values.planCourse && values.planYear) {
      const intakes = getYearInteakes(selectedCourse, values.planYear);
      setFieldValue("yearIntake", intakes.length === 1 ? intakes[0] : "");
    } else {
      setFieldValue("yearIntake", "");
    }
  }, [values.planCourse, values.planYear, setFieldValue, selectedCourse]);

  useEffect(() => {
    if (featureToggles.streamAndEntry) {
      if (values.planCourse && values.planYear) {
        dispatch(getAllowedMMS(values.planCourse, Number(values.planYear)));
      } else {
        dispatch(getAllowedMMS("", 0));
        setFieldValue("streamRecordId", "");
        setFieldValue("entryRecordId", "");
      }
    }
  }, [dispatch, setFieldValue, values.planCourse, values.planYear]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormHeading>To start your {mode} select your course and the year your studies commence.</FormHeading>
        </Grid>

        {distinctCourseLevels.length > 1 && (
          <Grid item xs={12}>
            <PaddedSelectLabel
              aria-label="Select course level, required"
              required
              htmlFor="course-level-for-plan"
              id="course-level-for-plan-label"
            >
              Course level
            </PaddedSelectLabel>
            <FormControl variant="standard" fullWidth={true} required>
              <Field
                component={Select}
                name="planCourseLevel"
                fullWidth={true}
                variant="outlined"
                MenuProps={SelectMenuProps}
                inputProps={{ id: "course-level-for-plan", required: true }}
                SelectDisplayProps={{
                  "aria-labelledby": "course-level-for-plan-label",
                  required: true,
                }}
                required={true}
              >
                {distinctCourseLevels.map((l) => {
                  return (
                    <MenuItem key={l} value={l}>
                      {capitalize(l)}
                    </MenuItem>
                  );
                })}
              </Field>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <PaddedSelectLabel
            aria-label="Select course, required"
            required
            htmlFor="course-for-plan-select"
            id="course-for-plan-select-label"
          >
            Select course
          </PaddedSelectLabel>
          <FormControl variant="standard" fullWidth={true} required>
            <Autocomplete
              id="course-for-plan-select"
              options={orderedCourses}
              getOptionLabel={(option: ICourseSummary) => `${option.name} (${option.code})`}
              disabled={!values.planCourseLevel}
              style={{ width: "100%" }}
              value={selectedCourse}
              onChange={onCourseSelected}
              renderInput={(params: any) => <MUITextField {...params} name="planCourse" variant="outlined" />}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <PaddedSelectLabel
            aria-label="Year your studies commence, required"
            required
            htmlFor="course-for-plan-year"
            id="course-for-plan-year-label"
          >
            Year your studies commence
          </PaddedSelectLabel>
          <FormControl variant="standard" fullWidth={true} required>
            <Field
              component={Select}
              name="planYear"
              fullWidth={true}
              variant="outlined"
              disabled={allowedStartYears.length === 0}
              MenuProps={SelectMenuProps}
              inputProps={{ id: "course-for-plan-year", required: true }}
              SelectDisplayProps={{
                "aria-labelledby": "course-for-plan-year-label",
                "aria-describedby": "course-for-plan-year-description",
                required: true,
              }}
              required={true}
            >
              {allowedStartYears.map((y) => {
                const yearIntakes = selectedCourse?.yearIntakes ? selectedCourse.yearIntakes[String(y)] : [];
                const anyIntakeForYear = yearIntakes.length > 0;
                return (
                  <MenuItem key={String(y)} value={String(y)} disabled={!anyIntakeForYear}>
                    {y}
                    {migratedStartYears.indexOf(y) < 0 && <>&#42;</>}
                    {anyIntakeForYear ? null : " (No intakes)"}
                  </MenuItem>
                );
              })}
            </Field>
            <StyledFormHelperText id="course-for-plan-year-description">
              <i className="material-icons">error_outline</i>
              Prospective students who wish to plan for future year commencement, please select the year closest to your
              planned year of commencement.
            </StyledFormHelperText>
            {selectedYearIsNotMigrated && (
              <StyledFormHelperText id="course-for-plan-year-description">
                <i className="material-icons" style={{ width: "24px", textAlign: "center" }}>
                  &#42;
                </i>
                <span>
                  <strong>Note:</strong> course rules from the year selected may differ slightly from what My Course
                  Planner shows. Please refer to the Handbook entry for the year you commenced to confirm your course
                  rules.
                </span>
              </StyledFormHelperText>
            )}
          </FormControl>
        </Grid>

        {featureToggles.streamAndEntry && streams && streams.length > 0 && (
          <Grid item xs={12}>
            <PaddedSelectLabel aria-label="Streams" required htmlFor="streams" id="streams-label">
              Stream
            </PaddedSelectLabel>
            <FormControl variant="standard" fullWidth={true} required>
              <Field
                component={Select}
                name="streamRecordId"
                fullWidth={true}
                variant="outlined"
                MenuProps={SelectMenuProps}
                inputProps={{ id: "streams-id", required: true }}
                SelectDisplayProps={{
                  "aria-labelledby": "streams-label",
                  "aria-describedby": "streams-description",
                  required: true,
                }}
                required={true}
                value={values.streamRecordId ?? ""}
              >
                {streams.map((s) => {
                  return (
                    <MenuItem key={s.id} value={s.recordId}>
                      {s.name}
                    </MenuItem>
                  );
                })}
              </Field>
              <StyledFormHelperText id="year-intake-description">
                <i className="material-icons">error_outline</i>
                <span>Please refer to your offer letter</span>
              </StyledFormHelperText>
            </FormControl>
          </Grid>
        )}

        {featureToggles.streamAndEntry && entries && entries.length > 0 && (
          <Grid item xs={12}>
            <PaddedSelectLabel aria-label="Course Entry Point" required htmlFor="entry" id="course-entry-point-label">
              Course Entry Point
            </PaddedSelectLabel>
            <FormControl variant="standard" fullWidth={true} required>
              <Field
                component={Select}
                name="entryRecordId"
                fullWidth={true}
                variant="outlined"
                MenuProps={SelectMenuProps}
                inputProps={{ id: "course-entry-point-id", required: true }}
                SelectDisplayProps={{
                  "aria-labelledby": "course-entry-point-label",
                  "aria-describedby": "course-entry-point-description",
                  required: true,
                }}
                value={values.entryRecordId ?? ""}
                required={true}
              >
                {entries.map((e) => {
                  return (
                    <MenuItem key={e.id} value={e.recordId ?? ""}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Field>
              <StyledFormHelperText id="year-intake-description">
                <i className="material-icons">error_outline</i>
                <span>Please refer to your offer letter</span>
              </StyledFormHelperText>
            </FormControl>
          </Grid>
        )}

        {featureToggles.dynamicYearIntake && (
          <Grid item xs={12}>
            <PaddedSelectLabel aria-label="Year intake" required htmlFor="year-intake" id="year-intake-label">
              Year intake
            </PaddedSelectLabel>
            <FormControl variant="standard" fullWidth={true} required>
              <Field
                component={Select}
                name="yearIntake"
                fullWidth={true}
                variant="outlined"
                disabled={!values.planCourse}
                MenuProps={SelectMenuProps}
                inputProps={{ id: "year-intake", required: true }}
                SelectDisplayProps={{
                  "aria-labelledby": "year-intake-label",
                  "aria-describedby": "year-intake-description",
                  required: true,
                }}
                required={true}
              >
                {Object.values(YearIntake).map((intake) => {
                  if (selectedYearIntakes.includes(intake)) {
                    return (
                      <MenuItem key={intake} value={intake}>
                        {intake}
                      </MenuItem>
                    );
                  }
                })}
              </Field>
              <StyledFormHelperText id="year-intake-description">
                <i className="material-icons">error_outline</i>
                <span>
                  Please check the course intake options by looking up this course on &nbsp;
                  <a href="https://study.unimelb.edu.au/" target="_blank" rel="noreferrer">
                    Study at University of Melbourne
                  </a>
                  .
                </span>
              </StyledFormHelperText>
            </FormControl>
          </Grid>
        )}

        {!featureToggles.dynamicYearIntake && (
          <Grid item xs={12}>
            <CheckboxControlLabel
              labelPlacement="start"
              control={<Field component={Switch} type="checkbox" name="isMidyearEntry" color="primary" />}
              label="Are you planning for mid-year entry?"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControl fullWidth={true} variant="outlined" required>
            <Field
              component={TextField}
              name="planName"
              label={`Name your ${mode}`}
              fullWidth={true}
              variant="outlined"
              required={true}
              inputProps={{
                // Changing based on accessibility feedback CPT-535
                "aria-label": `Name your ${mode}`,
                required: true,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormActionWrapper>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<KeyboardArrowRight sx={{ fill: (theme) => theme.palette.primary.contrastText }} />}
              disabled={disableSubmit}
            >
              Create course {mode}
            </Button>
          </FormActionWrapper>
        </Grid>
      </Grid>
    </form>
  );
};
