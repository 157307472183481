import React, { useCallback, useRef } from "react";
import { makeDnDId } from "containers/slotCard/dnd";
import { getAvailableTerms } from "lib/subjects";
import { BreadthDisciplineScope, ISubject } from "store/types";
import { useMenuState } from "@szhsin/react-menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { Aligned } from "components/aligned/alignedDiv";
import { SubjectMenu } from "containers/slotCard/menuItems/subjectMenu";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { usePlanPermissions } from "lib/usePlanPermissions";

interface ISubjectChipProps {
  isAssignable: boolean;
  inPlan: boolean;
  subject: ISubject;
  mmsRecordId?: string;
  dndIdToken: string;
  btnId: string;
  assignAs?: BreadthDisciplineScope;
}

const commonChipStyles = {
  borderRadius: "10px",
  marginTop: "1px",
  marginBottom: "1px",
  paddingLeft: "6px",
  paddingRight: "0px",
  "& .MuiChip-label": {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
};

const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation();

const useStyles = () => ({
  chipLabel: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  activeSubjectChip: { ...commonChipStyles, cursor: "grab", paddingRight: "0px !important" },
  inactiveSubjectChip: { ...commonChipStyles, cursor: "default", paddingRight: "6px" },
  menuIcon: {
    fontSize: "1rem",
  },
  menuButton: {
    marginLeft: "0px",
  },
  tooltip: {
    whiteSpace: "pre-line",
  },
});

export const SubjectChip = ({
  subject,
  dndIdToken,
  mmsRecordId,
  inPlan,
  isAssignable,
  btnId,
  assignAs,
}: ISubjectChipProps) => {
  const styles = useStyles();
  const availabilities = getAvailableTerms(subject);
  const tooltipText = `${subject.name}, L${subject.level}, ${subject.points}pts\n\n${
    availabilities.length === 0 ? "" : "Available: " + availabilities.join(", ")
  }${inPlan ? "\n\nAlready in plan" : ""}${!isAssignable ? "\n\nCan not be assigned to this course" : ""}
  `.trim();

  const dndId = makeDnDId({ subjectRecordId: subject.recordId, mmsRecordId, assignAs }, dndIdToken);

  const [menuProps, toggleMenu] = useMenuState();
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  const onOptionsClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      openMenu();
    },
    [openMenu],
  );
  const ref = useRef(null);
  const disabled = inPlan || !isAssignable;

  const result = (
    <Tooltip arrow aria-label={tooltipText} title={tooltipText} sx={{ tooltip: styles.tooltip }}>
      <span>
        <Chip
          onClick={stopPropagation}
          size="small"
          sx={disabled ? styles.inactiveSubjectChip : styles.activeSubjectChip}
          label={
            <Aligned style={styles.chipLabel}>
              {subject.code}
              {!disabled && (
                <IconButton
                  ref={ref}
                  aria-label={`Add subject ${subject.name} to plan`}
                  // aria-controls={btnId}
                  onClick={onOptionsClick}
                  size="small"
                  sx={styles.menuButton}
                >
                  <MoreVertIcon sx={styles.menuIcon} fontSize="small" />
                </IconButton>
              )}
            </Aligned>
          }
          disabled={disabled}
        />
        {!disabled && (
          <SubjectMenu
            {...menuProps}
            mmsRecordId={mmsRecordId}
            htmlId={btnId}
            anchorRef={ref}
            subjectRecordId={subject.recordId}
            close={closeMenu}
            assignAs={assignAs}
          />
        )}
      </span>
    </Tooltip>
  );

  const { readOnlyPlan } = usePlanPermissions();

  if (inPlan || !isAssignable) {
    return result;
  }

  return (
    <Droppable droppableId={dndId} isDropDisabled={true}>
      {(provided) => (
        <span ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable draggableId={dndId} index={1} isDragDisabled={readOnlyPlan}>
            {(provided) => (
              <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                {result}
              </span>
            )}
          </Draggable>
          {provided.placeholder}
        </span>
      )}
    </Droppable>
  );
};
