import initialState from "store/initialState";
import { OPEN_SUBJECT_FINDER_DRAWER } from "actions/subjectDrawerForRule";

const subjectDrawerForRule = (state = initialState.checklistDrawerForSubjectId, action: any) => {
  switch (action.type) {
    case OPEN_SUBJECT_FINDER_DRAWER:
      return action.payload;
    default:
      return state;
  }
};

export default subjectDrawerForRule;
