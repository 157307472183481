import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IState } from "store/types";
import { ResponsiveCenter } from "components/spinner/Center";
import { Button, Drawer } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { removeInvocationFocus } from "actions/focusInvocationId";
import { Aligned } from "components/aligned/alignedDiv";
import { InvocationIcon } from "./checklist/InvocationIcon";
import { ChecklistInvocationText } from "./checklist/InvocationsText";
import { getChecklistInvocations } from "lib/invocations";

const useStyles = () => ({
  drawerPaper: {
    paddingTop: "1rem",
    borderTop: "2px solid black",
    background: "lightgray",
    width: "100%",
    zIndex: 2000,
  },
});

export const FocusedInvocationBanner = () => {
  const focusInvocationId = useSelector((state: IState) => state.focusInvocationId);
  const invocations = useSelector((s: IState) => s.enrollment?.validation.invocations || []);
  const focusInvocation = invocations.find((i) => i.ruleId === focusInvocationId);

  const allGroups = useSelector((s: IState) => s.enrollment?.validation.groups || []);
  const checklistInvocations = useSelector((s: IState) => getChecklistInvocations(s.enrollment));

  const styles = useStyles();

  // Get the course on load
  const dispatch = useDispatch();

  const cancel = useCallback(() => {
    dispatch(removeInvocationFocus());
  }, [dispatch]);

  if (!focusInvocation || !focusInvocationId) {
    return null;
  }

  return (
    <>
      <Drawer
        anchor={"bottom"}
        variant="persistent"
        open={!!focusInvocationId}
        PaperProps={{
          sx: styles.drawerPaper,
        }}
      >
        <ResponsiveCenter>
          <Aligned style={{ justifyContent: "space-between", alignItems: "start" }}>
            <div>
              <strong>Highlighting subjects matching the following rule:</strong>
              <Aligned style={{ alignItems: "start", marginBottom: "1rem", marginTop: "1rem" }}>
                <InvocationIcon
                  style={{ marginTop: "0.1rem", marginRight: "0.5rem" }}
                  invocation={focusInvocation}
                  allGroups={allGroups}
                  checklistInvocations={checklistInvocations}
                />
                <ChecklistInvocationText invocation={focusInvocation} extraDndToken="subject-drawer" />
              </Aligned>
            </div>
            &nbsp;
            <Button
              onClick={cancel}
              variant="contained"
              startIcon={<CancelIcon />}
              sx={{
                background: "#e0e0e0",
                color: "black",
                minWidth: "160px",
                "&:hover": {
                  background: "#e0e0e0",
                },
              }}
            >
              Cancel&nbsp;highlight
            </Button>
          </Aligned>
        </ResponsiveCenter>
      </Drawer>
    </>
  );
};
