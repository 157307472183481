import { ISubject, ISlotIdentifier, SubjectTag, SubjectFocusState } from "store/types";
import { Theme, alpha } from "@mui/material";
import styled from "styled-components";
import { validationRed, brandMedGrey1 } from "theme/colors";

export interface IProps extends ISlotIdentifier {
  subject: ISubject;
}

const disabledForeground = "#d3d3d3";
const disabledBackground = "#f2f2f2";

const tagColors: Record<SubjectTag, string> = {
  [SubjectTag.NOT_ASSIGNABLE]: "#cccccc",
  [SubjectTag.CORE]: "#FFBDCC",
  [SubjectTag.BREADTH]: "#7CBDCE",
  [SubjectTag.ASSIGNED]: "#B0BCE1",
  [SubjectTag.ASSIGNED_2]: "#b1e2d5",
  [SubjectTag.SECONDARY_COURSE]: "#99ccff",
  [SubjectTag.DISCIPLINE]: "#FFDCA2",
  [SubjectTag.HIDDEN_MMS]: "#FFDCA2",
  [SubjectTag.CAN_BE_DISCIPLINE]: "#FFDCA2", // For "special" subjects that are selected as Discipline when they can be Breadth or Discipline
  [SubjectTag.DISCOVERY_RESULT]: "#9855d4",
  [SubjectTag.ON_PLAN]: "#b3b3b3",
};

const headerBackgroundColor = (props: any, theme: Theme) => {
  const defaultColor = theme.palette.primary.main;
  if (props?.disabled) {
    return disabledForeground;
  }
  if (props?.tag) {
    return tagColors[props.tag as SubjectTag] || defaultColor;
  }
  return defaultColor;
};

const chipBackgroundColor = (props: any, theme: Theme) => {
  if ((props as any).subjectFocusState === SubjectFocusState.TARGET) {
    return "#d9d9d9";
  }
  const defaultColor = theme.palette.secondary.light;
  if (props?.disabled) {
    return disabledForeground;
  }
  if (props?.tag) {
    return alpha(tagColors[props.tag as SubjectTag] || defaultColor, 0.3);
  }
  return alpha(defaultColor, 0.3);
};

const headerContentColor = (props: any, theme: Theme) => {
  const defaultColor = theme.palette.primary.contrastText;
  if (props?.tag && props.tag !== SubjectTag.DISCOVERY_RESULT) {
    return "black";
  }
  return defaultColor;
};

export const cardStyles = (theme: Theme, props: any) => ({
  card: {
    borderRadius: "5px",
    // paddingTop: "0.15rem",
    border: `1px solid ${headerBackgroundColor(props, theme)}`,
    background: `${
      (props as any)?.disabled
        ? disabledBackground
        : (props as any).subjectFocusState === SubjectFocusState.TARGET
        ? headerBackgroundColor(props, theme)
        : "white"
    }`,
    padding: "0px",
    marginTop: "10px",
    height: "100%",
    overflow: "visible",
  },
  cardHeader: {
    background: headerBackgroundColor(props, theme),
    color: headerContentColor(props, theme),
    paddingTop: "0.15rem",
    height: "40px",
    paddingBottom: "0.15rem",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    "& .MuiCardHeader-title": {
      fontSize: "0.75rem",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  cardChip: {
    background: chipBackgroundColor(props, theme),
  },
  cardHeaderIcon: {
    fill: headerContentColor(props, theme),
  },
  cardContent: {
    paddingTop: "0.15rem",
    paddingBottom: "0.5rem !important",
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
    height: "100%",
  },
  icon: {
    fill: headerContentColor(props, theme),
  },
  errorIcon: {
    fill: validationRed.toString(),
  },
});

export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`;

export const CardTitle = styled.h4`
  margin-top: 0.25rem;
  // font-size: 0.95rem;
`;

export const CardSubheading = styled.p`
  margin-top: 0.2rem;
  margin-bottom: 0.75rem;
  font-size: 0.8rem;
  color: ${brandMedGrey1.toString()};
`;
