// import React from "react";
import { HintToolTip } from "store/types";

export interface IHintDefinition {
  heading: any;
  text: any;
}

// Emojis from https://emojipedia.org/
export const hintDefinitions: Record<HintToolTip, IHintDefinition> = {
  [HintToolTip.ADD_MMS]: {
    heading: "Add a Major / Minor / Specialisation",
    text:
      "You can select a Major / Minor / Specialisation from this menu. This will populate the core subjects and update the Plan Checklist on the right.",
  },
  [HintToolTip.B_DES_MMS_WARNING]: {
    heading: "⚠️ Double Major/Minor",
    text: `My Course Planner can only be used to plan for a single major in the Bachelor of Design. For assistance planning a double major, minor, or specialisation, please visit the  <a href="https://edsc.unimelb.edu.au/course-planning/sample-course-plans" target="_">Faculty Course Planning website</a>`,
  },
  [HintToolTip.ADD_SUBJECT]: {
    heading: "Add a Subject",
    text: "Use the magnifying glass to search for subjects and add them to your plan.",
  },
  [HintToolTip.RULE_PROGRESS]: {
    heading: "Plan Checklist",
    text:
      "You can track your study plan requirements under the “Plan Checklist”. Select a section to expand the details, and click the magnifying glass to search and add relevant subjects. You can also hover your cursor over a circle to view your progression within the corresponding rule.",
  },
  [HintToolTip.ADD_NON_STANDARD_SUBJECT]: {
    heading: "Add a non-standard semester subject",
    text:
      "Want to add an intensive or non-standard subject (e.g., January, February, June, July)? Click the three dots next to the relevant year to add a Summer or Winter term study period.",
  },
  [HintToolTip.ADD_CREDIT_ADVANCED_STANDING]: {
    heading: "Adding Credit for Prior Study (Advanced Standing)",
    text: `If you've been granted credit for prior learning, you can use this menu to "Add advanced standing" and search for equivalent subjects.`,
  },
  [HintToolTip.MMS_RULES]: {
    heading: "Major / Minor / Specialisation rules",
    text:
      "Now that you have added a Major / Minor / Specialisation you can check the rules here to see what subjects you need to add to meet the specific requirements. Subject codes can be dragged into your plan from the plan checklist.",
  },
  [HintToolTip.SUBJECT_FINDER]: {
    heading: "Subject finder",
    text:
      "Pre-set filters have been set up to help narrow your subject search. You can start searching by subject name and code or use more filters to narrow down your search. ",
  },
  [HintToolTip.SUBJECT_DETAILS]: {
    heading: "Subject details",
    text: `<p>Now that you have added a subject to the plan, you can open the subject menu by clicking the three dots.</p><p>"Details" can be checked to ensure you are meeting prerequisite requirements, whilst other options allow you to change, remove, and move the subject.</p>`,
  },
  [HintToolTip.SUBJECT_PREREQ]: {
    heading: "Subject Prerequisites",
    text:
      "The red cross indicates that prerequisites still need to be satisfied and/or the subject is sitting in the wrong study period. Click the red cross for more information.",
  },
  [HintToolTip.COMPLETION]: {
    heading: "🎉 Congratulations, you have a complete plan!",
    text:
      "⚠️ Remember to review any remaining warnings. You can use the My Course Plan menu to save and share your unique link, or print your plan to pdf.",
  },
  [HintToolTip.SAMPLE_COURSE_PLANS]: {
    heading: "How to access sample course plans",
    text: `<p>Want to use a template to help you plan? Choose from our sample study plans here.</p><p>These plans include major subjects, any prerequisite subjects, and subjects recommended by the Faculty.</p>`,
  },
};
