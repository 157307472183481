export const OPEN_SUBJECT_CHECKLIST = "checklistDrawerForSubjectId/OPEN_SUBJECT_CHECKLIST";

export const openSubjectChecklist = (subjectRecordId: string) => {
  return {
    type: OPEN_SUBJECT_CHECKLIST,
    payload: subjectRecordId,
  };
};

export const closeSubjectChecklist = () => {
  return {
    type: OPEN_SUBJECT_CHECKLIST,
    payload: null,
  };
};
