import { createTheme, ThemeOptions } from "@mui/material/styles";
import { brandBlue, brandDarkBlue, brandGrey, validationRed, brandLightGrey } from "./colors";

const baseThemeDefinition: ThemeOptions = {
  components: {},
  mixins: {},
  palette: {
    text: {
      // hint: "rgba(0, 0, 0, 0.38)",
    },
    mode: "light",
    // type: "light",
    primary: {
      main: brandBlue.toString(),
      // light: brandRed.toString(),
      dark: brandDarkBlue.toString(),
    },
    secondary: {
      main: "#4a4a4a",
      light: brandLightGrey.toString(),
      // dark: brandBlack.toString(),
    },
    warning: {
      main: validationRed.toString(),
    },
  },
};

const baseTheme = createTheme(baseThemeDefinition);

export const defaultTheme = createTheme({
  ...baseThemeDefinition,
  typography: {
    fontFamily: "Source Sans Pro",
    allVariants: {
      fontFamily: "Source Sans Pro",
    },
    h2: {
      marginBottom: "12px !important",
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          width: "253px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          marginTop: 6,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: brandGrey.toString(),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& $notchedOutline": {
            borderColor: brandBlue.toString(),
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: baseTheme.palette.grey[600],
        },
        track: {
          border: `1px solid ${baseTheme.palette.grey[700]}`,
          backgroundColor: baseTheme.palette.common.white,
          opacity: 0.7,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          marginTop: "0px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Source Sans Pro, sans-serif",
        },
      },
    },

    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       marginTop: "14px !important",
    //       marginBottom: "14px !important",
    //     },
    //   },
    // },
  },
  // mixins: {},
});
