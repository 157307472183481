import { color } from "csx";

/** UOM colours */
export const brandBlue = color("#094183");
export const brandDarkBlue = color("#012A58");
export const brandLightBlue = color("#F2F5F9");

/** Secondary colours */
export const fabColour = color("#aa647b");

/** Greys */

export const brandGrey = color("#272727");
export const brandMedGrey1 = color("#575757");
export const brandMedGrey2 = color("#808184");
export const brandLightGrey = color("#e0e0e0");
export const brandBeige = color("#f6f5f3");
export const brandLightBeige = color("#fbfaf9");

/**Other colors */
export const lightBlue = color("#abccf1");
export const niceBlue = color("rgb(191, 224, 241)");
export const darkYellow = color("#cc9900");

/**Validation */

export const validationGreen = color("#28a745");
export const validationRed = color("#df0620");

/**Faded Brand Colors */

export const fadedBrandBlue1 = color("#35659e");
export const fadedBrandBlue2 = color("#4074b2");
//For the blue forms
// export const fadedTurquoise = color(`#ecf1f4`);
// export const darkFadedTurquoise = color("#dce4ea");
