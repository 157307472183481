import React from "react";
import styled from "styled-components";

const A = styled.a`
  position: fixed;
  right: 0;
  bottom: 60%;
  transform: rotate(270deg);
  transform-origin: right bottom;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0px;
  background: #428217;
  color: white;
  font-size: larger;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 12px 3px 12px;
  &:hover {
    padding-bottom: 8px;
  }
  &:focus {
    padding-bottom: 8px;
  }
`;

export const Feedback = React.memo(() => (
  <A target="_blank" className="do-not-print" href="http://melbourneuni.au1.qualtrics.com/jfe/form/SV_e9ZYhnNNkJYSl38">
    Feedback
  </A>
));
