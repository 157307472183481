import React, { useCallback } from "react";
import { ISubject, SubjectTag } from "store/types";
import { Card, CardHeader, CardContent, Chip, IconButton, useTheme } from "@mui/material";
import uniq from "lodash/uniq";
import isArray from "lodash/isArray";
import { cardStyles, CardSubheading, CardTitle, NameContainer } from "./cardStyles";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { DiscoveryDisclaimer } from "containers/search/common";

interface IProps {
  subject: ISubject;
  alreadyOnPlan: boolean;
  addToPlan: () => any;
  showDetails: () => any;
}

const preventDefault = (e: any) => {
  e.preventDefault();
};

export const SubjectSlotSearchCard = (props: IProps) => {
  const { subject, addToPlan, showDetails, alreadyOnPlan } = props;
  const theme = useTheme();
  const styles = alreadyOnPlan
    ? cardStyles(theme, { tag: SubjectTag.ON_PLAN })
    : subject.discoveryResult
    ? cardStyles(theme, { tag: SubjectTag.DISCOVERY_RESULT })
    : cardStyles(theme, {});
  const areas = subject.areaOfStudy.map((aos) => aos.description).join(",");
  const subjectAvailabilities = isArray(subject.availability) ? subject.availability : [subject.availability];
  const availabilities = uniq(subjectAvailabilities.map((a) => a?.studyPeriod)).filter(Boolean);

  const add = useCallback(
    (e: any) => {
      e.preventDefault();
      addToPlan();
    },
    [addToPlan],
  );

  return (
    <Card sx={styles.card} elevation={2} tabIndex={0} style={{ cursor: "pointer" }}>
      <CardHeader
        color="primary"
        title={`${areas} ${alreadyOnPlan ? "(Already in plan)" : ""}`.trim()}
        style={{ cursor: "auto" }}
        titleTypographyProps={{ variant: "inherit" }}
        sx={styles.cardHeader}
        avatar={
          subject.discoveryResult ? (
            <img src="/watson-white.png" alt="IBM Watson" style={{ width: 25, height: 25 }} />
          ) : null
        }
      />
      <CardContent sx={styles.cardContent} style={{ cursor: "initial" }} onClick={preventDefault}>
        <CardSubheading>
          <strong>{subject.code}</strong> | Level {subject.level} | {subject.points} points
        </CardSubheading>

        <NameContainer>
          <CardTitle style={{ maxWidth: "149px" }}>{subject.name}</CardTitle>
          <div>
            <IconButton
              aria-label={`Show details for subject ${subject.name}`}
              size="small"
              color="primary"
              onClick={showDetails}
            >
              <InfoIcon />
            </IconButton>
            {!alreadyOnPlan && (
              <IconButton aria-label={`Add subject ${subject.name} to plan`} size="small" color="primary" onClick={add}>
                <AddIcon />
              </IconButton>
            )}
          </div>
        </NameContainer>

        {availabilities.map((a) => (
          <React.Fragment key={a}>
            <Chip sx={styles.cardChip} label={a} size="small" /> &nbsp;
          </React.Fragment>
        ))}
        {subject.discoveryResult && <DiscoveryDisclaimer />}
      </CardContent>
    </Card>
  );
};
