import { FormControl, Theme, useTheme } from "@mui/material";
import { IState } from "../../store/types";
import { FieldArray, useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { orderBy } from "lodash";
import Divider from "@mui/material/Divider";
import { KeyGroup } from "./KeyGroup";
import { ValueGroup } from "./ValueGroup";
import Button from "@mui/material/Button";

export const useStyles = (theme: Theme) => ({
  fieldSetStyle: {
    padding: "24px",
    marginTop: "24px",
    borderColor: theme.palette.divider,
    borderWidth: "1px",
  },
});

export const AddEligibilityForm = () => {
  const { values, handleSubmit } = useFormikContext<any>(); // formikProps
  const theme = useTheme();
  const styles = useStyles(theme);

  const config: any = useSelector((state: IState) => state.planCreationConfig);
  const courses = config?.courses;
  const orderedCourses = useMemo(() => orderBy(courses, "name"), [courses]);
  const components = config?.allComponents;
  const orderedComponents = useMemo(() => orderBy(components, "name"), [components]);

  return (
    <form id="edit-eligibility-form" onSubmit={handleSubmit}>
      <KeyGroup courses={orderedCourses} components={orderedComponents} />
      <fieldset style={styles.fieldSetStyle}>
        <legend>DisAllowed Components</legend>
        <FormControl fullWidth variant="outlined" required>
          <FieldArray
            name="disAllowedComp"
            render={({ remove, push }) => (
              <div>
                {(values as any).disAllowedComp?.length > 0 &&
                  (values as any).disAllowedComp?.map((d: any, index: number) => (
                    <div className="row" key={index}>
                      <label htmlFor={`disAllowedComp.${index}.course`}>Group {index + 1}: </label>
                      <ValueGroup index={index} courses={orderedCourses} components={orderedComponents} />
                      <Button
                        type="button"
                        variant="contained"
                        style={{ marginTop: "12px", marginBottom: "12px" }}
                        autoFocus
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                      <Divider />
                    </div>
                  ))}
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  style={{ marginTop: "12px", marginBottom: "12px" }}
                  autoFocus
                  onClick={() => push("")}
                >
                  Add
                </Button>
              </div>
            )}
          />
        </FormControl>
      </fieldset>
    </form>
  );
};
