import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import { ComponentType, IComponent, IPlan } from "store/types";

interface IGroupedComponents {
  [key: string]: IComponent[];
}

export const getGroupedComponents = (components: IComponent[]) => groupBy(components, "type");

export const getMajorsAndMinors = (groupedComponents: IGroupedComponents) =>
  flatten(
    Object.entries(groupedComponents || [])
      .filter(([key, _value]) => key.match(/major/i) || key.match(/minor/i))
      .map(([_key, value]) => value),
  );

export const getSpecialisations = (groupedComponents: IGroupedComponents) =>
  flatten(
    Object.entries(groupedComponents || [])
      .filter(([key, _value]) => key.match(/specialisation/i))
      .map(([_key, value]) => value),
  );

export const getEntries = (groupedComponents: IGroupedComponents) =>
  flatten(
    Object.entries(groupedComponents || [])
      .filter(([key, _value]) => key.match(/entry/i))
      .map(([_key, value]) => value),
  );

export const getStreams = (groupedComponents: IGroupedComponents) =>
  flatten(
    Object.entries(groupedComponents || [])
      .filter(([key, _value]) => key.match(/stream/i))
      .map(([_key, value]) => value),
  );

export const isHiddenMMS = (c: IComponent | null | undefined) =>
  !!c && (!!c.type.match(/stream/i) || !!c.type.match(/entry/i));
export const isHiddenMMSById = (mmsRecordId?: string, allowedComponents?: IComponent[]) =>
  isHiddenMMS((allowedComponents ?? []).find((c) => c.recordId === mmsRecordId));
export const getPlanHiddenMMS = (plan?: IPlan, allowedComponents?: IComponent[]) => {
  const planMmsRecordIs = plan?.mmsRecordIds ?? [];
  const mmses = (allowedComponents ?? []).filter(
    (c) => planMmsRecordIs.indexOf(c.recordId) >= 0 && (c.type.match(/stream/i) || c.type.match(/entry/i)),
  );
  return mmses[0] ?? null;
};

export const getNestedMMS = (majorsAndMinors: IComponent[], specialisations: IComponent[]) => [
  ...majorsAndMinors.map((m: IComponent) => ({
    ...m,
    children: (specialisations || []).filter((s: IComponent) => s.parentRecordId === m.recordId),
  })),
  ...specialisations
    .filter((s) => !majorsAndMinors.find((m) => m.recordId === s.parentRecordId))
    .map((m: IComponent) => ({
      ...m,
      children: (specialisations || []).filter((s: IComponent) => s.parentRecordId === m.recordId),
    })),
];

const isSubjectAssignable = (
  mms: IComponent,
  subjectRecordId: string,
  mmsAssignableSubjects: Record<string, string[]>,
) => {
  if (!mmsAssignableSubjects || !mmsAssignableSubjects[mms.recordId]) {
    return false;
  }
  return mmsAssignableSubjects[mms.recordId].indexOf(subjectRecordId) >= 0;
};

export const getMMSWithSpecialisationsForSubject = (
  majorsAndMinors: IComponent[],
  specialisations: IComponent[],
  subjectRecordId: string,
  mmsAssignableSubjects: Record<string, string[]>,
) => {
  const assignableSpecialisations = specialisations.filter((s) =>
    isSubjectAssignable(s, subjectRecordId, mmsAssignableSubjects),
  );
  const assignableMMS = majorsAndMinors.filter(
    (mms) =>
      isSubjectAssignable(mms, subjectRecordId, mmsAssignableSubjects) ||
      assignableSpecialisations.find((s) => s.parentRecordId === mms.recordId),
  );
  return getNestedMMS(assignableMMS, assignableSpecialisations);
};

export const isCourseLevelInformalSpec = (s: IComponent) =>
  s.parentCourse.recordId === s.parentRecordId && s.type === ComponentType.INFORMAL_SPEC;
