import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { removeDiscontinuedMM } from "../../actions/admin";
import { useNavigate } from "@reach/router";

interface IProps {
  open: boolean;
  close: () => any;
  discontinuedRecord?: any;
}

export const DeleteDiscontinuedMMSDialog = ({ open, close, discontinuedRecord }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback(() => {
    close();
    if (discontinuedRecord) {
      const res = dispatch(removeDiscontinuedMM(discontinuedRecord)) as any;
      if (Promise.resolve(res) === res) {
        res.then(() => navigate(`/staff/discontinued-mms`));
      } else {
        navigate(`/staff/discontinued-mms`);
      }
    }
  }, [close, dispatch, discontinuedRecord, navigate]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="delete-plan-dialog-title"
      aria-describedby="delete-plan-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="delete-plan-dialog-title">Delete Discontinued MMS rule</DialogTitle>
      <DialogContent>
        <p>Are you sure to delete discontinued mms rule?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={onSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
