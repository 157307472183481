import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Typography, useTheme } from "@mui/material";
import { commonStyles } from "theme/styles";
import { brandLightBlue } from "theme/colors";
import omit from "lodash/omit";

interface ITableRow {
  id: string;
  [key: string]: any;
}

interface IProps {
  outlined?: boolean;
  tableRows: ITableRow[];
  tableHeader?: ITableRow;
}
const AppTable = ({ tableRows, tableHeader, outlined }: IProps) => {
  const theme = useTheme();
  const styles = commonStyles(theme);

  return (
    <Table size="small" sx={outlined ? styles.subjectTableOutlined : styles.subjectTable}>
      {tableHeader && (
        <TableHead>
          <TableRow>
            {Object.values(tableHeader).map((v: string, i) => (
              <TableCell key={`${v}-${i}`} sx={outlined ? styles.subjectTableCellOutlined : undefined}>
                <Typography variant="body2">{v}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {tableRows.map((row: ITableRow, i: number) => {
          const values: string[] = Object.values(omit(row, ["id"]));
          return (
            <TableRow
              key={row.id}
              sx={styles.subjectTableRow}
              style={{ background: i % 2 === 0 || outlined ? "white" : brandLightBlue.toString() }}
            >
              {values.map((v: any, i) => (
                <TableCell key={`${row.id}-value-${i}`} sx={outlined ? styles.subjectTableCellOutlined : undefined}>
                  {typeof v === "string" ? <Typography variant="body2">{v}</Typography> : v}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AppTable;
