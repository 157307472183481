import React from "react";
import styled from "styled-components";
import { InfoOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Aligned } from "components/aligned/alignedDiv";
const H3 = styled.h3`
  margin-top: 0px;
  margin-left: 0.5rem;
`;

const BannerContainer = styled.div`
  padding: 1rem;
  border: 1px solid lightgray;
  background: #e8e8e8;
  border-radius: 10px;
`;

interface IButtonProps {
  label: string;
  onClick: () => void;
  colour?: "inherit" | "primary" | "secondary";
}
interface IProps {
  heading: string;
  body?: React.ReactElement<any> | string;
  buttons?: IButtonProps[];
}
export const Banner = ({ heading, body, buttons }: IProps) => (
  <BannerContainer>
    <Aligned style={{ alignItems: "start" }}>
      <InfoOutlined />
      <H3>{heading}</H3>
    </Aligned>
    {body}
    {buttons &&
      buttons.length > 1 &&
      buttons.map((b: IButtonProps, i: number) => (
        <React.Fragment key={`${b.label}-${i}`}>
          <Button variant="contained" color={b.colour || "primary"} onClick={b.onClick}>
            {b.label}
          </Button>
          &nbsp; &nbsp;
        </React.Fragment>
      ))}
  </BannerContainer>
);
