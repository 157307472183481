import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { ComponentType, IExpansionState, IState } from "store/types";
import { SearchDrawerResultsHeaderContainer } from "../../theme/styles";
import { SearchResultsHeader } from "../search/SearchResultsHeader";
import { SubjectSearchResults } from "../search/common";
import { Aligned } from "../../components/aligned/alignedDiv";
import { templateName } from "../../lib/templates";
import { Collapse, IconButton, InputAdornment, TextField, Toolbar, Tooltip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetAppIcon from "@mui/icons-material/GetApp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import styled from "styled-components";
import { useDebounce } from "../../lib/useDebounce";
import { fromPairs, orderBy } from "lodash";
import { loadTemplate } from "../../actions/enrollment";
import { LoadTemplateDialog } from "../../components/templates/LoadTemplateDialog";
import { SearchOutlined } from "@mui/icons-material";
import Spinner from "../../components/spinner";

interface IProps {
  open: boolean;
  close: () => any;
  mmsRecordIds?: Array<string>;
}

const UL = styled.ul`
  margin-top: 0.5rem;
  margin-left: 0rem;
  padding-left: 1.25rem;
  & li {
    margin-bottom: 0.85rem;
  }
`;

const DialogTextContainer = styled.div`
  margin: 0 1.5rem 1rem 1.5rem;
`;
const ExpandCollapseButton = styled(IconButton)``;
export interface INewNameFormVals {
  name: string;
}

export const SampleCoursePlanDialog = (props: IProps) => {
  // State of the search box
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 150);
  const onSearchTextChange = useCallback((e: any) => setSearchText(e.target.value.trim().toLowerCase()), [
    setSearchText,
  ]);
  const disableActions = useSelector((state: IState) => state.loading || state.submittingChanges);

  //Expend
  const [expandedIds, setExpandedIds] = React.useState<IExpansionState>({});
  const makeExpansionHandler = (id: string) => () => {
    setExpandedIds((old) => ({ ...old, [id]: !(old[id] ?? false) }));
  };

  useEffect(() => {
    setExpandedIds({});
  }, []);

  // What we need from the Redux state
  let allTemplates = useSelector((state: IState) => state.enrollment?.matchingTemplates ?? []);
  const planId = useSelector((state: IState) => state.enrollment?.plan?.id);
  const allowedComponents = useSelector((state: IState) => state?.enrollment?.allowedComponents);
  const componentHash = useMemo(() => fromPairs((allowedComponents ?? []).map((c) => [c.recordId, c])), [
    allowedComponents,
  ]);

  const components = allowedComponents?.filter((ac) => props.mmsRecordIds?.includes(ac.recordId));
  const streamOrEntryPointComponent = components?.find(
    (component) => component.type === ComponentType.STREAM || component.type === ComponentType.ENTRY,
  );

  if (!!streamOrEntryPointComponent) {
    allTemplates = allTemplates.filter((t) => t.mmsRecordIds.includes(streamOrEntryPointComponent.recordId));
  }

  let templates = useMemo(
    () =>
      orderBy(
        allTemplates.filter((t) => templateName(t, componentHash).toLowerCase().includes(debouncedSearchText)),
        (t) => templateName(t, componentHash),
      ),
    [allTemplates, componentHash, debouncedSearchText],
  );

  if (props.mmsRecordIds && props.mmsRecordIds.length > 0) {
    templates = templates.filter((t) => !!t.mmsRecordIds.find((m) => props.mmsRecordIds?.includes(m)));
  }

  const [templateToClone, setTemplateToClone] = useState<string | null>(null);
  const selectTemplateToClone = useCallback((id: string) => () => setTemplateToClone(id), []);
  const clearTemplateToClone = useCallback(() => setTemplateToClone(null), []);

  const dispatch = useDispatch();
  const cloneTemplate = useCallback(() => {
    if (templateToClone && planId) {
      clearTemplateToClone();
      dispatch(loadTemplate(planId, templateToClone));
      props.close();
    }
  }, [clearTemplateToClone, dispatch, planId, props, templateToClone]);

  if (allTemplates.length === 0) {
    return null;
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="delete-plan-dialog-title"
        aria-describedby="delete-plan-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        {!!templates && (
          <>
            <DialogTitle id="delete-plan-dialog-title">Sample Course Plans available</DialogTitle>
            <DialogTextContainer>
              {props.mmsRecordIds && props.mmsRecordIds.length > 0 && !streamOrEntryPointComponent && (
                <p>
                  Based on your selected major, the following sample Course Plans are available. These will help ensure
                  you don&apos;t miss any subjects that lead to your major.
                </p>
              )}
              {(!props.mmsRecordIds || streamOrEntryPointComponent) && (
                <p>
                  Based on your selected course and intake, the following sample Course Plans are available. Sample
                  Course Plans help ensure you stay on track and meet particular course requirements.
                </p>
              )}
            </DialogTextContainer>
            <Toolbar>
              <TextField
                autoFocus={true}
                fullWidth={true}
                onChange={onSearchTextChange}
                placeholder="Search Templates"
                variant="outlined"
                inputProps={{ "aria-label": "Search Templates" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Toolbar>
            <DialogTextContainer>
              <SearchDrawerResultsHeaderContainer>
                <SearchResultsHeader>
                  <p>Available templates</p>
                  <p>{`showing ${templates.length} out of ${allTemplates.length}`}</p>
                </SearchResultsHeader>
              </SearchDrawerResultsHeaderContainer>
            </DialogTextContainer>
            <DialogContent>
              <SubjectSearchResults>
                <UL>
                  {templates.map((t) => (
                    <li key={t.id}>
                      <Aligned style={{ alignItems: "start", justifyContent: "space-between" }}>
                        <div>{templateName(t, componentHash)}&nbsp;&nbsp;</div>
                        <Aligned>
                          {!!t.notes && (
                            <Tooltip
                              arrow
                              aria-label={"View plan notes"}
                              title={expandedIds[`${t.id}`] ? "Collapse plan notes" : "Expand plan notes"}
                              placement="top"
                            >
                              <ExpandCollapseButton
                                size="small"
                                color="inherit"
                                aria-label={expandedIds[`${t.id}`] ? "Collapse plan notes" : "Expand plan notes"}
                                onClick={makeExpansionHandler(t.id)}
                              >
                                {expandedIds[`${t.id}`] ? (
                                  <ExpandLessIcon fontSize="small" />
                                ) : (
                                  <ExpandMoreIcon fontSize="small" />
                                )}
                              </ExpandCollapseButton>
                            </Tooltip>
                          )}

                          <Tooltip
                            arrow
                            aria-label={"Load template into current plan"}
                            title={"Load template into current plan"}
                            placement="top"
                          >
                            <span>
                              <IconButton size="small" onClick={selectTemplateToClone(t.id)} disabled={disableActions}>
                                <GetAppIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>

                          <Tooltip
                            arrow
                            aria-label={"View template (opens new page)"}
                            title={"View template (opens new page)"}
                            placement="top"
                          >
                            <span>
                              <IconButton
                                size="small"
                                href={`${window.location.origin}/plan/${t.id}`}
                                target="_blank"
                                disabled={disableActions}
                              >
                                <OpenInNewIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Aligned>
                      </Aligned>
                      <Collapse in={expandedIds[`${t.id}`] ?? false} timeout="auto" unmountOnExit>
                        <div dangerouslySetInnerHTML={{ __html: !!t.notes ? t.notes : "" }} />
                      </Collapse>
                    </li>
                  ))}
                </UL>
              </SubjectSearchResults>
            </DialogContent>
            <DialogTextContainer>
              <p>Proceed without importing a sample Course Plan?</p>
              <DialogActions>
                <Button onClick={props.close} variant="contained">
                  OK
                </Button>
              </DialogActions>
            </DialogTextContainer>
          </>
        )}

        <Spinner loading={!templates} />
      </Dialog>
      <LoadTemplateDialog open={!!templateToClone} onCancel={clearTemplateToClone} onAccept={cloneTemplate} />
    </>
  );
};
