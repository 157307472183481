import React from "react";
import logo from "../../assets/images/logo-with-padding.svg";
import { ResponsiveCenter } from "components/spinner/Center";
import styled from "styled-components";
import { AppBar, Button, Theme, Toolbar, useTheme } from "@mui/material";
import "./app-bar.css";
import { Aligned } from "components/aligned/alignedDiv";
import ListOutlined from "@mui/icons-material/ListOutlined";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { Link } from "@reach/router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SimpleMenu from "./AdminMenu";
import { featureToggles } from "../../config/featureToggles";

const StyledToolbar = styled(Toolbar)`
  padding-left: 0rem !important;
`;
const Img = styled.img`
  max-height: 5rem;
`;
const UserName = styled.div`
  margin-top: 0.5rem;
  font-size: 85%;
  display: flex;
  align-items: center;
`;

const useStyles = (theme: Theme) => ({
  appBar: {
    background: "purple",
  },
  navIcon: {
    fill: theme.palette.common.white,
  },
  navButton: {
    color: theme.palette.common.white,
    marginRight: "1rem",
  },
});

interface IProps {
  path?: string;
  id?: string;
}

export const AdminAppHeader = (_props: IProps) => {
  const admin = useSelector((s: IState) => s.admin);
  const theme = useTheme();
  const styles = useStyles(theme);
  const path = _props.path;
  return (
    <AppBar position="static" className={`do-not-print`} sx={styles.appBar}>
      <ResponsiveCenter>
        <Aligned style={{ justifyContent: "space-between" }}>
          <StyledToolbar>
            <Img src={logo} alt="University of Melbourne Logo" />
            <Aligned style={{ flexDirection: "column", alignItems: "start" }}>
              <Link style={{ whiteSpace: "nowrap" }} to="/staff" className="page-title">
                My Course Planner (Admin)
                {admin && (
                  <UserName>
                    <AccountCircleIcon style={{ fill: "white" }} fontSize="small" />
                    &nbsp;
                    {admin?.user}
                  </UserName>
                )}
              </Link>
            </Aligned>
          </StyledToolbar>

          {admin && path === "templates" && !featureToggles.componentEligibility && (
            <Aligned>
              <Link to="/staff/templates">
                <Button sx={styles.navButton} startIcon={<ListOutlined sx={styles.navIcon} fontSize="small" />}>
                  View&nbsp;Templates
                </Button>
              </Link>
              <Link to="/staff/new-template">
                <Button
                  sx={styles.navButton}
                  variant="text"
                  startIcon={<AddOutlined sx={styles.navIcon} fontSize="small" />}
                >
                  Add&nbsp;Template
                </Button>
              </Link>
            </Aligned>
          )}
          {featureToggles.componentEligibility && admin && path === "eligibility" && <SimpleMenu />}

          {featureToggles.componentEligibility && admin && path === "templates" && <SimpleMenu />}

          {featureToggles.discontinuedMMS && admin && path === "discontinued-mms" && <SimpleMenu />}
        </Aligned>
      </ResponsiveCenter>
    </AppBar>
  );
};
