import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { CHANGE_COURSE, CHANGE_YEAR, GET_ALL_MMS, GET_CONFIG } from "actions/planCreationConfig";
import { IPlanCreationConfig } from "store/types";

const planCreationConfig = (state = initialState.planCreationConfig, action: any): IPlanCreationConfig | null => {
  switch (action.type) {
    case failed(GET_CONFIG):
    case pending(GET_CONFIG):
      return initialState.planCreationConfig;
    case fulfilled(GET_CONFIG):
      return {
        ...state,
        ...action.payload,
        allowedStartYears: [],
        migratedStartYears: [],
        selectedCourseId: null,
        componentsForSelectedCourse: [],
        selectedYear: null,
      };
    case fulfilled(CHANGE_COURSE):
    case fulfilled(CHANGE_YEAR):
    case fulfilled(GET_ALL_MMS):
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default planCreationConfig;
