import React from "react";

import { AdminAppHeader } from "containers/app-bar/AdminAppBar";
import { CreatePlan } from "containers/CreatePlan";

interface IProps {
  path?: string;
}

export const CreateTemplate = (_props: IProps) => {
  return (
    <>
      <AdminAppHeader />
      <CreatePlan mode="template" />
    </>
  );
};
