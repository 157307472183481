import React, { useCallback, useEffect, useRef, useState } from "react";
import { CourseGrid } from "./CourseGrid";
import AppBar from "./app-bar/AppBar";
import { AppSubheading } from "./AppSubheading";
import AppFooter from "components/app-footer/app-footer";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "store/types";
import { assignSubject, getEnrollment } from "../actions/enrollment";
import { DragDropContext, DragStart, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { parseDnDId } from "./slotCard/dnd";
import { moveOptimistically } from "actions/optimisticUpdate";
import { SubjectChecklistDrawer } from "./slotCard/SubjectChecklistDrawer";
import { TemplatesDrawer } from "./app-bar/TemplatesDrawer";
import { SimilarSubjectsDrawer } from "./slotCard/SimilarSubjectsDrawer";
import { RuleSubjectSearchDrawer } from "./search/RuleSubjectSearchDrawer";
import { Feedback } from "components/feedback";
import { setDraggedSubject } from "actions/draggedSubjectId";
import { SubjectSearchDrawer } from "./search/SubjectSearchDrawer";
import { SearchFAB } from "./search/SearchFAB";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { CloneTemplateFAB } from "./search/CloneTemplateFAB";
import { AdminAppHeader } from "./app-bar/AdminAppBar";
import { useLocation, useNavigate } from "@reach/router";
import { FocusedInvocationBanner } from "./FocusedInvocationBanner";
import { SampleCoursePlanDialog } from "./planAction/SampleCoursePlanDialog";
import { featureToggles } from "../config/featureToggles";

interface IProps {
  path?: string;
  id?: string;
  courseCode?: string;
  planYear?: string;
  children?: any | null;
}

export const PlanContainer = ({ id, children }: IProps) => {
  const enrollment = useSelector((state: IState) => state.enrollment);
  const loading = useSelector((state: IState) => state.loading);
  const planId = enrollment?.plan.id;
  const currentPlanYear = enrollment?.plan.year;
  const courseRecordId = enrollment?.plan?.courseRecordId;
  const currentCourseCode = enrollment?.course.code;
  const { readOnlyPlan, cloneable, adminPath } = usePlanPermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const hasMatchingTemplates = !!enrollment?.matchingTemplates && enrollment?.matchingTemplates.length > 0;

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevPlanId = usePrevious(planId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id && id !== planId && !loading) {
      if (id !== prevPlanId) {
        dispatch(getEnrollment(id));
      }
    }
  }, [id, dispatch, planId, loading, prevPlanId]);

  // Redirect old links
  useEffect(() => {
    const newPath = `/${currentCourseCode}/${currentPlanYear}/plan/`;
    if (currentCourseCode && currentPlanYear && !pathname.startsWith(newPath) && !adminPath) {
      // eslint-disable-next-line no-useless-escape
      const fullPath = pathname.replace(/^(\/[^\/]*\/[^\/]*)?\/plan\//g, newPath).replace(/\/+/g, "/");
      navigate(fullPath);
    }
  }, [currentPlanYear, currentCourseCode, pathname, adminPath, navigate]);

  const [templatesDrawerOpen, setTemplatesDrawerOpen] = useState(false);
  const flipTemplatesDrawer = useCallback(() => setTemplatesDrawerOpen((old) => !old), []);

  const [sampleCoursePlanOpen, setSampleCoursePlanOpenOpen] = useState(true);
  const flipSampleCoursePlan = useCallback(() => setSampleCoursePlanOpenOpen((old) => !old), []);
  const mmsRecordIds = enrollment?.plan.mmsRecordIds;

  const onDragEnd = useCallback(
    (result: DropResult, _provided: ResponderProvided) => {
      dispatch(setDraggedSubject(null));
      const source = parseDnDId(result.source.droppableId);
      if (planId && courseRecordId && source.subjectRecordId && result.destination?.droppableId) {
        const destination = parseDnDId(result.destination?.droppableId);

        if (!readOnlyPlan && destination.year && destination.semester && destination.slotIndex !== undefined) {
          dispatch(moveOptimistically(source, destination));
          dispatch(
            assignSubject({
              planId,
              courseRecordId,
              subjectRecordId: source.subjectRecordId,
              mmsRecordId: source.mmsRecordId,
              courseRecordIds: source.courseRecordIds,
              year: destination.year,
              semester: destination.semester,
              slotIndex: destination.slotIndex,
              assignAs: source.assignAs,
            }),
          );
        }
      }
    },
    [dispatch, planId, courseRecordId, readOnlyPlan],
  );

  const onDragStart = useCallback(
    (initial: DragStart, _provided: ResponderProvided) => {
      const draggableId = JSON.parse(initial.draggableId);
      dispatch(setDraggedSubject(draggableId.subjectRecordId));
    },
    [dispatch],
  );

  return id && enrollment ? (
    <>
      {adminPath ? (
        <AdminAppHeader />
      ) : (
        <AppBar openTemplatesDrawer={flipTemplatesDrawer} mmsRecordIds={mmsRecordIds} />
      )}
      {enrollment && <AppSubheading />}
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        dragHandleUsageInstructions={"Use the Subject menu to edit, remove or move this subject."}
      >
        {enrollment && <CourseGrid />}
        {enrollment && <AppFooter />}
        {enrollment && <SubjectChecklistDrawer />}
        {enrollment && (
          <TemplatesDrawer open={templatesDrawerOpen} close={flipTemplatesDrawer} mmsRecordIds={mmsRecordIds} />
        )}
        {enrollment && <SimilarSubjectsDrawer />}
        {enrollment && <RuleSubjectSearchDrawer />}
        {enrollment && !readOnlyPlan && <SubjectSearchDrawer />}
        {enrollment && <Feedback />}
        {enrollment && !readOnlyPlan && <SearchFAB />}
        {enrollment && cloneable && <CloneTemplateFAB />}
        {enrollment && <FocusedInvocationBanner />}
        {children || null}
      </DragDropContext>
      {!!featureToggles.sampleTemplate &&
        hasMatchingTemplates &&
        !enrollment?.plan?.template &&
        !enrollment.plan?.sourceTemplateId &&
        !!enrollment.isNewCreation && (
          <SampleCoursePlanDialog
            open={sampleCoursePlanOpen}
            close={flipSampleCoursePlan}
            mmsRecordIds={mmsRecordIds}
          />
        )}
    </>
  ) : null;
};
