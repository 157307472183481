import { useLocation } from "@reach/router";
import { useSelector } from "react-redux";
import { IState, UserMode } from "store/types";

export const usePlanPermissions = () => {
  const location = useLocation();
  const admin = useSelector((s: IState) => s.admin);
  const planIsTemplate = useSelector((s: IState) => !!s.enrollment?.plan?.template);

  const adminPath = location.pathname === "/staff" || location.pathname.startsWith("/staff/");
  const userMode = adminPath && admin ? UserMode.ADMIN : UserMode.STUDENT;

  return {
    userMode,
    readOnlyPlan: planIsTemplate && userMode !== UserMode.ADMIN,
    cloneable: !adminPath && planIsTemplate,
    adminPath: !!adminPath,
    planIsTemplate,
  };
};
