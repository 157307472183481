import { flatten } from "lodash";
import { IEnrollment, IRuleInvocation, IRuleInvocationGroup } from "store/types";

// Type guard
export function isRuleInvocation(i: IRuleInvocation | IRuleInvocationGroup): i is IRuleInvocation {
  const invocation = i as IRuleInvocation;
  return !!invocation.ruleId && !!invocation.ruleType && !!invocation.recordId && !!invocation.text;
}

export const getChecklistInvocations = (enrollment: IEnrollment | null) => {
  const invocations = enrollment?.validation?.invocations || [];
  const courseRecordId = enrollment?.plan.courseRecordId;
  const mmsRecordIds = enrollment?.plan.mmsRecordIds || [];
  const secondaryCoursesIds = (enrollment?.plan.secondaryCourses || []).map((c) => c.courseRecordId);
  const secondaryCoursesMMSIds = flatten((enrollment?.plan.secondaryCourses || []).map((c) => c.mmsRecordIds ?? []));
  const targetRercordIds = [courseRecordId, ...mmsRecordIds, ...secondaryCoursesIds, ...secondaryCoursesMMSIds].filter(
    Boolean,
  );

  return invocations.filter((i) => targetRercordIds.indexOf(i.recordId) >= 0);
};

export const isGroupSuccessful = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  allInvocations: IRuleInvocation[],
): boolean => {
  if (group.result !== undefined) {
    return group.result;
  }
  const groupInvocations = allInvocations.filter((cr) => cr.groupId === group.id);
  if (groupInvocations.find((gi) => !gi.result)) {
    return false;
  }
  const subGroups = allGroups.filter((g) => g.parentId === group.id);
  if (subGroups.find((sg) => !isGroupSuccessful(sg, allGroups, allInvocations))) {
    return false;
  }
  return true;
};

export const isInfoGroup = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  allInvocations: IRuleInvocation[],
): boolean => {
  const groupInvocations = allInvocations.filter((cr) => cr.groupId === group.id);
  if (groupInvocations.find((gi) => !isInfoRule(gi))) {
    return false;
  }
  const subGroups = allGroups.filter((g) => g.parentId === group.id);
  if (subGroups.find((sg) => !isInfoGroup(sg, allGroups, allInvocations))) {
    return false;
  }
  return groupInvocations.length > 0 || subGroups.length > 0;
};

export const isInfoOrConstraintGroup = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  allInvocations: IRuleInvocation[],
): boolean => {
  const groupInvocations = allInvocations.filter((cr) => cr.groupId === group.id);
  if (groupInvocations.find((gi) => !isConstraintRule(gi) && !isInfoRule(gi))) {
    return false;
  }
  const subGroups = allGroups.filter((g) => g.parentId === group.id);
  if (subGroups.find((sg) => !isInfoOrConstraintGroup(sg, allGroups, allInvocations))) {
    return false;
  }
  return groupInvocations.length > 0 || subGroups.length > 0;
};

export const isGroupEmpty = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
) => isGroupEmptyHelper(group, allGroups, invocations, {});

const isGroupEmptyHelper = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
  visited: { [key: string]: boolean },
) => {
  visited[group.id] = true;
  const groupInvocations = invocations.filter((cr) => cr.groupId === group.id);
  if (groupInvocations.length > 0) {
    return false;
  }

  const subGroups = allGroups.filter((g) => g.parentId === group.id).filter((g) => !visited[g.id]);

  for (const subGroup of subGroups) {
    if (!isGroupEmptyHelper(subGroup, allGroups, invocations, visited)) {
      return false;
    }
  }
  return true;
};

export const isGroupOnlyAboutRecords = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
  recordIds: string[],
) => isGroupOnlyAboutRecordsHelper(group, allGroups, invocations, recordIds, {});

const isGroupOnlyAboutRecordsHelper = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
  recordIds: string[],
  visited: { [key: string]: boolean },
) => {
  visited[group.id] = true;
  if (recordIds.length === 0) {
    return false;
  }

  const groupInvocations = invocations.filter((cr) => cr.groupId === group.id);
  for (const invocation of groupInvocations) {
    if (recordIds.indexOf(invocation.recordId) < 0) {
      return false;
    }
  }

  const subGroups = allGroups.filter((g) => g.parentId === group.id).filter((g) => !visited[g.id]);

  for (const subGroup of subGroups) {
    if (!isGroupOnlyAboutRecordsHelper(subGroup, allGroups, invocations, recordIds, visited)) {
      return false;
    }
  }
  return true;
};

export const isGroupAboutAnyRecords = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
  recordIds: string[],
) => isGroupAboutAnyRecordsHelper(group, allGroups, invocations, recordIds, {});

const isGroupAboutAnyRecordsHelper = (
  group: IRuleInvocationGroup,
  allGroups: IRuleInvocationGroup[],
  invocations: IRuleInvocation[],
  recordIds: string[],
  visited: { [key: string]: boolean },
) => {
  visited[group.id] = true;
  if (recordIds.length === 0) {
    return true;
  }

  const groupInvocations = invocations.filter((cr) => cr.groupId === group.id);
  for (const invocation of groupInvocations) {
    if (recordIds.indexOf(invocation.recordId) >= 0) {
      return true;
    }
  }

  const subGroups = allGroups.filter((g) => g.parentId === group.id).filter((g) => !visited[g.id]);

  for (const subGroup of subGroups) {
    if (isGroupAboutAnyRecordsHelper(subGroup, allGroups, invocations, recordIds, visited)) {
      return true;
    }
  }
  return false;
};

export const getParentGroups = (
  allGroups: IRuleInvocationGroup[],
  node: IRuleInvocation | IRuleInvocationGroup,
): IRuleInvocationGroup[] => {
  const parentId = isRuleInvocation(node) ? node.groupId : node.parentId;
  const parent = allGroups.find((g) => parentId === g.id);
  if (!parent) {
    return [];
  } else if (!parent.parentId) {
    return [parent];
  }
  return [parent, ...getParentGroups(allGroups, parent)];
};

export const isInfoRule = (rule: IRuleInvocation) => rule.ruleType.match(/information$/gi);
export const isCCRule = (rule: IRuleInvocation) => rule.ruleType.match(/count constraint$/gi);
export const isPCRule = (rule: IRuleInvocation) => rule.ruleType.match(/points? constraint$/gi);

export const isConstraintRule = (rule: IRuleInvocation) => {
  return (
    rule.ruleType.match(/progression$/gi) || rule.ruleType.match(/cross/gi) || (rule.progress && !rule.progress.minimum)
  );
};

export const isSubjectRule = (rule: IRuleInvocation) => rule.recordId.match(/^SUB/gi);
