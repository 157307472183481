import React, { useCallback } from "react";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { Fab, Tooltip, useTheme } from "@mui/material";
import { IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { flipSearchDrawer } from "actions/searchDrawerOpen";
import { useFabStyles } from "theme/styles";
import { brandDarkBlue } from "theme/colors";

export function SearchFAB() {
  const searchIsOpen = useSelector((state: IState) => state.searchDrawerOpen);
  const focusBannerOpen = useSelector((state: IState) => !!state.focusInvocationId);
  const dispatch = useDispatch();
  const openSearch = useCallback(() => dispatch(flipSearchDrawer()), [dispatch]);

  const theme = useTheme();
  const styles = useFabStyles(theme, {
    backgroundColor: brandDarkBlue.toString(),
    bottomOffset: focusBannerOpen ? "calc(2rem + 100px)" : "2rem",
  });

  return searchIsOpen ? null : (
    <Tooltip
      className="do-not-print"
      arrow
      aria-label={"Search And Add Subjects"}
      title={"Search And Add Subjects"}
      placement="top"
    >
      <Fab color="primary" className={`do-not-print`} sx={styles.fab} variant="extended" onClick={openSearch}>
        <SearchOutlined sx={styles.fabIcon} />
        Subject Search
      </Fab>
    </Tooltip>
  );
}
