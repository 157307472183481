import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Field, Formik, FormikHelpers } from "formik";
import { validateValuePresence } from "lib/validation";
import { TextField } from "formik-mui";
import { FormControl, IconButton, InputAdornment } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { renamePlan as enrollmentRenamePlan } from "actions/enrollment";
import { renamePlan as adminRenamePlan } from "actions/admin";
import { useDispatch } from "react-redux";
import { useLocation } from "@reach/router";

// The common bits we need from a plan or template
interface IPlanSummary {
  id?: string;
  name: string;
}

interface IProps {
  open: boolean;
  close: () => any;
  plan?: IPlanSummary;
  isTemplate?: boolean;
}

export interface INewNameFormVals {
  name: string;
}

export const ChangeNameDialog = ({ open, close, plan, isTemplate }: IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const planId = plan?.id;
  const planName = plan?.name?.trim();

  const initialValues: INewNameFormVals = { name: planName || "" };

  const onSubmit = useCallback(
    (values: INewNameFormVals, helpers: FormikHelpers<INewNameFormVals>) => {
      close();
      if (planId) {
        let submitResult;
        if (location.pathname === "/staff" || location.pathname === "/staff/") {
          submitResult = dispatch(adminRenamePlan(planId, values)) as any;
        } else {
          submitResult = dispatch(enrollmentRenamePlan(planId, values)) as any;
        }
        submitResult.catch(() => helpers.setSubmitting(false));
      }
    },
    [close, dispatch, location.pathname, planId],
  );

  const validateFormState = useCallback(
    (values: INewNameFormVals) => {
      const errors: any = {};
      if (!isTemplate) {
        validateValuePresence(errors, values, "name");
      }
      return errors;
    },
    [isTemplate],
  );

  if (!open || !planId) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="rename-plan-dialog-title"
          aria-describedby="rename-plan-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="rename-plan-dialog-title">Rename {isTemplate ? "template" : "plan"}</DialogTitle>
          <DialogContent>
            <form onSubmit={props.handleSubmit} id="rename-plan-form">
              <FormControl fullWidth variant="outlined" required>
                <Field
                  // style={{ width: `min(80vw, 400px)` }}
                  component={TextField}
                  sx={{ margin: "4px 1px" }}
                  name="name"
                  label={`New name`}
                  fullWidth={true}
                  variant="outlined"
                  required={!isTemplate}
                  inputProps={{
                    // Changing based on accessibility feedback CPT-535
                    "aria-label": `New name`,
                    required: !isTemplate,
                  }}
                  InputProps={
                    isTemplate
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear name field"
                                onClick={() => props.setFieldValue("name", "")}
                                size="large"
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="rename-plan-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
