import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { updateDiscontinuedMM } from "../../actions/admin";
import { EditDiscontinuedMMSForm } from "./EditDiscontinuedMMSForm";

interface IProps {
  open: boolean;
  close: () => any;
  discontinuedRecord?: any;
}

export const EditDiscontinuedMMSDialog = ({ open, close, discontinuedRecord }: IProps) => {
  const dispatch = useDispatch();
  const initialValues: any =
    { course: discontinuedRecord?.course, discontinuedMMS: discontinuedRecord?.discontinuedMMS } || {};

  const onSubmit = useCallback(
    (values: Array<any>, helpers: FormikHelpers<Array<any>>) => {
      close();
      if (discontinuedRecord) {
        const submitResult = dispatch(
          updateDiscontinuedMM({
            recordKey: discontinuedRecord.recordKey,
            course: discontinuedRecord.course,
            discontinuedMMS: (values as any).discontinuedMMS,
          }),
        ) as any;
        submitResult.catch(() => helpers.setSubmitting(false));
      }
    },
    [close, dispatch, discontinuedRecord],
  );

  if (!open) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="edit-discontinuedMMS-dialog-title"
          aria-describedby="edit-discontinuedMMS-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="edit-discontinuedMMS-dialog-title">Edit DiscontinuedMMS Rule</DialogTitle>
          <DialogContent>
            <EditDiscontinuedMMSForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="edit-discontinuedMMS-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
