import { IBaseCourse, ISlot, IState } from "../../../store/types";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import { ListItemIcon, ListItemText } from "@mui/material";
import Done from "@mui/icons-material/Done";
import React, { useCallback } from "react";
import ListIcon from "@mui/icons-material/List";
import { useDispatch, useSelector } from "react-redux";
import { changeAssignment } from "../../../actions/enrollment";
import DoneAll from "@mui/icons-material/DoneAll";

interface IProps {
  selectableCourses: IBaseCourse[];
  slotCourseRecordIds: Array<string>;
  subjectRecordId: string | undefined;
  slot: ISlot | undefined;
}

export const AssignSecondaryCourseSubMenu = (props: IProps) => {
  const { selectableCourses, slotCourseRecordIds, slot, subjectRecordId } = props;
  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const planId = plan?.id;

  const mainCourse = useSelector((s: IState) => s.enrollment?.course);

  const dispatch = useDispatch();

  const selectedSubjectRecordId = slot?.subjectRecordId || subjectRecordId;
  const slotMMSRecordId = slot?.mmsRecordId;

  const doChangeCourse = useCallback(
    (courseRecordIds: string[]) => () => {
      if (selectedSubjectRecordId) {
        let newCourseRecordIds = courseRecordIds;
        if (newCourseRecordIds.length === 0) {
          newCourseRecordIds = [mainCourse!.recordId];
        }
        dispatch(changeAssignment(planId || "", selectedSubjectRecordId, newCourseRecordIds, slotMMSRecordId, false));
      }
    },
    [selectedSubjectRecordId, dispatch, planId, slotMMSRecordId, mainCourse],
  );

  return (
    <>
      {
        <SubMenu
          key={`course-menu-root`}
          label={() => (
            <>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Assign to Course, Diploma, or Cross-Credit" />
            </>
          )}
        >
          {selectableCourses.map((c: any) => {
            const selected = slotCourseRecordIds.length === 1 && slotCourseRecordIds[0] === c.recordId;
            return (
              <MenuItem
                aria-pressed={selected}
                checked={selected}
                type="checkbox"
                key={c.recordId}
                onClick={doChangeCourse(selected ? [] : [c.recordId])}
              >
                <ListItemIcon>{selected && <Done />}</ListItemIcon>
                <ListItemText primary={c.name} />
              </MenuItem>
            );
          })}
          {(function () {
            const selected = slotCourseRecordIds.length === selectableCourses.length;
            return (
              <MenuItem
                aria-pressed={selected}
                checked={selected}
                type="checkbox"
                onClick={doChangeCourse(selectableCourses.map((s: any) => s.recordId))}
              >
                <ListItemIcon>{selected && <DoneAll />}</ListItemIcon>
                <ListItemText primary={"Both / Cross Credit"} />
              </MenuItem>
            );
          })()}
        </SubMenu>
      }
    </>
  );
};
