import { useDispatch, useSelector } from "react-redux";
import { IState, ISubjectQuery, ISubjectQuerySort } from "store/types";
import max from "lodash/max";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "lib/useDebounce";
import { InitialSearchQuery } from "lib/searchConstants";
import hash from "object-hash";
import { isQueryEmpty } from "lib/search";
import { clearSearch, searchSubjects } from "actions/subjectSearch";

const currentYear = new Date().getFullYear();

export const useSearch = (year: number | undefined, initialQuery: ISubjectQuery = InitialSearchQuery) => {
  const latestCourseYear =
    useSelector((state: IState) => max(state.enrollment?.course.allowedStartYears || [])) || currentYear;
  const searchYear = Math.min(latestCourseYear, year || currentYear);

  // The state of the search dialog
  const [searchText, setSearchText] = useState("");
  const debouncedText = useDebounce(searchText, 300);
  const [query, setQuery] = useState<ISubjectQuery>(initialQuery);
  const [searching, setSearching] = useState(false);

  useEffect(() => setSearching(true), [setSearching, searchText]);
  useEffect(() => setQuery((q) => ({ ...q, text: debouncedText, page: 0 })), [setQuery, debouncedText]);
  const updateQuery = useCallback(
    (prop: keyof ISubjectQuery) => (vals: any) =>
      setQuery((old) => ({ ...old, [prop]: vals, page: prop === "page" ? vals : 0 })),
    [setQuery],
  );
  const updatePage = useCallback(
    (_event: React.ChangeEvent<unknown>, value: number) => {
      updateQuery("page")(value - 1);
    },
    [updateQuery],
  );
  const toggleBreadth = useCallback(
    () => setQuery((old) => ({ ...old, onlyBreadth: !old.onlyBreadth, page: 0 })),
    [setQuery],
  );
  const toggleDiscipline = useCallback(
    () => setQuery((old) => ({ ...old, onlyDiscipline: !old.onlyDiscipline, page: 0 })),
    [setQuery],
  );
  const updateSort = useCallback(
    (sort?: ISubjectQuerySort | null) => setQuery((old) => ({ ...old, sort: sort ?? null })),
    [setQuery],
  );

  // Hash to keep track if anything changes
  const queryHash = useMemo(() => hash({ searchYear, query }), [searchYear, query]);
  const initialQueryHash = useMemo(() => hash(initialQuery), [initialQuery]);

  // Reset the query when the initialquery is reinitialised
  useEffect(() => {
    setQuery(initialQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQueryHash]);

  // Search on change
  const dispatch = useDispatch();
  const onSearchTextChange = useCallback(
    (e: any) => setSearchText(e.target.value.trim().toLowerCase()),
    [setSearchText],
  );
  const doSearch = useCallback(
    (q: ISubjectQuery) => {
      if (isQueryEmpty(q)) {
        dispatch(clearSearch());
      } else {
        setSearching(true);

        const p: any = dispatch(searchSubjects(searchYear, q));
        if (p === Promise.resolve(p)) {
          p.finally(() => setSearching(false));
        } else {
          setSearching(false);
        }
      }
    },
    [dispatch, searchYear],
  );

  // Whenever the query changes - send out a request
  useEffect(() => {
    doSearch(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryHash, doSearch]);

  const refreshSearch = useCallback(() => {
    doSearch(query);
  }, [doSearch, query]);

  // Clear the Redux state on open
  useEffect(() => {
    dispatch(clearSearch());
  }, [dispatch]);

  return {
    searchYear,
    query,
    setQuery,
    updateQuery,
    updatePage,
    updateSort,
    onSearchTextChange,
    searching,
    toggleBreadth,
    toggleDiscipline,
    refreshSearch,
  };
};

export const useSearchPagination = () => {
  const enablePagination = useSelector(
    (state: IState) => (state.subjectSearch?.results || []).length < (state.subjectSearch?.totalCount ?? 0),
  );

  const numPages = useSelector((state: IState) => {
    const totalCount = state.subjectSearch?.totalCount || 0;
    const pageSize = state.subjectSearch?.query?.pageSize ?? 0;
    if (pageSize > 0) {
      return Math.ceil(totalCount / pageSize);
    } else {
      return 0;
    }
  });

  return { enablePagination, numPages };
};
