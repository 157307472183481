export const CLOSE_SNACKBAR = "snackbar/CLOSE_SNACKBAR";
export const OPEN_SNACKBAR_WITH_ERR = "snackbar/OPEN_SNACKBAR_WITH_ERR";

export const closeSnackBar = () => ({
  type: CLOSE_SNACKBAR,
  payload: {},
});

export const openSnackBarWithError = (message: string) => ({
  type: OPEN_SNACKBAR_WITH_ERR,
  payload: { message },
});
