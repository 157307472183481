import { ISubjectSearchDrawerFor } from "store/types";

export const OPEN_SUBJECT_FINDER_DRAWER = "subjectDrawerForRule/OPEN_SUBJECT_FINDER_DRAWER";

export const openSubjectDrawerForRuleId = (drawerFor: ISubjectSearchDrawerFor) => {
  return {
    type: OPEN_SUBJECT_FINDER_DRAWER,
    payload: drawerFor,
  };
};

export const closeSubjectDrawerForRule = () => {
  return {
    type: OPEN_SUBJECT_FINDER_DRAWER,
    payload: null,
  };
};
