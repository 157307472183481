import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

interface IProps {
  open: boolean;
  close: () => any;
  onChangeDiploma: () => any;
}

export const DiplomaDialog = ({ open, close, onChangeDiploma }: IProps) => {
  return (
    <Dialog open={open} onClose={close} aria-describedby="diploma-dialog-description" maxWidth="sm" fullWidth>
      <DialogContent>
        <p>
          Enrolling into a Concurrent Diploma requires approval through an application process. Please see our &nbsp;
          <a href="https://students.unimelb.edu.au/your-course/concurrent-diplomas" target="_blank" rel="noreferrer">
            Concurrent Diploma website
          </a>
          &nbsp; for eligibility criteria and application details.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={onChangeDiploma}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
