import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Field, Formik, FormikHelpers } from "formik";
import { validateValuePresence } from "lib/validation";
import { FormControl } from "@mui/material";
import { isEmpty } from "lodash";
import { editNotesToPlan } from "actions/enrollment";
import { useDispatch } from "react-redux";
import WYSIWYGEditor from "./WYSIWYGEditor";

// The common bits we need from a plan or template
interface IPlanSummary {
  id?: string;
  notes?: string;
}

interface IProps {
  open: boolean;
  close: () => any;
  plan?: IPlanSummary;
  isTemplate?: boolean;
}

export interface INotesFormVals {
  notes: string;
}

export const EditNotesDialog = ({ open, close, plan, isTemplate }: IProps) => {
  const dispatch = useDispatch();

  const planId = plan?.id;
  const notes = plan?.notes?.trim();

  const initialValues: INotesFormVals = { notes: notes || "" };

  const onSubmit = useCallback(
    (values: INotesFormVals, helpers: FormikHelpers<INotesFormVals>) => {
      close();
      if (planId) {
        const submitResult = dispatch(editNotesToPlan(planId, values)) as any;
        submitResult.catch(() => helpers.setSubmitting(false));
      }
    },
    [close, dispatch, planId],
  );

  const validateFormState = useCallback(
    (values: INotesFormVals) => {
      const errors: any = {};
      if (!isTemplate) {
        validateValuePresence(errors, values, "notes");
      }
      return errors;
    },
    [isTemplate],
  );

  if (!open || !planId) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="edit-notes-plan-dialog-title"
          aria-describedby="edit-notes-plan-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="edit-notes-plan-dialog-title">Edit Notes</DialogTitle>
          <DialogContent>
            <form onSubmit={props.handleSubmit} id="edit-notes-plan-form">
              <FormControl fullWidth variant="outlined" required>
                <Field
                  // style={{ width: `min(80vw, 400px)` }}
                  component={WYSIWYGEditor}
                  name="notes"
                />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="edit-notes-plan-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
