import { IPlan, Semester, ISubjectQuery } from "store/types";
// import isArray from "lodash/isArray";

export const getDefaultLevel = (year: number, semester: Semester, plan: IPlan) => {
  const { isMidyearEntry, year: planYear } = plan;

  if (semester === Semester.ADVANCED_STANDING) {
    return "";
  }

  let diff = 0;
  if (!isMidyearEntry || semester === Semester.SEM2 || semester === Semester.WINTER) {
    diff = year - planYear + 1;
  } else {
    diff = year - planYear;
  }
  return String(Math.min(diff, 3));
};

export const isQueryEmpty = (q: Partial<ISubjectQuery>) => !q.ruleSearch && !q.onlyBreadth && !q.onlyDiscipline;
