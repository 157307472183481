import React from "react";
import { ISlot, ISlotIdentifier } from "store/types";

import { Draggable, Droppable } from "react-beautiful-dnd";
import { SubjectSlotCard } from "./subjectSlotCard";
import { makeDnDId } from "./dnd";
import { FullDiv } from "lib/fullDiv";
import { usePlanPermissions } from "lib/usePlanPermissions";

interface IProps extends ISlotIdentifier {
  displaySlot: ISlot;
}

export const DraggableSubjectSlotCard = (props: IProps) => {
  const dndId = makeDnDId({ ...props, subjectRecordId: props.displaySlot?.subjectRecordId });
  const { readOnlyPlan } = usePlanPermissions();

  return (
    <Droppable droppableId={dndId} isDropDisabled={true}>
      {(provided) => (
        <FullDiv ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable draggableId={dndId} index={1} isDragDisabled={!!props.displaySlot?.disabled || readOnlyPlan}>
            {(provided, snapshot) => (
              <FullDiv ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                <SubjectSlotCard {...props} isDragging={snapshot.isDragging} />
              </FullDiv>
            )}
          </Draggable>
          {/* {provided.placeholder} */}
        </FullDiv>
      )}
    </Droppable>
  );
};
