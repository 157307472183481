import React, { useCallback } from "react";
import AddOutlined from "@mui/icons-material/AddOutlined";
import { Fab, Tooltip, useTheme } from "@mui/material";
import { IState } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { useFabStyles } from "theme/styles";
import { post } from "lib/fetch";
import { setSubmittingChanges, START_SUBMITTING, STOP_SUBMITTING } from "actions/submittingChanges";
import { openSnackBarWithError } from "actions/snackBar";

export function CloneTemplateFAB() {
  const theme = useTheme();
  const classes = useFabStyles(theme, { backgroundColor: "#006600" });

  const dispatch = useDispatch();

  const searchIsOpen = useSelector((state: IState) => state.searchDrawerOpen);
  const planId = useSelector((state: IState) => state.enrollment?.plan?.id);

  const cloneTemplate = useCallback(async () => {
    try {
      dispatch(setSubmittingChanges(START_SUBMITTING));
      const payload = await post(`/v1/enrollment/cloneTemplate`, { templateId: planId });
      window.open(`${window.location.origin}/plan/${payload.planId}`, "_blank")?.focus();
    } catch (_e) {
      dispatch(openSnackBarWithError(`Could not clone template`));
    } finally {
      dispatch(setSubmittingChanges(STOP_SUBMITTING));
    }
  }, [dispatch, planId]);

  return searchIsOpen || !planId ? null : (
    <Tooltip
      className="do-not-print"
      arrow
      aria-label={"Clone Plan Template"}
      title={"Clone Plan Template"}
      placement="top"
    >
      <Fab color="primary" className={`do-not-print`} sx={classes.fab} variant="extended" onClick={cloneTemplate}>
        <AddOutlined sx={classes.fabIcon} />
        Create Plan from Template
      </Fab>
    </Tooltip>
  );
}
