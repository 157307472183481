import parse from "date-fns/parse";
import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";

const now = new Date();
const inputFormat = "dd/MM/yyyy";
const outputFormat = "d MMMM yyyy";

export const formatDate = (dateTxt: string) => {
  const date = parse(dateTxt, inputFormat, now);
  return format(date, outputFormat);
};

export const timeAgo = (dateISO: string) => {
  const date = new Date(dateISO);
  const now = new Date();
  return formatDistance(date, now);
};
