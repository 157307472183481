import React, { useEffect } from "react";
import { Collapse, Divider, List, ListItem, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { IState, IRuleInvocationGroup, IRuleInvocation, HintToolTip, IExpansionState } from "store/types";
import { useSelector } from "react-redux";
import { InvocationListItem, InvocationsListContainer } from "./InvocationsList";
import { OperatorHeader, OperatorLink } from "./operator";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import { Aligned } from "components/aligned/alignedDiv";
import { isGroupOnlyAboutRecords, isGroupEmpty, isRuleInvocation, isSubjectRule } from "lib/invocations";
import { Hint } from "containers/Hint";
import { iconForGroup } from "./InvocationsIcons";

interface IProps {
  invocations: IRuleInvocation[];
  expandedByDefault?: boolean;
  onHighlightOpen?: () => any;
}

const useStyles = () => ({
  root: {
    // width: "100%",
    backgroundColor: "#f7f7f7",
    marginLeft: "0rem",
  },
  expansionGroupText: {
    fontWeight: "bold",
  },
});

export const NestedRules = (props: IProps) => {
  const expandedByDefault = !!props.expandedByDefault;

  const [expandedIds, setExpandedIds] = React.useState<IExpansionState>({});
  const validation = useSelector((s: IState) => s.enrollment?.validation);
  const mmsRecordIds = useSelector((s: IState) => s.enrollment?.plan.mmsRecordIds || []);
  // const courseRecordId = useSelector((s: IState) => s.enrollment?.plan.courseRecordId);
  const secondaryCourses = useSelector((s: IState) => s.enrollment?.plan.secondaryCourses || []);
  const ruleInvocations = props.invocations || [];
  const allGroups = uniqBy(validation?.groups || [], (g) => g.id);
  const styles = useStyles();
  const planChecklistRuleInvocations = !ruleInvocations.find((i: IRuleInvocation) => isSubjectRule(i));

  const makeExpansionHandler = (id: string) => () =>
    setExpandedIds((old) => ({ ...old, [id]: !(old[id] ?? expandedByDefault) }));

  const unsortedRootGroups = allGroups.filter((g) => !g.parentId && !isGroupEmpty(g, allGroups, ruleInvocations));
  const rootGroups = sortBy(unsortedRootGroups, (g) => g.position);
  const firstMMSGroupId = rootGroups.find((g) => isGroupOnlyAboutRecords(g, allGroups, ruleInvocations, mmsRecordIds))
    ?.id;
  const firstGroupId = rootGroups[0]?.id;

  useEffect(() => {
    setExpandedIds({});
  }, [expandedByDefault]);

  const renderListItem = (group: IRuleInvocationGroup) => {
    const groupIsExpanded = expandedIds[`${group.id}`] ?? expandedByDefault;
    const groupIcon = iconForGroup(group, allGroups, ruleInvocations);

    return (
      <ListItem aria-expanded={groupIsExpanded ? "true" : "false"} button onClick={makeExpansionHandler(group.id)}>
        <ListItemText
          primary={
            <Aligned style={styles.expansionGroupText}>
              {groupIcon} &nbsp; {group.label}
            </Aligned>
          }
        />
        {groupIsExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
    );
  };

  const renderGroupsHelper = (group: IRuleInvocationGroup, level: number): JSX.Element | null => {
    const groupInvocations = ruleInvocations.filter((cr) => cr.groupId === group.id);
    const subGroups = allGroups.filter((g) => g.parentId === group.id && !isGroupEmpty(g, allGroups, ruleInvocations));

    const children = sortBy([...groupInvocations, ...subGroups], (e) => e.position || 0);
    const nChildren = groupInvocations.length + subGroups.length;

    const groupIsExpanded = expandedIds[`${group.id}`] ?? expandedByDefault;

    if (nChildren === 0) {
      return null;
    }

    return (
      <React.Fragment key={group.id}>
        {group.id === firstMMSGroupId ? (
          <Hint hintType={HintToolTip.MMS_RULES} hideHint={firstMMSGroupId !== group.id} placement="left">
            {renderListItem(group)}
          </Hint>
        ) : (
          <Hint
            hintType={HintToolTip.RULE_PROGRESS}
            hideHint={!planChecklistRuleInvocations || group.id !== firstGroupId}
            placement="left-start"
          >
            {renderListItem(group)}
          </Hint>
        )}
        <Collapse in={groupIsExpanded} timeout="auto" unmountOnExit>
          <OperatorHeader operator={group.operator} numOperands={nChildren} level={level} />

          <InvocationsListContainer>
            {children.map((c, i) => {
              const key = isRuleInvocation(c) ? `${c.ruleId}-${c.recordId}-${i}` : `${group.id}-${i}`;
              return (
                <React.Fragment key={key}>
                  {isRuleInvocation(c) ? (
                    <InvocationListItem
                      expandedIds={expandedIds}
                      makeExpansionHandler={makeExpansionHandler}
                      invocation={c}
                      expandedByDefault={expandedByDefault}
                      onHighlightOpen={props.onHighlightOpen}
                      assignAs={c.matchingDetails?.breadthDisciplineScope ?? undefined}
                    />
                  ) : (
                    <div style={{ marginLeft: `${1}rem` }}>{renderGroupsHelper(c, level + 1)}</div>
                  )}
                  {i < nChildren - 1 && <OperatorLink operator={group.operator} level={level} index={i} />}
                </React.Fragment>
              );
            })}
          </InvocationsListContainer>
        </Collapse>
      </React.Fragment>
    );
  };

  // Compute where the dividers should be
  const firstDiplomaIndex = rootGroups.findIndex((g) =>
    secondaryCourses.find((sc) =>
      isGroupOnlyAboutRecords(g, allGroups, ruleInvocations, [sc.courseRecordId, ...sc.mmsRecordIds]),
    ),
  );
  const isTotalConstrainForMultipleCourses =
    rootGroups?.length >= 0 && rootGroups[0]?.id === "LogicConstruct_TotalCoursePoints";

  return (
    <List component="nav" sx={styles.root}>
      {firstDiplomaIndex >= 1 ? (
        <>
          {rootGroups.slice(0, 1).map((g) => renderGroupsHelper(g, 0))}
          {isTotalConstrainForMultipleCourses && <Divider />}
          {rootGroups.slice(1, firstDiplomaIndex).map((g) => renderGroupsHelper(g, 0))}
          {isTotalConstrainForMultipleCourses && <Divider />}
          {rootGroups.slice(firstDiplomaIndex).map((g) => renderGroupsHelper(g, 0))}
        </>
      ) : (
        rootGroups.map((g) => renderGroupsHelper(g, 0))
      )}
    </List>
  );
};
