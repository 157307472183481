import { fulfilled, failed } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import {
  CREATE_ENROLLMENT,
  GET_ENROLLMENT,
  ADD_YEAR,
  REMOVE_YEAR,
  FLIP_STUDY_PERIOD,
  CHANGE_MMS_ENROLLMENT,
  ASSIGN_SUBJECT,
  REMOVE_SUBJECT,
  CHANGE_SUBJECT_TYPE_ASSIGNMENT,
} from "actions/enrollment";
import { MOVE_OPTIMISTICALLY } from "actions/optimisticUpdate";
import { getMovedState, normaliseEnrollment } from "lib/subjectGrid";

const optimisticUpdate = (state = initialState.optimisticUpdate, action: any) => {
  switch (action.type) {
    case fulfilled(CREATE_ENROLLMENT):
    case failed(CREATE_ENROLLMENT):
    case fulfilled(GET_ENROLLMENT):
    case failed(GET_ENROLLMENT):
    case fulfilled(ASSIGN_SUBJECT):
    case failed(ASSIGN_SUBJECT):
    case fulfilled(REMOVE_SUBJECT):
    case failed(REMOVE_SUBJECT):
    case fulfilled(CHANGE_MMS_ENROLLMENT):
    case failed(CHANGE_MMS_ENROLLMENT):
    case fulfilled(CHANGE_SUBJECT_TYPE_ASSIGNMENT):
    case failed(CHANGE_SUBJECT_TYPE_ASSIGNMENT):
    case fulfilled(ADD_YEAR):
    case failed(ADD_YEAR):
    case fulfilled(REMOVE_YEAR):
    case failed(REMOVE_YEAR):
    case fulfilled(FLIP_STUDY_PERIOD):
    case failed(FLIP_STUDY_PERIOD):
      return null;
    case MOVE_OPTIMISTICALLY:
      const { enrollment, from, to } = action.payload;
      const optimisticEnrollment = normaliseEnrollment({
        ...enrollment,
        plan: { ...enrollment?.plan, studyPeriods: getMovedState(from, to, enrollment?.plan?.studyPeriods || []) },
      });
      return { plan: optimisticEnrollment?.plan };
    default:
      return state;
  }
};

export default optimisticUpdate;
