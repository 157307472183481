import initialState from "store/initialState";
import { OPEN_SUBJECT_CHECKLIST } from "actions/checklistDrawerForSubjectId";

const checklistDrawerForSubjectId = (state = initialState.checklistDrawerForSubjectId, action: any) => {
  switch (action.type) {
    case OPEN_SUBJECT_CHECKLIST:
      return action.payload;
    default:
      return state;
  }
};

export default checklistDrawerForSubjectId;
