import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { Drawer, Grid, Hidden, InputAdornment, Switch, TextField } from "@mui/material";
import {
  BreadthDisciplineScope,
  COURSE_GRID_SEMESTERS,
  IState,
  ISubject,
  ISubjectQuery,
  SubjectTag,
} from "store/types";
import max from "lodash/max";
import { useDispatch, useSelector } from "react-redux";
import identity from "lodash/identity";
import Spinner from "components/spinner";
import { CheckboxControlLabel, MultiSelectField, PaginationContainer, SubjectSearchResults } from "./common";
import { InitialSearchQuery, Level } from "lib/searchConstants";
import { AutocompleteMultiSelect } from "./AutocompleteMultiSelect";
import {
  SearchDrawerPaddedContainer,
  SearchDrawerResultsContainer,
  SearchDrawerResultsHeaderContainer,
  useSearchDrawerStyles,
  useSearchStyles,
} from "theme/styles";
import { useSearch, useSearchPagination } from "./hooks";
import { SubjectResultsHeader } from "./SubjectResultsHeader";
import { Pagination, useTheme } from "@mui/material";
import { ChecklistSubjectCard } from "containers/slotCard/checklistSubjectCard";
import { flipSearchDrawer } from "actions/searchDrawerOpen";
import { getSubjectRecordIds } from "lib/plan";
import { SubjectDetailsModal } from "containers/slotCard/SubjectDetailsModal";
import { useAssignedCourseRecordIds, useAssignedCourses } from "lib/assignment";
import { isGraduateCourse } from "lib/course";

const currentYear = new Date().getFullYear();

export function SubjectSearchDrawer() {
  const theme = useTheme();
  const styles = useSearchStyles(theme);
  const searchDrawerClasses = useSearchDrawerStyles();
  const assignedCourseIds = useAssignedCourseRecordIds();

  // What we need from the Redux state
  const { courseAreasOfStudy, plan, course } = useSelector((state: IState) => state.enrollment!);
  const isUndergrad = !isGraduateCourse(course);
  const assignedCourses = useAssignedCourses();
  const planSubjectRecordIds = getSubjectRecordIds(plan);

  const areasOfStudy = useMemo(() => courseAreasOfStudy.map((a) => a.area), [courseAreasOfStudy]);
  const getAreaDescription = useCallback(
    (area: string) => courseAreasOfStudy.find((aos) => aos.area === area)?.description || area,
    [courseAreasOfStudy],
  );

  const dispatch = useDispatch();
  const closeDrawer = useCallback(() => dispatch(flipSearchDrawer()), [dispatch]);

  // Details modal - target subject
  const [detailedSubjectId, setDetailedSubjectId] = useState<string | null>(null);
  const onShowDetails = useCallback((id: string) => () => setDetailedSubjectId(id), [setDetailedSubjectId]);
  const onCloseDetails = useCallback(() => setDetailedSubjectId(null), [setDetailedSubjectId]);

  const searchResults = useSelector((state: IState) => state.subjectSearch.results);
  const { enablePagination, numPages } = useSearchPagination();
  const originalResults = (searchResults || []).filter((s: ISubject) => !s?.discoveryResult);
  const discoveryResults = (searchResults || []).filter((s: ISubject) => s?.discoveryResult);
  const userIsDragging = useSelector((s: IState) => !!s.draggedSubjectId);

  const open = useSelector((state: IState) => state.searchDrawerOpen);

  // Order results, so discovery results come last
  const subjectResults = [...originalResults, ...discoveryResults];

  // Default search
  const initialQuery: ISubjectQuery = useMemo(
    () => ({
      ...InitialSearchQuery,
      courseRecordIds: assignedCourseIds,
      sort: {
        property: "code",
        direction: "ASC",
      },
      pageSize: 20,
    }),
    [assignedCourseIds],
  );

  const latestCourseYear =
    useSelector((state: IState) => max(state.enrollment?.course.allowedStartYears || [])) || currentYear;

  const {
    query,
    searchYear,
    updateQuery,
    updateSort,
    onSearchTextChange,
    searching,
    toggleBreadth,
    toggleDiscipline,
    updatePage,
    refreshSearch,
  } = useSearch(latestCourseYear, initialQuery);

  const subjectCourseRecordIds = query.courseRecordIds.length === 1 ? query.courseRecordIds : [];

  // Redo the search
  useEffect(() => {
    if (open) {
      refreshSearch();
    }
  }, [open, refreshSearch]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={closeDrawer}
        PaperProps={{
          sx: searchDrawerClasses.searchDrawerPaper,
        }}
        ModalProps={{
          disableEscapeKeyDown: userIsDragging,
        }}
        keepMounted
      >
        <AppBar sx={styles.appBar}>
          <Toolbar sx={searchDrawerClasses.toolbar}>
            <TextField
              sx={styles.textField}
              autoFocus={true}
              fullWidth={true}
              onChange={onSearchTextChange}
              placeholder="Search Subjects"
              variant="outlined"
              InputLabelProps={{ sx: styles.label }}
              inputProps={{ "aria-label": "Search Subjects" }}
              InputProps={{
                sx: styles.textFieldInputRoot,
                startAdornment: (
                  <InputAdornment position="start" sx={styles.adornment}>
                    <SearchOutlined sx={styles.icon} />
                  </InputAdornment>
                ),
              }}
            />
            <Button color="inherit" aria-label="Close">
              <CloseIcon sx={styles.icon} onClick={closeDrawer} />
            </Button>
          </Toolbar>
        </AppBar>

        <SearchDrawerPaddedContainer>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} xl={4}>
              <MultiSelectField
                id="level-select-in-search-drawer"
                name="level"
                label="Level"
                formValue={query.level}
                allValues={Level}
                placeholder="Select levels"
                formatLabel={(l: string) => `Level ${l}`}
                onChange={updateQuery("level")}
                renderValue={(n) => `${n} levels selected`}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <MultiSelectField
                id="studyPeriod-select-in-search-drawer"
                name="studyPeriod"
                label="Study Period"
                formValue={query.studyPeriod}
                allValues={COURSE_GRID_SEMESTERS}
                placeholder="Select study period"
                formatLabel={identity}
                onChange={updateQuery("studyPeriod")}
                renderValue={(n) => `${n} study terms selected`}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={4}>
              <AutocompleteMultiSelect
                id="area-of-study-list-in-search-drawer"
                label="Area of Study"
                options={areasOfStudy}
                formValue={query.areaOfStudy}
                formatLabel={getAreaDescription}
                onChange={updateQuery("areaOfStudy")}
                placeholder="Area of Study"
              />
            </Grid>

            {assignedCourses.length > 1 && (
              <Grid item xs={12} sm={6} xl={12}>
                <AutocompleteMultiSelect
                  id="assigned-courses-list"
                  label="Course"
                  options={assignedCourseIds}
                  formValue={query.courseRecordIds}
                  formatLabel={(v) => assignedCourses.find((c) => c.recordId === v)?.name ?? "N/A"}
                  onChange={updateQuery("courseRecordIds")}
                  placeholder={query.courseRecordIds.length > 0 ? "" : "Courses"}
                />
              </Grid>
            )}

            {assignedCourses.length <= 1 && (
              <Hidden xlDown xlUp>
                <Grid item sm={6}>
                  <div />
                </Grid>
              </Hidden>
            )}

            {isUndergrad && (
              <Grid item xs={12} sm={6} md={6}>
                <CheckboxControlLabel
                  labelPlacement="end"
                  control={
                    <Switch value={query.onlyBreadth} onChange={toggleBreadth} type="checkbox" color="primary" />
                  }
                  label="Breadth Subjects?"
                />
              </Grid>
            )}
            {isUndergrad && (
              <Grid item xs={12} sm={12} md={6}>
                <CheckboxControlLabel
                  labelPlacement="end"
                  control={
                    <Switch
                      value={query.onlyDiscipline}
                      checked={query.onlyDiscipline}
                      onChange={toggleDiscipline}
                      type="checkbox"
                      color="primary"
                    />
                  }
                  label="Discipline Subjects?"
                />
              </Grid>
            )}
          </Grid>
        </SearchDrawerPaddedContainer>

        <SearchDrawerResultsContainer>
          {!searching && (
            <SearchDrawerResultsHeaderContainer>
              <SubjectResultsHeader query={query} searchYear={searchYear} sortSubjects={updateSort} />
            </SearchDrawerResultsHeaderContainer>
          )}

          <SubjectSearchResults>
            <Spinner loading={searching} />
            {!searching && (
              <Grid container>
                {subjectResults.map((s) => {
                  const subjectAreasTitle = s.areaOfStudy.map((aos) => aos.description).join(",");
                  const isOnPlan = planSubjectRecordIds.indexOf(s.recordId) >= 0;
                  const title = isOnPlan ? `${subjectAreasTitle} (Already In Plan)` : subjectAreasTitle;
                  return (
                    <Grid item key={s.id} xs={12}>
                      <ChecklistSubjectCard
                        key={s.recordId}
                        title={title}
                        subject={s}
                        dndToken={`drawer-search-result-subject`}
                        subjectTag={
                          isOnPlan ? SubjectTag.ON_PLAN : s.discoveryResult ? SubjectTag.DISCOVERY_RESULT : undefined
                        }
                        courseRecordIds={subjectCourseRecordIds}
                        assignAs={query.onlyBreadth ? BreadthDisciplineScope.Breadth : undefined}
                        showDetails={onShowDetails(s.recordId)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </SubjectSearchResults>
          {(enablePagination || searching) && (
            <PaginationContainer>
              <Pagination
                count={numPages}
                page={query.page + 1}
                onChange={updatePage}
                color="primary"
                showFirstButton={numPages > 3}
                showLastButton={numPages > 3}
              />
            </PaginationContainer>
          )}
        </SearchDrawerResultsContainer>
      </Drawer>
      {detailedSubjectId && (
        <SubjectDetailsModal forYear={plan.year} subjectRecordId={detailedSubjectId} close={onCloseDetails} />
      )}
    </>
  );
}
