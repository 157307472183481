import React, { useCallback } from "react";
import { Drawer, Typography, useTheme } from "@mui/material";
import { IState, SubjectTag } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { closeSimilarSubjects } from "actions/similarSubjectsSearch";
import { getSubjectRecordIds } from "lib/plan";
import { ChecklistSubjectCard } from "./checklistSubjectCard";
import { commonStyles } from "theme/styles";

export const Title = styled.strong`
  margin-top: 0;
  margin-bottom: 1.5rem;
`;

export function SimilarSubjectsDrawer() {
  // What we need from the Redux state
  // const courseCode = useSelector((s: IState) => s.enrollment?.course.code);
  const similarSubjectsSearch = useSelector((s: IState) => s.similarSubjectsSearch);
  const userIsDragging = useSelector((s: IState) => !!s.draggedSubjectId);
  const subjectRecordId = similarSubjectsSearch?.subjectRecordId;
  const { plan, subjects } = useSelector((state: IState) => state.enrollment!);
  const planSubjectRecordIds = getSubjectRecordIds(plan);
  const similarSubjects = (similarSubjectsSearch?.results || []).filter(
    (s) => planSubjectRecordIds.indexOf(s.recordId) < 0,
  );

  const planId = plan?.id;

  const subject = (subjects || []).find((s) => s.recordId === subjectRecordId);

  const theme = useTheme();
  const styles = commonStyles(theme);

  const dispatch = useDispatch();
  const closeDrawer = useCallback(() => {
    dispatch(closeSimilarSubjects());
  }, [dispatch]);

  const open = !!subjectRecordId && !!planId; //!!planId && !!subject;
  // planSubjectRecordIds.indexOf(s.recordId) >= 0

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={closeDrawer}
      PaperProps={{
        sx: styles.drawerPaper,
      }}
      ModalProps={{
        disableEscapeKeyDown: userIsDragging,
      }}
      keepMounted
    >
      <Typography variant="h6" variantMapping={{ h6: "h4" }}>
        Similar subjects for &quot;{subject?.name}&quot;
      </Typography>
      {similarSubjects.length > 0 ? (
        similarSubjects.map((s, _i) => (
          <ChecklistSubjectCard
            key={s.recordId}
            subject={s}
            dndToken={`${s.recordId}-discovery-search`}
            subjectTag={SubjectTag.DISCOVERY_RESULT}
          />
        ))
      ) : (
        <Typography variant="body1" style={{ marginTop: 25 }}>
          No new results found
        </Typography>
      )}
    </Drawer>
  );
}
