import React from "react";

import { Operator } from "store/types";
import { Typography } from "@mui/material";

const headers: Record<Operator, string> = {
  [Operator.NOT]: "Not:",
  [Operator.OR]: "Either:",
  [Operator.XOR]: "One of:",
  [Operator.AND]: "",
  [Operator.IF]: "If",
};

const linkWords: Record<Operator, (n?: number) => string> = {
  [Operator.NOT]: () => "",
  [Operator.OR]: () => "Or",
  [Operator.XOR]: () => "Or",
  [Operator.AND]: () => "",
  [Operator.IF]: (n) => (n === 0 ? "Then" : ""),
};

interface IOperatorProps {
  operator?: Operator | null;
  level: number;
}

interface IOperatorHeaderProps extends IOperatorProps {
  numOperands: number;
}

interface IOperatorLinkProps extends IOperatorProps {
  index: number;
}

const useStyles = () => ({
  operator: {
    fontWeight: "bold",
    marginLeft: "1rem",
    fontSize: "90%",
  },
});

export const OperatorHeader = ({ operator, numOperands }: IOperatorHeaderProps) => {
  const styles = useStyles();
  if (numOperands <= 1 && operator !== Operator.NOT) {
    return null;
  }
  const header = operator && headers[operator];
  return header ? <Typography sx={styles.operator}>{header}</Typography> : null;
};

export const OperatorLink = ({ operator, index }: IOperatorLinkProps) => {
  const styles = useStyles();
  const link = operator && linkWords[operator](index);
  return link ? <Typography sx={styles.operator}>{link}</Typography> : null;
};
