import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { addDiscontinuedMMS } from "../../actions/admin";
import { AddDiscontinuedMMSForm } from "./AddDiscontinuedMMSForm";

interface IProps {
  open: boolean;
  close: () => any;
}

export const AddDiscontinuedMMSDialog = ({ open, close }: IProps) => {
  const dispatch = useDispatch();
  const initialValues: any = { course: { code: "", name: "" }, discontinuedMMS: [{ code: "", name: "", year: "" }] };

  const onSubmit = useCallback(
    (values: any, helpers: FormikHelpers<any>) => {
      close();

      const recordKey = values.course.code;
      const course = { code: values.course.code, name: values.course.name };

      const submitResult = dispatch(
        addDiscontinuedMMS({
          recordKey,
          course,
          discontinuedMMS: values.discontinuedMMS,
        }),
      ) as any;
      submitResult.catch(() => helpers.setSubmitting(false));
    },
    [close, dispatch],
  );

  if (!open) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="add-discontinued-mms-dialog-title"
          aria-describedby="add-discontinued-mms-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="add-discontinued-mms-dialog-title">Add Discontinued MMS Rule</DialogTitle>
          <DialogContent>
            <AddDiscontinuedMMSForm />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="add-discontinued-mms-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
