import { Grid } from "@mui/material";
import styled from "styled-components";
import { brandBlue } from "theme/colors";
import { InputLabel, FormControlLabel } from "@mui/material";

export const FormOuterGrid = styled(Grid)`
  padding: 2.5vh 1rem 1rem 1rem;
  background-color: #f7f7f7;
  justify-content: center;
`;

export const FormHeading = styled.p`
  color: ${brandBlue.toString()};
  margin-top: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
`;
export const FormActionWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const PaddedSelectLabel = styled(InputLabel)`
  margin-bottom: 0.75rem;
`;
export const CheckboxControlLabel = styled(FormControlLabel)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  margin-left: 0;
`;
