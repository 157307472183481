import React, { useCallback, useRef } from "react";
import { BreadthDisciplineScope, ISubject, SubjectTag } from "store/types";
import { Card, CardHeader, CardContent, Chip, IconButton, useTheme } from "@mui/material";
import { cardStyles, CardSubheading, CardTitle, NameContainer } from "./cardStyles";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { makeDnDId } from "./dnd";
import { getAvailableTerms, getSubjectAssignmentLabel } from "lib/subjects";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMenuState } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { DiscoveryDisclaimer } from "containers/search/common";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { useCoursesFor } from "lib/assignment";
import { SubjectMenu } from "./menuItems/subjectMenu";

export interface IProps {
  dndToken: string;
  subject: ISubject;
  title?: string;
  mmsRecordId?: string;
  courseRecordIds?: string[];
  subjectTag?: SubjectTag;
  assignAs?: BreadthDisciplineScope;
  showDetails?: () => void;
}

export const ChecklistSubjectCard = (props: IProps) => {
  const { subject, dndToken, mmsRecordId, courseRecordIds, subjectTag, title, assignAs, showDetails } = props;
  const availabilities = getAvailableTerms(subject);

  const assignableCourses = useCoursesFor(subject.recordId);
  const defaultCourseRecordIds = [assignableCourses[0]?.recordId].filter(Boolean);
  const effectiveCourseRecordIds =
    !courseRecordIds || courseRecordIds?.length === 0 ? defaultCourseRecordIds : courseRecordIds;

  const theme = useTheme();
  const styles = cardStyles(theme, { tag: subjectTag });
  const dndId = makeDnDId(
    {
      subjectRecordId: subject.recordId,
      mmsRecordId,
      courseRecordIds: effectiveCourseRecordIds,
      assignAs,
    },
    dndToken,
  );

  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  const ref = useRef(null);
  const btnId = `checklist-menu-${subject.recordId}`.replace(/\s/g, "");

  const subjectAreasTitle = subject.areaOfStudy.map((aos) => aos.description).join(",");

  const { readOnlyPlan } = usePlanPermissions();
  const disabled = subjectTag === SubjectTag.NOT_ASSIGNABLE || subjectTag === SubjectTag.ON_PLAN || readOnlyPlan;

  return (
    <Droppable droppableId={dndId} isDropDisabled={true}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <Draggable draggableId={dndId} index={1} isDragDisabled={disabled}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                <Card sx={styles.card} elevation={2} style={{ marginBottom: "0.25rem", width: "calc(100% - 1rem)" }}>
                  <CardHeader
                    color="primary"
                    title={`${title || getSubjectAssignmentLabel(subjectTag) || subjectAreasTitle}`.trim()}
                    titleTypographyProps={{ variant: "button" }}
                    subheaderTypographyProps={{ variant: "inherit" }}
                    sx={styles.cardHeader}
                    action={
                      !disabled ? (
                        <>
                          <IconButton
                            ref={ref}
                            aria-label={`Options for core subject ${subject.name}`}
                            // aria-controls={btnId}
                            onClick={openMenu}
                            size="large"
                          >
                            <MoreVertIcon sx={styles.icon} />
                          </IconButton>
                          <SubjectMenu
                            {...props}
                            {...menuProps}
                            htmlId={btnId}
                            anchorRef={ref}
                            subjectRecordId={subject.recordId}
                            close={closeMenu}
                            assignAs={assignAs}
                          />
                        </>
                      ) : undefined
                    }
                    avatar={
                      subjectTag === SubjectTag.DISCOVERY_RESULT ? (
                        <img src="/watson-white.png" alt="IBM Watson" style={{ width: 25, height: 25 }} />
                      ) : null
                    }
                  />
                  <CardContent sx={styles.cardContent}>
                    <CardSubheading>
                      <strong>{subject.code}</strong> | Level {subject.level} | {subject.points} points
                    </CardSubheading>
                    <NameContainer>
                      <CardTitle>{subject.name}</CardTitle>
                      {showDetails && (
                        <IconButton
                          aria-label={`Show details for subject ${subject.name}`}
                          size="small"
                          color="primary"
                          onClick={showDetails}
                        >
                          <InfoIcon />
                        </IconButton>
                      )}
                    </NameContainer>
                    {availabilities.map((a) => (
                      <React.Fragment key={a}>
                        <Chip sx={styles.cardChip} label={a} size="small" /> &nbsp;
                      </React.Fragment>
                    ))}
                    {subjectTag === SubjectTag.DISCOVERY_RESULT && <DiscoveryDisclaimer />}
                  </CardContent>
                </Card>
              </div>
            )}
          </Draggable>
          {/* {provided.placeholder} */}
        </div>
      )}
    </Droppable>
  );
};
