import { useLocation } from "@reach/router";
import React from "react";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { AuthContainer } from "./AuthForm";
import { Templates } from "./Templates";

interface IProps {
  path?: string;
  id?: string;
  children?: any[] | null;
}

export const AdminContainer = ({ children }: IProps) => {
  const admin = useSelector((state: IState) => state.admin);
  const location = useLocation();

  if (!admin) {
    return <AuthContainer />;
  }
  if (location.pathname === "/staff" || location.pathname === "/staff/") {
    return <Templates />;
  }
  return <>{children}</>;
};
