export interface IBaseCourse {
  recordId: string;
  code: string;
  name: string;
  points?: number;
  effectiveYear?: string;
  allowedStartYears: number[];
  migratedYears: number[];
  qualificationType: Array<IQualificationType>;
}

export enum YearIntake {
  START_YEAR = "Start Year",
  MID_YEAR = "Mid Year",
}

export enum StudyLevel {
  UNDERGRADUATE = "Undergraduate",
  GRADUATE = "Graduate",
}

export interface IQualificationType {
  aqfLevel: string;
  awardTitlePreFill: string;
  description: string;
  postnominalPreFill: string;
  studyLevel: StudyLevel;
  studyType: string;
}

export enum QualificationLevel {
  DIPLOMA = "5",
  BACHELOR = "7",
  MASTERS = "9",
}

export interface ICourseSummary extends IBaseCourse {
  yearIntakes: Record<string, YearIntake[]>;
}

export interface ICourse extends IBaseCourse {
  effectiveRulesYear: number;
  mmsLimits: IMMSLimits;
}

export interface ILimits {
  minimum?: number;
  maximum?: number;
}

export interface IMMSLimits {
  minimum?: number;
  maximum?: number;
  majors?: ILimits;
  minors?: ILimits;
  informalSpecs?: ILimits;
}

export interface IPlanCreationConfig {
  courses: ICourseSummary[];
  componentsForSelectedCourse?: IComponent[];
}

export interface ISelfEvalSelection {
  recordId: string;
  ruleId: string;
}

export interface IPlanTemplate {
  state: TemplateStage;
  user?: string;
}

export interface IPlan {
  id?: string;
  courseRecordId: string;
  name: string;
  notes?: string;
  year: number;
  isMidyearEntry: boolean;
  createdTS?: string;
  lastUpdatedTS?: string;
  mmsRecordIds: string[];
  studyPeriods: IStudyPeriod[];
  selfEvalSelections: ISelfEvalSelection[];
  template?: IPlanTemplate;
  secondaryCourses: ISecondaryCourse[];
  sourceTemplateId?: string;
  entry?: IEntry;
  stream?: IStream;
}

export interface IEntry {
  points?: number;
  name?: string;
  recordId?: string;
}

export interface IStream {
  name?: string;
  recordId?: string;
}

export interface ISecondaryCourse {
  courseRecordId: string;
  mmsRecordIds: string[];
}

export interface ISlot {
  id?: string;
  sequence: number;
  subjectRecordId?: string;
  tags: ITag[];
  mmsRecordId?: string;
  disabled?: boolean;
  displayPoints: number;
  courseRecordIds: string[];
}

export interface ITag {
  name: string;
  label: string;
}
export interface IAttribute {
  name: string;
  value: string;
}

export enum Semester {
  SEM1 = "Semester 1",
  SEM2 = "Semester 2",
  SUMMER = "Summer Term",
  WINTER = "Winter Term",
  ADVANCED_STANDING = "Advanced Standing",
}
export const COURSE_GRID_SEMESTERS = Object.values(Semester).filter((s) => s !== Semester.ADVANCED_STANDING);

export interface IStudyPeriod {
  id?: string;
  type: Semester;
  year: number;
  slots: ISlot[];
}

export enum ComponentType {
  MAJOR = "Major",
  MINOR = "Minor",
  INFORMAL_SPEC = "Informal specialisation",
  ENTRY = "Course Entry Point",
  STREAM = "Stream",
}

export enum CertificationType {
  Degree = "Degree",
  Diploma = "Diploma",
}

export interface IComponent {
  id: string;
  rules: any[];
  recordId: string;
  version: string;
  code: string;
  name: string;
  notes?: string;
  type: ComponentType;
  points: number;
  overview: string;
  owningOrg: string;
  publishedYears: string;
  lastUpdateTS: string;
  parentCourse: {
    code: string;
    name: string;
    owningOrg: string;
    points: string;
    recordId: string;
    versionId: string;
  };
  courseParentRecordId: string;
  parent: {
    recordId: string;
  };
  parentRecordId: string;
  children?: any[]; //
}

export interface ISecondaryCourseOption extends IBaseCourse {
  allowedComponents: IComponent[];
}

export interface IOverload {
  type: Semester;
  year: number;
  pointsOverload: number;
}

// Data relating to the selected preferences
// E.g. viable MMS for the course, etc
export interface IEnrollment {
  plan: IPlan;
  course: ICourse;
  allowedYears: number[];
  allowedComponents: IComponent[];
  allowedSecondaryCourses?: ISecondaryCourseOption[];
  subjects: ISubject[];
  validation: IPlanValidation;
  currentHint?: HintToolTip | null;
  // Need this only for the tooltip/hint
  subjectSearchOpen?: boolean | null;
  referredSubjects?: ISubject[];
  assignableCore: string[];
  courseAreasOfStudy: IAreaOfStudy[];
  matchingTemplates: ITemplateSummary[];
  overloads: IOverload[];
  isNewCreation?: boolean;
}

export interface ISnackBarState {
  type: "error" | "success";
  message: string;
  autoHideDuration: number;
}

export interface ISubjectQuerySort {
  property: keyof ISubject;
  direction: "ASC" | "DESC";
}
export interface ISubjectQuery {
  text: string;
  level: string[];
  studyPeriod: Semester[];
  areaOfStudy: string[];
  courseRecordIds: string[];
  onlyBreadth?: boolean;
  onlyDiscipline?: boolean;
  points?: number[];
  page: number;
  pageSize: number;
  sort?: ISubjectQuerySort | null;
  ruleSearch?: {
    recordType: "course" | "component" | "subject";
    ruleId: string;
    ruleRecordId: string;
  };
}

export interface ISubjectSearch {
  searchInProgress: boolean;
  searchTimestamp: number;
  query?: ISubjectQuery;
  searchYear?: number;
  results: ISubject[];
  // We've loaded a few subjects - how many are there left out
  totalCount: number;
}

export interface ISimilarSubjectSearch {
  searchInProgress: boolean;
  searchTimestamp: number;
  subjectRecordId?: string | null;
  results: ISubject[];
}

export interface IContact {
  type: string;
  name: string | any[];
  contactDetails: string;
}
export interface IAvailability {
  campus: string;
  studyPeriod: string;
  year: string;
  periodStartDate: string;
  periodEndDate: string;
  lastEnrolDate: string;
  withdrawWithoutFailDate: string;
  censusDate: string;
  modeOfDelivery: string;
  assessmentPeriodEndDate: string;
  totalTimeCommittment: string;
  contactHours: string;
  contacts: IContact[];
}
interface IAreaOfStudy {
  area: string;
  description: string;
}
export interface ISubjectAssessment {
  description: string;
  percentage: number;
  hurdle: boolean;
  hurdleRequirementDescription: string;
  quantity: string;
  timing: string;
  order: number;
  type: string;
  subjectAvailability: string;
}
export interface ISubject {
  rules: any;
  id: string;
  recordId: string;
  effectiveYear: string;
  version: string;
  areaOfStudy: IAreaOfStudy[];
  subjectAssessment: ISubjectAssessment[];
  assessment: string;
  availability: IAvailability[];
  points: number;
  overview: string;
  hasQuota: boolean;
  owningOrg: string;
  publishedYears: string;
  code: string;
  name: string;
  level: string;
  lastUpdateTS: string;
  availableAsDiscipline: ICourseReference[];
  availableAsBreadth: ICourseReference[];
  discoveryResult: boolean;
}

export interface ICourseReference {
  name: string;
  code: string;
  year?: string;
}

export enum Operator {
  OR = "OR",
  XOR = "XOR",
  AND = "AND",
  NOT = "NOT",
  IF = "IF",
}

export interface IRuleInvocationGroup {
  id: string;
  label: string;
  position: number;
  parentId: null | string;
  operator?: Operator;
  result?: boolean;
}

export interface IProgress {
  minimum: number | null;
  maximum: number | null;
  currentCount: number;
}

export enum BreadthDisciplineScope {
  Breadth = "Only Breadth",
  Discipline = "Only Discipline",
  Breadth_and_Discipline = "Both Breadth and Discipline",
}

export interface IRuleInvocation {
  // Unique identifier of the Rule that was invoked
  ruleId: string;
  // Type of Rule that was invoked, e.g. "C1" - Points Constraint
  ruleType: string;
  // Unique identifier of the component this plan was sourced from, i.e. Course, Component, Subject
  recordId: string;
  // Result of the invocation of the Rule: true or false
  result: boolean;
  // The generated text version of the rule eg "You must do 12.5 points of Science"
  text: string;
  section: string;
  // The progress for PointsConstraint, CountConstraint, CountConstraintMMS and Total Course Points rules
  progress?: IProgress;

  matchingDetails?: {
    // only for PointConstraint, CountConstraint
    breadthDisciplineScope: BreadthDisciplineScope | null;
    recordIds: string[];
  };
  position: number;
  groupId: string | null;
  coreSubjectRecordIds?: string[];

  isHtml: boolean;
  referredSubjectCodes: string[];
  selfEvaluated?: boolean;
}

export enum SubjectTag {
  NOT_ASSIGNABLE = "not_assigned",
  CORE = "core",
  BREADTH = "breadth",
  ASSIGNED = "assigned",
  ASSIGNED_2 = "assigned2",
  SECONDARY_COURSE = "secondary",
  DISCIPLINE = "discipline1",
  HIDDEN_MMS = "hidden_mms",
  CAN_BE_DISCIPLINE = "canBeDiscipline",
  DISCOVERY_RESULT = "discoveryResult",
  ON_PLAN = "alreadyOnPlan",
}

export enum SubjectFocusState {
  NO_HIGHLIGHT = "no_highlight",
  HIGHLIGHTED = "highlighted",
  DIMMED = "dimmed",
  TARGET = "target",
}

export type SubjectTags = {
  [tag in SubjectTag]: string[];
};

export interface IPlanValidation {
  // Unique identifier of this PlanValidation resource
  id: string;
  // Unique identifier of the plan resource to validate
  planId: string;
  // Total count of rules
  count: number;
  // Count of failing rules
  failed: number;
  // Count of passing rules
  passed: number;
  invocations: IRuleInvocation[];
  groups: IRuleInvocationGroup[];
  mmsAssignableSubjects?: Record<string, string[]>;
}

export interface ISlotIdentifier {
  year: number;
  semester: Semester;
  slotIndex: number;
}

interface IDnDIdFullObject extends ISlotIdentifier {
  subjectRecordId: string;
  mmsRecordId: string;
  courseRecordIds?: string[];
  assignAs?: BreadthDisciplineScope;
}

export interface ISubjectSearchDrawerFor {
  ruleId: string;
  recordId: string;
}

export type DnDId = Partial<IDnDIdFullObject>;

export interface IExpansionState {
  [id: string]: boolean;
}

export interface IOptimisticUpdate {
  plan: Partial<IPlan> | null;
}

export interface ITemplateSummary {
  id: string;
  name: string;
  notes: string;
  year: number;
  lastUpdatedTS: string;
  isMidyearEntry: boolean;
  courseRecordId: string;
  mmsRecordIds: string[];
  template: {
    user: string;
    state: TemplateStage;
  };
}

export interface IAdmin {
  user: string;
  password: string;
  role?: RoleType;
  authorisedCourseCodes?: Array<string>;
  templateSummaries: ITemplateSummary[];
  compEligibilities: Array<any>;
  discontinuedRecords: Array<any>;
  courses: ICourse[];
  components: IComponent[];
}

export enum RoleType {
  READ = "read",
  WRITE = "write",
  FULL = "full",
}

export interface IState {
  loading: boolean;
  submittingChanges: boolean;
  planCreationConfig: null | IPlanCreationConfig;
  enrollment: null | IEnrollment;
  snackBarState: ISnackBarState | null;
  subjectSearch: ISubjectSearch;
  optimisticUpdate: null | Partial<IOptimisticUpdate>;
  checklistDrawerForSubjectId?: string | null;
  subjectDrawerForRule?: ISubjectSearchDrawerFor | null;
  searchDrawerOpen: boolean;
  similarSubjectsSearch?: ISimilarSubjectSearch;
  draggedSubjectId?: string | null;
  admin?: IAdmin | null;
  focusInvocationId?: string | null;
}

export enum TemplateStage {
  DRAFT = "draft",
  PUBLISHED = "published",
  ARCHIVED = "archived",
}

export enum UserMode {
  STUDENT = "STUDENT",
  ADMIN = "ADMIN",
}

export enum PlanAction {
  ADD_YEAR = "ADD_YEAR",
  REMOVE_YEAR = "REMOVE_YEAR",
  ADD_SUMMER_TERM = "ADD_SUMMER_TERM",
  ADD_WINTER_TERM = "ADD_WINTER_TERM",
}

export enum HintToolTip {
  ADD_MMS = "ADD_MMS",
  ADD_NON_STANDARD_SUBJECT = "ADD_NON_STANDARD_SUBJECT",
  ADD_CREDIT_ADVANCED_STANDING = "ADD_CREDIT_ADVANCED_STANDING",
  B_DES_MMS_WARNING = "B_DES_MMS_WARNING",
  ADD_SUBJECT = "ADD_SUBJECT",
  RULE_PROGRESS = "RULE_PROGRESS",
  MMS_RULES = "MMS_RULES",
  SUBJECT_FINDER = "SUBJECT_FINDER",
  SUBJECT_DETAILS = "SUBJECT_DETAILS",
  SUBJECT_PREREQ = "SUBJECT_PREREQ",
  COMPLETION = "COMPLETION",
  SAMPLE_COURSE_PLANS = "SAMPLE_COURSE_PLANS",
}

export enum PlanYears {
  MIN = 3,
  MIDYEAR_ENTRY_MIN = 4,
  MAX = 8,
}
export enum RuleTemplateType {
  TotalCoursePoints = "Total Course Points",
  CrossCreditCoursePoints = "Cross Credit Course Points",
  PointsConstraint = "Points Constraint",
  CountConstraint = "Count Constraint",
  CountConstraintMMS = "Count Constraint MMS",
  Information = "Information",
  Availability = "Availability",
  Progression = "Progression",
}
