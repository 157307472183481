import { v4 as uuidv4 } from "uuid";

const LOCAL_STORAGE_ID = "UOM-CPT-USER-ID";

export const getUserId = () => {
  const currentId = localStorage.getItem(LOCAL_STORAGE_ID);
  if (!currentId) {
    const newUUID = uuidv4();
    localStorage.setItem(LOCAL_STORAGE_ID, uuidv4());
    return newUUID;
  }
  return currentId;
};
