import React from "react";
import { useSelector } from "react-redux";
import { IState } from "store/types";
import { getSubjectStudyPeriod } from "lib/plan";
import { Banner } from "../../components/banner";
import min from "lodash/min";
import { getSubject } from "lib/subjects";

interface IProps {
  subjectRecordId?: string;
}
export const SubjectChecklistWarning = ({ subjectRecordId }: IProps) => {
  const { plan, subjects, course } = useSelector((state: IState) => state.enrollment!);
  const subject = getSubject(subjects, subjectRecordId);
  const sp = getSubjectStudyPeriod(plan, subjectRecordId);

  // If subject is not on plan - nothing to do
  if (!sp || !subject || !plan) {
    return null;
  }

  // Is the subject from a migrated course year?
  const firstMigratedYear = min(course.migratedYears || []);
  const isSubjectFromMigratedYear = firstMigratedYear && sp?.year && firstMigratedYear <= sp.year;

  // If so - no need for warning.
  if (isSubjectFromMigratedYear) {
    return null;
  }

  return (
    <Banner
      heading="Subject may contain requisites"
      body={
        <div>
          This subject may contain requisites not stated here. Please check the{" "}
          <a href={`https://handbook.unimelb.edu.au/${sp.year}/subjects/${subject.code}`} target="_">
            Handbook entry
          </a>{" "}
          for the relevant year for full details.
        </div>
      }
    />
  );
};
