import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IProps {
  open: boolean;
  onAccept: () => any;
  onCancel: () => any;
}

export const SelfEvalDialog = React.memo(({ open, onAccept, onCancel }: IProps) => (
  <Dialog
    open={open}
    onClose={onCancel}
    aria-labelledby="self-eval-alert-dialog-title"
    aria-describedby="self-eval-alert-dialog-description"
  >
    <DialogTitle id="self-eval-alert-dialog-title">Eligibility acknowledgement</DialogTitle>
    <DialogContent>
      <DialogContentText id="self-eval-alert-dialog-description">
        I recognise this is a requirement to be met prior to undertaking this study.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant="contained">
        Cancel
      </Button>
      <Button onClick={onAccept} color="primary" variant="contained" autoFocus>
        Agree
      </Button>
    </DialogActions>
  </Dialog>
));
