import React, { useCallback, useEffect, useState } from "react";
import { Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { BreadthDisciplineScope, IExpansionState, IRuleInvocation, IState } from "store/types";
import { ExpandLess, ExpandMore, PlaylistAdd, Search, HighlightOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { ChecklistSubjectCard } from "containers/slotCard/checklistSubjectCard";
import { getSubjectRecordIdsNotInPlan } from "lib/plan";
import { assignInvocationCoreSubjects, loadReferredSubjects } from "actions/enrollment";
import { ChecklistInvocationText } from "./InvocationsText";
import { AutoAssignCoreDialog } from "components/autoAssignCore/AutoAssignCoreDialog";
import { AutoAssignmentSummaryDialog } from "components/autoAssignCore/AutoAssignmentSummaryDialog";
import { InvocationIcon } from "./InvocationIcon";
import { openSubjectDrawerForRuleId } from "actions/subjectDrawerForRule";
import { getChecklistInvocations, isCCRule, isPCRule } from "lib/invocations";
import { analyseDrawerSubject } from "lib/subjects";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { Aligned } from "components/aligned/alignedDiv";
import { flipInvocationFocus } from "actions/focusInvocationId";
import { featureToggles } from "config/featureToggles";

const useStyles = () => ({
  nested: {
    backgroundColor: "#f7f7f7",
    // paddingLeft: theme.spacing(1),
  },
  nestedListItem: {
    backgroundColor: "#f7f7f7",
    alignItems: "start",
    userSelect: "text",
  },
  nestedPrimaryText: {
    fontSize: "0.8rem",
    marginTop: "0px",
    marginBottom: "0px",
  },
  nestedIcon: {
    minWidth: "inherit",
    marginRight: "12px",
    paddingTop: "6px",
  },
});

interface IInvocationListContainerProps {
  children?: any;
}

export const InvocationsListContainer = ({ children }: IInvocationListContainerProps) => {
  const styles = useStyles();

  return (
    <List component="div" sx={styles.nested} disablePadding>
      {children}
    </List>
  );
};

interface IInvocationItemProps {
  invocation: IRuleInvocation;
  style?: React.CSSProperties;
  expandedIds: IExpansionState;
  expandedByDefault: boolean;
  assignAs?: BreadthDisciplineScope;
  makeExpansionHandler: (id: string) => () => void;
  onHighlightOpen?: () => any;
}

export const InvocationListItem = (props: IInvocationItemProps) => {
  const { invocation, style, expandedIds, makeExpansionHandler, onHighlightOpen, assignAs } = props;
  const styles = useStyles();

  const allGroups = useSelector((s: IState) => s.enrollment?.validation.groups || []);
  const checklistInvocations = useSelector((s: IState) => getChecklistInvocations(s.enrollment));
  const { readOnlyPlan } = usePlanPermissions();

  // Core subjects for this requirments
  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const courseCode = useSelector((s: IState) => s.enrollment?.course?.code);
  const planId = plan?.id;
  const planMMSRecordIds = plan?.mmsRecordIds || [];
  const secondaryCourses = plan?.secondaryCourses || [];
  const mmsRecordId = planMMSRecordIds.indexOf(invocation.recordId) >= 0 ? invocation.recordId : undefined;
  const courseRecordId =
    secondaryCourses.find(
      (sc) => sc.courseRecordId === invocation.recordId || sc.mmsRecordIds.indexOf(invocation.recordId) >= 0,
    )?.courseRecordId ??
    plan?.courseRecordId ??
    "";
  const referredSubjects = useSelector((s: IState) => s.enrollment?.referredSubjects || []);
  const missingCoreSubjectRecordIds = getSubjectRecordIdsNotInPlan(plan, invocation.coreSubjectRecordIds);
  const missingCoreSubjectRecordIdsJoined = missingCoreSubjectRecordIds.join(",");
  const missingCoreSubjects = referredSubjects.filter((s) => missingCoreSubjectRecordIds.indexOf(s.recordId) >= 0);
  const containsCoreOptions = missingCoreSubjectRecordIds.length > 0;

  const dispatch = useDispatch();

  // Focused or not
  const focusInvocationId = useSelector((s: IState) => s.focusInvocationId);
  const isInvocationFocused = !!focusInvocationId && focusInvocationId === invocation.ruleId;
  const onRuleFocusClicked = useCallback(
    (e: React.MouseEvent<any>) => {
      e.stopPropagation();
      if (onHighlightOpen) {
        onHighlightOpen();
      }
      dispatch(flipInvocationFocus(invocation.ruleId));
    },
    [dispatch, invocation.ruleId, onHighlightOpen],
  );

  // Mentioned subjects for this requirement
  const referredSubjectCodes = invocation.referredSubjectCodes;
  const mentionedSubjectCodesJoined = referredSubjectCodes.join(",");

  const enableSubjectDrawer = isCCRule(invocation) || isPCRule(invocation);

  // Handle the expansion
  const expansionId = `${invocation.ruleId}-${invocation.groupId}-${invocation.recordId}`;
  const flipExpansion = makeExpansionHandler(expansionId);
  const isExpanded = expandedIds[expansionId];
  const onSearchClick = useCallback(() => {
    if (containsCoreOptions) {
      flipExpansion();
    } else if (enableSubjectDrawer) {
      dispatch(openSubjectDrawerForRuleId({ ruleId: invocation.ruleId, recordId: invocation.recordId }));
    }
  }, [containsCoreOptions, dispatch, enableSubjectDrawer, flipExpansion, invocation.recordId, invocation.ruleId]);

  // Load the core and referred to subjects

  useEffect(() => {
    if (mentionedSubjectCodesJoined.length > 0) {
      dispatch(loadReferredSubjects([], mentionedSubjectCodesJoined.split(",")));
    }
  }, [dispatch, mentionedSubjectCodesJoined]);

  useEffect(() => {
    if (containsCoreOptions && isExpanded) {
      dispatch(loadReferredSubjects(missingCoreSubjectRecordIdsJoined.split(","), []));
    }
  }, [dispatch, missingCoreSubjectRecordIdsJoined, containsCoreOptions, isExpanded]);

  // Handle the Add button
  // Manage the state of the confirmation dialog
  const [autoAssignInvocation, setAutoAssignInvocation] = useState<IRuleInvocation | null>(null);
  const closeAutoAssignModal = useCallback(() => setAutoAssignInvocation(null), []);
  const openAutoAssignModal = useCallback((i: IRuleInvocation) => setAutoAssignInvocation(i), []);

  // Manage the state of the summary dialog
  const [autoAssignmentResult, setAutoAssignmentResult] = useState<any>(null);
  const closeAutoAssignmentSummaryModal = useCallback(() => setAutoAssignmentResult(null), []);
  const openAutoAssignmentSummaryModal = useCallback((result: any) => setAutoAssignmentResult(result), []);

  const onAddAllClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      openAutoAssignModal(invocation);
    },
    [invocation, openAutoAssignModal],
  );
  const doAutoAssign = useCallback(async () => {
    closeAutoAssignModal();
    const autoAssignAction: any = dispatch(assignInvocationCoreSubjects(planId, invocation, courseCode));
    const autoAssignmentResult = await autoAssignAction;
    // console.log(autoAssignmentResult);
    openAutoAssignmentSummaryModal(autoAssignmentResult.value.autoAsignmentResult);
  }, [closeAutoAssignModal, dispatch, invocation, openAutoAssignmentSummaryModal, planId, courseCode]);

  return (
    <>
      <AutoAssignCoreDialog open={!!autoAssignInvocation} onAccept={doAutoAssign} onCancel={closeAutoAssignModal} />
      <AutoAssignmentSummaryDialog
        open={!!autoAssignmentResult}
        result={autoAssignmentResult}
        onConfirm={closeAutoAssignmentSummaryModal}
      />
      <ListItem
        sx={styles.nestedListItem}
        button
        onClick={onSearchClick}
        style={{
          marginLeft: "1rem",
          width: "calc(100% - 1rem)",
          border: isInvocationFocused ? "1px dashed gray" : undefined,
          backgroundColor: isInvocationFocused ? "lightgray" : undefined,
          ...style,
        }}
        aria-expanded={isExpanded ? "true" : "false"}
      >
        <ListItemIcon sx={styles.nestedIcon} tabIndex={0}>
          <InvocationIcon invocation={invocation} allGroups={allGroups} checklistInvocations={checklistInvocations} />
        </ListItemIcon>
        <ListItemText
          tabIndex={0}
          primary={
            <span style={styles.nestedPrimaryText}>
              <ChecklistInvocationText
                invocation={invocation}
                mmsRecordId={mmsRecordId}
                assignAs={assignAs}
                extraDndToken="invlist"
              />
            </span>
          }
        />

        {containsCoreOptions && !readOnlyPlan && (
          <Tooltip arrow aria-label="add core subjects" title={"Add all core subjects for this rule."}>
            <IconButton aria-label="add core subjects" size="small" onClick={onAddAllClick}>
              <PlaylistAdd fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}

        {featureToggles.highlightSubjectsFromChecklist && (invocation?.matchingDetails?.recordIds ?? []).length > 0 && (
          <Aligned>
            {isInvocationFocused ? (
              <Tooltip arrow title="Cancel subject highlight" aria-label="Cancel subject highlight" placement="top">
                <IconButton onClick={onRuleFocusClicked} size="small">
                  <HighlightOff fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip arrow title="Highlight subjects" aria-label="Highlight subjects" placement="top">
                <IconButton onClick={onRuleFocusClicked} size="small">
                  <i style={{ fontSize: "inherit" }} className="material-icons">
                    push_pin
                  </i>
                </IconButton>
              </Tooltip>
            )}
          </Aligned>
        )}

        {containsCoreOptions && isExpanded && <ExpandLess />}
        {containsCoreOptions && !isExpanded && <ExpandMore />}

        {!containsCoreOptions && enableSubjectDrawer && (
          <Aligned>
            <IconButton size="small">
              <Search />
            </IconButton>
          </Aligned>
        )}
      </ListItem>
      {containsCoreOptions && (
        <Collapse in={isExpanded} style={{ marginLeft: "2.5rem" }} timeout="auto" unmountOnExit>
          {missingCoreSubjects.map((s) => {
            const meta = analyseDrawerSubject(s, invocation);
            return (
              <ChecklistSubjectCard
                key={s.recordId}
                subject={s}
                dndToken={`${invocation.groupId}-${invocation.ruleId}-core-subject`}
                mmsRecordId={mmsRecordId}
                courseRecordIds={[courseRecordId]}
                title={meta.checklistSubjectTitle}
                subjectTag={meta.checklistSubjectTag}
              />
            );
          })}
        </Collapse>
      )}
    </>
  );
};
