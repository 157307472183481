import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "@reach/router";
import { Droppable } from "react-beautiful-dnd";
import { makeDnDId } from "./dnd";
import { HintToolTip, ISlotIdentifier, IState } from "store/types";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Hint } from "containers/Hint";
import { useDispatch, useSelector } from "react-redux";
import { isFirstEmptySlotInPlan, isLastSlotInSemester } from "lib/plan";
import { getOverloadForPeriod, subjectFitsSlot } from "lib/subjectGrid";
import { getAllKnownSubjects } from "lib/subjects";
import { usePlanPermissions } from "lib/usePlanPermissions";
// import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { removeOverload } from "actions/enrollment";

const CardOuterDiv = styled.div`
  min-height: ${(props: any) => (props.isDraggingOver ? "136px" : "140px")};
  height: 100%;
  margin: 0.875rem 0;
  border-radius: 5px;
  border: dashed
    ${(props: any) =>
      props.isDropDisabled
        ? "none"
        : props.isDraggingOver
        ? "3px rgba(153, 162, 170, 0.7)"
        : "1px rgba(153, 162, 170, 0.31)"};
  background-color: ${(props: any) =>
    !props.isDropDisabled ? `rgba(172, 172, 172, 0.04)` : "rgba(172, 172, 172, 0.5)"};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
` as any;

const CardHorizontalStrip = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
`;

const CircleToLink = styled(Link)`
  display: flex;
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 1.375rem;
  font-weight: bold;
  border: 1px solid rgba(153, 162, 170, 0.31);
  color: #4a4a4a;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  i {
    display: flex;
    color: #808080;
    margin: auto 0.69rem;
  }
  &:hover,
  &:focus {
    width: 90%;
    max-width: 180px;
    color: #4a4a4a;
    border-color: #4a4a4a;
    i {
      color: #4a4a4a;
      margin: auto 0.69rem;
    }
  }
`;

const LinkContent = styled.div`
  text-decoration: none;
  color: inherit;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    justify-content: center;
  }
`;
const LinkTxt = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.6px;
`;

interface IProps extends ISlotIdentifier {
  loading?: boolean;
}

export const EmptySlotCard = React.memo((props: IProps) => {
  const { year, semester, slotIndex } = props;
  const dndId = makeDnDId(props);

  const userIsDragging = useSelector((s: IState) => !!s.draggedSubjectId);
  const { readOnlyPlan } = usePlanPermissions();

  const plan = useSelector((s: IState) => s.enrollment?.plan);
  const overloads = useSelector((s: IState) => s.enrollment?.overloads ?? []);
  const overload = getOverloadForPeriod({ type: props.semester, year: props.year }, overloads);
  const lastInSemester = isLastSlotInSemester(plan, props);
  const coursePoints = useSelector((s: IState) => s.enrollment?.course?.points);
  const isFirst = isFirstEmptySlotInPlan(plan, props);

  const draggedSubjectId = useSelector((s: IState) => s.draggedSubjectId);
  const subjects = useSelector((s: IState) => getAllKnownSubjects(s));
  // const draggedSubject = subjects.find((s) => s.recordId === draggedSubjectId);
  const isDropDisabled =
    !!draggedSubjectId && !subjectFitsSlot(props, draggedSubjectId, subjects, plan, coursePoints, overloads);

  const dispatch = useDispatch();
  const doRemoveOverload = useCallback(
    () => dispatch(removeOverload({ type: props.semester, year: props.year })),
    [props.semester, props.year, dispatch],
  );

  return (
    <Droppable droppableId={dndId} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <CardOuterDiv
          ref={provided.innerRef}
          {...provided.droppableProps}
          isDraggingOver={snapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
        >
          {overload > 0 && lastInSemester && (
            <Tooltip arrow placement="top" title="Remove overload">
              <IconButton
                size="small"
                style={{ position: "absolute", top: "6px", right: "6px" }}
                aria-label="remove overload"
                onClick={doRemoveOverload}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {!snapshot.isUsingPlaceholder && (
            <Hint hintType={HintToolTip.ADD_SUBJECT} hideHint={!isFirst} placement="right">
              <CardHorizontalStrip>
                {props.loading && <CircularProgress color="secondary" size={50} thickness={1} />}
                {!props.loading && isDropDisabled && !readOnlyPlan && (
                  <i aria-hidden="true" className="material-icons" style={{ fontSize: "2.5rem" }}>
                    close
                  </i>
                )}
                {!props.loading && !isDropDisabled && !readOnlyPlan && (
                  <CircleToLink to={userIsDragging ? "" : `search/year/${year}/semester/${semester}/slot/${slotIndex}`}>
                    <LinkContent>
                      <i aria-hidden="true" className="material-icons">
                        search
                      </i>
                      <LinkTxt aria-label="Add a subject">ADD A SUBJECT</LinkTxt>
                    </LinkContent>
                  </CircleToLink>
                )}
              </CardHorizontalStrip>
            </Hint>
          )}
          {/* {provided.placeholder} */}
        </CardOuterDiv>
      )}
    </Droppable>
  );
});
