import initialState from "store/initialState";
import { FLIP_SEARCH_DRAWER } from "actions/searchDrawerOpen";

const searchDrawerOpen = (state = initialState.searchDrawerOpen, action: any) => {
  switch (action.type) {
    case FLIP_SEARCH_DRAWER:
      return !state;
    default:
      return state;
  }
};

export default searchDrawerOpen;
