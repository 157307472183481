import * as React from "react";

export const Aligned = (props: any) => (
  <div style={{ display: "flex", justifyContent: "start", alignItems: "center", ...(props.style || {}) }}>
    {props.children}
  </div>
);

export const AlignedTop = (props: any) => (
  <div style={{ display: "flex", justifyContent: "start", alignItems: "top", ...(props.style || {}) }}>
    {props.children}
  </div>
);
