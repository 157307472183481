import initialState from "store/initialState";
import { REMOVE_INVOCATION_FOCUS, SET_INVOCATION_FOCUS } from "actions/focusInvocationId";

const focusInvocationId = (state = initialState.focusInvocationId, action: any = {}) => {
  const { type, payload } = action;
  switch (type) {
    case REMOVE_INVOCATION_FOCUS:
      return null;
    case SET_INVOCATION_FOCUS:
      return payload?.id ?? null;
    default:
      return state;
  }
};

export default focusInvocationId;
