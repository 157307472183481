import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Field, Formik, FormikHelpers } from "formik";
import { validateValuePresence } from "lib/validation";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { ITemplateSummary } from "store/types";
import { cloneTemplateByYear } from "../../actions/admin";
import { Select } from "formik-mui";
import { PaddedSelectLabel } from "components/forms";
import { FormControl, FormHelperText, MenuItem } from "@mui/material";
import { get } from "lib/fetch";

interface IProps {
  open: boolean;
  close: () => any;
  templateSummary?: ITemplateSummary;
}

export interface ICloneTemplateFormVals {
  year: number | string;
}

const validateFormState = (values: ICloneTemplateFormVals) => {
  const errors: any = {};
  validateValuePresence(errors, values, "year");
  return errors;
};

enum LoadingState {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}
const sleep = (t: number) => new Promise((resolve, _reject) => setTimeout(() => resolve(""), t * 1000));
export const CloneTemplateDialog = ({ open, close, templateSummary }: IProps) => {
  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const templateId = templateSummary?.id;
  const templateYear = templateSummary?.year;
  const courseRecordId = templateSummary?.courseRecordId;

  useEffect(() => {
    const extractAvailableCourseYears = async () => {
      setLoadingState(LoadingState.LOADING);
      try {
        await sleep(1);
        const { allowedYears: allowedStartYears } = await get(
          `/v1/planCreationConfig/courseYears?recordId=${courseRecordId}`,
        );
        setLoadingState(LoadingState.LOADED);
        setAvailableYears(allowedStartYears.filter((y: number | string) => String(y) !== String(templateYear)));
      } catch (e) {
        setAvailableYears([]);
        setLoadingState(LoadingState.ERROR);
      }
    };

    if (open && courseRecordId && templateYear) {
      extractAvailableCourseYears();
    }
  }, [courseRecordId, templateYear, open]);

  const initialValues: ICloneTemplateFormVals = { year: "" };

  const onSubmit = useCallback(
    (values: ICloneTemplateFormVals, helpers: FormikHelpers<ICloneTemplateFormVals>) => {
      close();
      if (templateId && values.year) {
        const submitResult = dispatch(cloneTemplateByYear(templateId, values.year)) as any;
        submitResult.catch(() => helpers.setSubmitting(false));
      }
    },
    [close, dispatch, templateId],
  );

  if (!open) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="copy-template-dialog-title"
          aria-describedby="copy-template-dialog-description"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="copy-template-dialog-title">Clone template to another year</DialogTitle>
          <DialogContent>
            <form onSubmit={props.handleSubmit} id="copy-template-form">
              <PaddedSelectLabel
                aria-label="Year to clone to"
                required
                htmlFor="copy-template-to-year"
                id="copy-template-to-year-label"
              >
                Year to clone to
              </PaddedSelectLabel>
              {
                <FormControl variant="standard" fullWidth={true} required>
                  <Field
                    component={Select}
                    name="year"
                    fullWidth={true}
                    variant="outlined"
                    // disabled={allowedStartYears.length === 0}
                    inputProps={{ id: "copy-template-to-year", required: true }}
                    SelectDisplayProps={{
                      "aria-labelledby": "copy-template-to-year-label",
                      "aria-describedby": "copy-template-to-year-description",
                      required: true,
                    }}
                    disabled={loadingState === LoadingState.LOADING}
                    error={loadingState === LoadingState.ERROR}
                    required={true}
                  >
                    {availableYears.map((y) => (
                      <MenuItem key={String(y)} value={String(y)}>
                        {y}
                      </MenuItem>
                    ))}
                  </Field>

                  {loadingState !== LoadingState.LOADED && (
                    <FormHelperText>
                      {loadingState === LoadingState.LOADING && "loading years ..."}
                      {loadingState === LoadingState.ERROR && "Couldn't load available years"}
                    </FormHelperText>
                  )}
                </FormControl>
              }
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="copy-template-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !isEmpty(props.errors) || loadingState !== LoadingState.LOADED}
            >
              Copy
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
