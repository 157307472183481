import React, { useCallback, useMemo } from "react";
import { ICourse, IPlan, Semester } from "store/types";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Check from "@mui/icons-material/Check";
import { ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "./actionMenu.css";
import { minYearsInPlan } from "lib/years";
export interface IProps {
  anchorRef: React.Ref<any>;
  closeMenu: () => any;
  removeYear: (year: number) => any;
  flipSemester: (year: number, sem: Semester) => any;
  yearIndex: number;
  year: number;
  plan: IPlan;
  course: ICourse;
}
const useStyles = (theme: Theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedListRoot: {
    maxHeight: "40vh",
    overflow: "auto",
  },
});

const buildHasSemester = (plan: IPlan, year: number) => (semester: Semester) =>
  !!plan.studyPeriods.find((sp) => sp.year === year && sp.type === semester);

const buildHasSubject = (plan: IPlan, year: number) => (semester: Semester) =>
  !!plan.studyPeriods.find(
    (sp) => sp.year === year && sp.type === semester && sp.slots.find((slot) => !!slot.subjectRecordId),
  );
const yearHasSubject = (plan: IPlan, year: number) =>
  !!plan.studyPeriods.find((sp) => sp.year === year && sp.slots.find((slot) => !!slot.subjectRecordId));
const lastYear = (plan: IPlan) => Math.max(...plan.studyPeriods.map((sp) => Number(sp.year)));

export const StudyPeriodMenu = (props: IProps) => {
  const { closeMenu, plan, yearIndex, year, anchorRef, removeYear, flipSemester, ...menuProps } = props;
  const theme = useTheme();
  const styles = useStyles(theme);

  const doRemoveYear = useCallback(() => removeYear(year), [year, removeYear]);
  const doFlipSemester = useCallback((s: Semester) => () => flipSemester(year, s), [year, flipSemester]);

  const hasSemester = useMemo(() => buildHasSemester(plan, year), [plan, year]);
  const hasSubject = useMemo(() => buildHasSubject(plan, year), [plan, year]);

  // Allow year removal
  const lastPlanYear = lastYear(plan);
  const coursePoints = 150; //course.points ?? 300;
  const minYearNumber = minYearsInPlan(coursePoints, plan.isMidyearEntry);
  const showDeleteYearOption = lastPlanYear === year && !yearHasSubject(plan, year) && yearIndex >= minYearNumber;

  // Show/Hide logic semester
  const disableSem1 = true;
  const disableSem2 = year !== lastPlanYear || (yearIndex + 1) * 100 <= coursePoints;
  const disableSummer = plan.isMidyearEntry && yearIndex === 0;
  const disableWinter = false;

  const studyPeriods = [
    {
      label: "Semester 1",
      onClick: doFlipSemester(Semester.SEM1),
      disabled: disableSem1 || hasSubject(Semester.SEM1),
      selected: hasSemester(Semester.SEM1),
    },
    {
      label: "Semester 2",
      onClick: doFlipSemester(Semester.SEM2),
      disabled: disableSem2 || hasSubject(Semester.SEM2),
      selected: hasSemester(Semester.SEM2),
    },
    {
      label: "Summer term",
      onClick: doFlipSemester(Semester.SUMMER),
      disabled: disableSummer || hasSubject(Semester.SUMMER),
      selected: hasSemester(Semester.SUMMER),
    },
    {
      label: "Winter term",
      onClick: doFlipSemester(Semester.WINTER),
      disabled: disableWinter || hasSubject(Semester.WINTER),
      selected: hasSemester(Semester.WINTER),
    },
  ];

  const menuItems: any[] = [];
  menuItems.push(
    <SubMenu
      key={`study-period-sub-menu-${year}`}
      aria-label={`Study periods for year ${year}`}
      label={() => <ListItemText primary="Study periods" />}
    >
      {studyPeriods.map((sp: any) => (
        <MenuItem
          key={`${sp.label}-${year}`}
          tabIndex={0}
          type="checkbox"
          checked={sp.selected}
          onClick={sp.disabled ? undefined : sp.onClick}
          aria-pressed={sp.selected}
          className={sp.disabled ? "menu-list-item-disabled" : undefined}
          sx={sp.disabled ? undefined : styles.nested}
          aria-disabled={sp.disabled}
        >
          <ListItemIcon>
            {sp.selected && <Check className={sp.disabled ? "menu-list-item-icon-disabled" : undefined} />}
          </ListItemIcon>
          <ListItemText
            aria-label={`${sp.label} ${sp.disabled ? "Disabled," : ""}`}
            // className={sp.disabled ?  "menu-list-item-disabled" : undefined} not used so that users using assistive tech can still tab to it
            primary={sp.label}
          />
        </MenuItem>
      ))}
    </SubMenu>,
  );

  if (showDeleteYearOption) {
    menuItems.push(
      <MenuItem key={`study-period-menu-delete-year-${year}`} onClick={doRemoveYear}>
        Delete year
      </MenuItem>,
    );
  }
  return (
    <ControlledMenu
      // children={menuItems as any[]}
      sx={styles.root}
      anchorRef={anchorRef}
      onClose={closeMenu}
      {...{ ...menuProps, menuItemFocus: { position: "initial" } }}
    >
      {menuItems}
    </ControlledMenu>
  );
};
