import { FormControl, Theme, useTheme } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { IComponent, ICourseSummary, QualificationLevel } from "../../store/types";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import { TextField } from "formik-mui";

export const useStyles = (theme: Theme) => ({
  fieldSetStyle: {
    padding: "24px",
    marginTop: "24px",
    borderColor: theme.palette.divider,
    borderWidth: "1px",
  },
});

interface IProps {
  courses: any;
  components: any;
}

export const KeyGroup = (props: IProps) => {
  const { values, setFieldValue } = useFormikContext<any>(); // formikProps
  const theme = useTheme();
  const styles = useStyles(theme);
  const courses = props.courses;
  const components = props.components;

  // course
  const selectedCourse = useMemo(
    () => courses.find((c: any) => c.code === values.courseKey?.code) ?? null,
    [courses, values.courseKey],
  );
  const onCourseSelected = useCallback(
    (_e: any, course: ICourseSummary | null) => {
      setFieldValue(
        "courseKey",
        { name: course?.name, code: course?.code, qualificationLevel: course?.qualificationType[0]?.aqfLevel } ?? null,
      );
    },
    [setFieldValue],
  );

  // mms
  const selectedMMS = useMemo(
    () => components.find((c: any) => c.code === values.mmsKey?.code) ?? null,
    [components, values.mmsKey],
  );
  const onMMSSelected = useCallback(
    (_e: any, mms: any) => {
      setFieldValue("mmsKey", { name: mms?.name, code: mms?.code } ?? null);
    },
    [setFieldValue],
  );

  return (
    <fieldset style={styles.fieldSetStyle}>
      <legend>Key</legend>
      <InputLabel
        aria-label="Select course"
        htmlFor="course-key-select"
        id="course-key-select-label"
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        Select course (if applicable)
      </InputLabel>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          id="course-key-select"
          options={courses}
          getOptionLabel={(option: ICourseSummary) => `${option.name} (${option.code})`}
          style={{ width: "100%" }}
          renderInput={(params: any) => <Field component={TextField} {...params} name="courseKey" variant="outlined" />}
          value={selectedCourse}
          onChange={onCourseSelected}
        />
      </FormControl>
      {!(selectedCourse?.qualificationLevel == QualificationLevel.DIPLOMA) && (
        <>
          <InputLabel
            aria-label="Select mms key"
            htmlFor="mms-key-select"
            id="mms-key-select-label"
            style={{ marginTop: "12px", marginBottom: "12px" }}
          >
            Select mms (if applicable)
          </InputLabel>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="mms-key-select"
              options={components.filter(
                (c: IComponent) =>
                  c.parentCourse.code === values.courseKey?.code &&
                  c.publishedYears == new Date().getFullYear().toString(),
              )}
              getOptionLabel={(option: ICourseSummary) => `${option.name} (${option.code})`}
              style={{ width: "100%" }}
              renderInput={(params: any) => (
                <Field component={TextField} {...params} name="mmsKey" variant="outlined" />
              )}
              value={selectedMMS}
              onChange={onMMSSelected}
            />
          </FormControl>
        </>
      )}
    </fieldset>
  );
};
