import { combineReducers } from "redux";
import loading from "./loading";
import admin from "./admin";
import submittingChanges from "./submittingChanges";
import planCreationConfig from "./planCreationConfig";
import enrollment from "./enrollment";
import subjectSearch from "./subjectSearch";
import snackBarState from "./snackBarState";
import optimisticUpdate from "./optimisticUpdate";
import checklistDrawerForSubjectId from "./checklistDrawerForSubjectId";
import subjectDrawerForRule from "./subjectDrawerForRule";
import similarSubjectsSearch from "./similarSubjectsSearch";
import draggedSubjectId from "./draggedSubjectId";
import searchDrawerOpen from "./searchDrawerOpen";
import focusInvocationId from "./focusInvocationId";

export default () =>
  combineReducers({
    submittingChanges,
    loading,
    planCreationConfig,
    enrollment,
    subjectSearch,
    snackBarState,
    optimisticUpdate,
    checklistDrawerForSubjectId,
    subjectDrawerForRule,
    similarSubjectsSearch,
    draggedSubjectId,
    searchDrawerOpen,
    admin,
    focusInvocationId,
  });
