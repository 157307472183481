import React from "react";
import logo from "../../assets/images/logo-with-padding.svg";
import { ResponsiveCenter } from "components/spinner/Center";
import styled from "styled-components";
import { AppBar, Button, Theme, Toolbar, useTheme } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import "./app-bar.css";
import { Aligned } from "components/aligned/alignedDiv";
import { useSelector } from "react-redux";
import { ComponentType, HintToolTip, IState, UserMode } from "store/types";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { Hint } from "../Hint";
import { useMenuState } from "@szhsin/react-menu";

const StyledToolbar = styled(Toolbar)`
  padding-left: 0rem !important;
`;
const Img = styled.img`
  max-height: 5rem;
`;

interface IProps {
  path?: string;
  id?: string;
  openTemplatesDrawer?: () => any;
  mmsRecordIds?: any;
}

const useStyles = (theme: Theme) => ({
  icon: {
    fill: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
});

const AppHeader = (props: IProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  let templates = useSelector((s: IState) => s.enrollment?.matchingTemplates ?? []);
  const { planIsTemplate, userMode } = usePlanPermissions();
  const [menuProps, _toggleMenu] = useMenuState({ transition: true });

  const allowedComponents = useSelector((state: IState) => state?.enrollment?.allowedComponents);
  const components = allowedComponents?.filter((ac) => props.mmsRecordIds?.includes(ac.recordId));
  const streamOrEntryPointComponent = components?.find(
    (component) => component.type === ComponentType.STREAM || component.type === ComponentType.ENTRY,
  );

  if (!!streamOrEntryPointComponent) {
    templates = templates.filter((t: any) => t.mmsRecordIds.includes(streamOrEntryPointComponent.recordId));
  }

  const showTemplates = userMode === UserMode.STUDENT && !planIsTemplate && templates.length > 0;

  return (
    <AppBar position="static" className="do-not-print">
      <ResponsiveCenter>
        <Aligned style={{ justifyContent: "space-between" }}>
          <StyledToolbar>
            <Img src={logo} alt="University of Melbourne Logo" />
            <a href="/" className="page-title">
              My Course Planner {props.id}
            </a>
          </StyledToolbar>

          {showTemplates && (
            <Hint hintType={HintToolTip.SAMPLE_COURSE_PLANS} hideHint={menuProps.state === "open"} placement="left">
              <Button
                variant="outlined"
                className={`do-not-print`}
                sx={styles.button}
                onClick={props.openTemplatesDrawer}
                // TouchRippleProps={{ sx: rippleClasses }}
                startIcon={<DirectionsIcon sx={styles.icon} />}
              >
                Sample Course Plans
              </Button>
            </Hint>
          )}
        </Aligned>
      </ResponsiveCenter>
    </AppBar>
  );
};

export default AppHeader;
