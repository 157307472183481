import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppBar, Drawer, Grid, IconButton, InputAdornment, TextField, Toolbar } from "@mui/material";
import { COURSE_GRID_SEMESTERS, IState, ISubjectQuery } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import {
  SearchDrawerPaddedContainer,
  SearchDrawerResultsContainer,
  SearchDrawerResultsHeaderContainer,
  useSearchDrawerStyles,
  useSearchStyles,
} from "theme/styles";
import { closeSubjectDrawerForRule } from "actions/subjectDrawerForRule";
import { ChecklistInvocationText } from "containers/checklist/InvocationsText";
import { SearchOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { MultiSelectField, PaginationContainer, SubjectSearchResults } from "./common";
import { AutocompleteMultiSelect } from "./AutocompleteMultiSelect";
import { Level } from "lib/searchConstants";
import identity from "lodash/identity";
import { InvocationIcon } from "containers/checklist/InvocationIcon";
import { Aligned } from "components/aligned/alignedDiv";
import { useSearch, useSearchPagination } from "./hooks";
import Spinner from "components/spinner";
import { ChecklistSubjectCard } from "containers/slotCard/checklistSubjectCard";
import { SubjectResultsHeader } from "./SubjectResultsHeader";
import { analyseDrawerSubject } from "lib/subjects";
import { getChecklistInvocations } from "lib/invocations";
import { Pagination, useTheme } from "@mui/material";
import { useCoursesFor, useAssignedCourseRecordIds, useAssignedMMSRecordIds } from "lib/assignment";

export const RuleSubjectSearchDrawer = () => {
  // What we need from the Redux state
  const drawerFor = useSelector((s: IState) => s.subjectDrawerForRule);
  const {
    course: { effectiveRulesYear },
    plan,
    courseAreasOfStudy,
    validation: { invocations },
  } = useSelector((state: IState) => state.enrollment!);
  const userIsDragging = useSelector((s: IState) => !!s.draggedSubjectId);
  const { id: planId } = plan;
  const allGroups = useSelector((s: IState) => s.enrollment?.validation.groups || []);
  const checklistInvocations = useSelector((s: IState) => getChecklistInvocations(s.enrollment));
  const subjectSearch = useSelector((state: IState) => state.subjectSearch);
  const displayResults = subjectSearch.results.filter((s) => !s.discoveryResult);
  const invocation = invocations.find((i) => i.recordId === drawerFor?.recordId && i.ruleId === drawerFor.ruleId);

  const matchingCourses = useCoursesFor(invocation?.recordId);
  const assignmentCourseRecordIds = [matchingCourses[0]?.recordId].filter(Boolean) as string[];
  const assignedMMSRecordIds = useAssignedMMSRecordIds();
  const mmsRecordId = assignedMMSRecordIds.find((id) => id === invocation?.recordId);

  const areasOfStudy = useMemo(() => courseAreasOfStudy.map((a) => a.area), [courseAreasOfStudy]);
  const getAreaDescription = useCallback(
    (area: string) => courseAreasOfStudy.find((aos) => aos.area === area)?.description || area,
    [courseAreasOfStudy],
  );

  const dispatch = useDispatch();
  const closeDrawer = useCallback(() => {
    dispatch(closeSubjectDrawerForRule());
  }, [dispatch]);

  const recordId = invocation?.recordId;
  const ruleId = invocation?.ruleId;

  const allCourseRecordIds = useAssignedCourseRecordIds();
  const allMMSRecordIds = useAssignedMMSRecordIds();

  const recordType =
    recordId && allCourseRecordIds.indexOf(recordId) >= 0
      ? "course"
      : recordId && allMMSRecordIds.indexOf(recordId) >= 0
      ? "component"
      : "subject";

  const relevantCourses = useCoursesFor(recordId);
  const relevantCoursesRecordIds = relevantCourses.map((c) => c.recordId);

  const initialQuery: ISubjectQuery = useMemo(
    () => ({
      text: "",
      level: [],
      studyPeriod: [],
      areaOfStudy: [],
      courseRecordIds: relevantCoursesRecordIds,
      page: 0,
      pageSize: 10,
      sort: {
        property: "code",
        direction: "ASC",
      },
      ruleSearch:
        ruleId && recordId
          ? {
              recordType,
              ruleId,
              ruleRecordId: recordId,
            }
          : undefined,
    }),
    [recordId, ruleId, recordType, relevantCoursesRecordIds],
  );

  const { query, searchYear, updateQuery, onSearchTextChange, searching, updateSort, updatePage } = useSearch(
    effectiveRulesYear,
    initialQuery,
  );
  const { enablePagination, numPages } = useSearchPagination();

  const open = !!planId && !!invocation;
  const theme = useTheme();
  const styles = useSearchStyles(theme);
  const searchDrawerClasses = useSearchDrawerStyles();

  const [searchText, setSearchText] = useState("");
  useEffect(() => setSearchText(""), [open]);
  useEffect(() => {
    onSearchTextChange({ target: { value: searchText } });
  }, [onSearchTextChange, searchText]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={closeDrawer}
      PaperProps={{
        sx: searchDrawerClasses.searchDrawerPaper,
      }}
      ModalProps={{
        disableEscapeKeyDown: userIsDragging,
      }}
      keepMounted
    >
      {invocation && (
        <SearchDrawerPaddedContainer>
          <Aligned style={{ alignItems: "start", marginBottom: "1rem", marginTop: "1rem" }}>
            <InvocationIcon
              style={{ marginTop: "0.1rem", marginRight: "0.5rem" }}
              invocation={invocation}
              allGroups={allGroups}
              checklistInvocations={checklistInvocations}
            />
            <strong>
              <ChecklistInvocationText invocation={invocation!} extraDndToken="subject-drawer" />
            </strong>
          </Aligned>
        </SearchDrawerPaddedContainer>
      )}

      <AppBar sx={styles.appBar}>
        <Toolbar sx={searchDrawerClasses.toolbar}>
          {/* <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close"></IconButton> */}
          <TextField
            sx={styles.textField}
            autoFocus={true}
            fullWidth={true}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Subjects"
            variant="outlined"
            InputLabelProps={{ sx: styles.label }}
            inputProps={{ "aria-label": "Search Subjects" }}
            InputProps={{
              sx: styles.textFieldInputRoot,
              startAdornment: (
                <InputAdornment position="start" sx={styles.adornment}>
                  <SearchOutlined sx={styles.icon} />
                </InputAdornment>
              ),
            }}
          />
          <IconButton color="inherit" aria-label="Close" onClick={closeDrawer} size="large">
            <CloseIcon sx={styles.icon} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <SearchDrawerPaddedContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6} xl={4}>
            <MultiSelectField
              id="level-select-in-rule-search-drawer"
              name="level"
              label="Level"
              formValue={query.level}
              allValues={Level}
              placeholder="Select levels"
              formatLabel={(l: string) => `Level ${l}`}
              onChange={updateQuery("level")}
              renderValue={(n) => `${n} levels selected`}
            />
          </Grid>

          <Grid item xs={12} lg={6} xl={4}>
            <MultiSelectField
              id="studyPeriod-select-in-rule-search-drawer"
              name="studyPeriod"
              label="Study Period"
              formValue={query.studyPeriod}
              allValues={COURSE_GRID_SEMESTERS}
              placeholder="Select study period"
              formatLabel={identity}
              onChange={updateQuery("studyPeriod")}
              renderValue={(n) => `${n} study terms selected`}
            />
          </Grid>

          <Grid item xs={12} lg={6} xl={4}>
            <AutocompleteMultiSelect
              id="area-of-study-list-in-rule-search-drawer"
              label="Area of Study"
              options={areasOfStudy}
              formValue={query.areaOfStudy}
              formatLabel={getAreaDescription}
              onChange={updateQuery("areaOfStudy")}
              placeholder="Area of Study"
            />
          </Grid>
        </Grid>
      </SearchDrawerPaddedContainer>

      <SearchDrawerResultsContainer>
        {!searching && (
          <SearchDrawerResultsHeaderContainer>
            <SubjectResultsHeader query={query} searchYear={searchYear} sortSubjects={updateSort} />
          </SearchDrawerResultsHeaderContainer>
        )}
        {invocation && (
          <SubjectSearchResults>
            <Spinner loading={searching} />
            {!searching && (
              <Grid container spacing={1}>
                {displayResults.map((s) => {
                  const meta = analyseDrawerSubject(s, invocation);
                  return (
                    <Grid item key={s.id} xs={12}>
                      <ChecklistSubjectCard
                        key={s.recordId}
                        title={meta.checklistSubjectTitle}
                        subject={s}
                        dndToken={`${invocation.groupId}-${invocation.ruleId}-search-result-subject`}
                        mmsRecordId={mmsRecordId}
                        courseRecordIds={assignmentCourseRecordIds}
                        subjectTag={meta.checklistSubjectTag}
                        assignAs={invocation.matchingDetails?.breadthDisciplineScope ?? undefined}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </SubjectSearchResults>
        )}

        {(enablePagination || searching) && invocation && (
          <PaginationContainer>
            <Pagination
              count={numPages}
              page={query.page + 1}
              onChange={updatePage}
              color="primary"
              showFirstButton={numPages > 3}
              showLastButton={numPages > 3}
            />
          </PaginationContainer>
        )}
      </SearchDrawerResultsContainer>
    </Drawer>
  );
};
