import { CLEAR_SEARCH, SUBJECTS_SEARCH } from "actions/subjectSearch";
import { fulfilled, failed, pending } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";

const subjectSearch = (state = initialState.subjectSearch, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case fulfilled(SUBJECTS_SEARCH):
      // Handle different order of search results coming back
      if (payload.searchTimestamp > (state.searchTimestamp || 0)) {
        const { assignedSubjectRecordIds: _, ...searchResultPayload } = payload;

        return {
          ...state,
          ...searchResultPayload,
          searchInProgress: false,
          results: payload.results,
        };
      } else {
        return state;
      }
    case failed(SUBJECTS_SEARCH):
      return { ...state, results: [], searchInProgress: false };
    case pending(SUBJECTS_SEARCH):
      return { ...state, results: [], searchInProgress: true };
    case CLEAR_SEARCH:
      return initialState.subjectSearch;
    default:
      return state;
  }
};

export default subjectSearch;
