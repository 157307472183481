import { useMediaQuery, useTheme } from "@mui/material";
import { Breakpoint } from "@mui/material/styles";

export function useBreakpoint(): Breakpoint {
  const theme = useTheme();

  const breakPointsMatches = [
    { breakpoint: "xs", matches: useMediaQuery(theme.breakpoints.down("lg")) },
    { breakpoint: "sm", matches: useMediaQuery(theme.breakpoints.down("xl")) },
    { breakpoint: "md", matches: useMediaQuery(theme.breakpoints.down("xl")) },
    { breakpoint: "lg", matches: useMediaQuery(theme.breakpoints.down("xl")) },
    { breakpoint: "xl", matches: useMediaQuery(theme.breakpoints.down("xl")) },
  ];

  const firstMatch = breakPointsMatches.find((m) => m.matches);

  return (firstMatch?.breakpoint || "xl") as Breakpoint;
}
