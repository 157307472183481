import React from "react";
import Grid from "@mui/material/Grid";

export const ResponsiveCenter = ({ children, className }: { children: any; className?: string }) => (
  <Grid container spacing={0} className={className} alignItems="center" justifyContent="center">
    <Grid item xs={12} sm={11} md={11} lg={11} xl={9}>
      <div>{children}</div>
    </Grid>
  </Grid>
);
