import { HintToolTip, IEnrollment } from "store/types";
import { getChecklistInvocations } from "./invocations";
import { getSubjectRecordIds, isPlanEmpty } from "./plan";
import { planChecklistHasFailedGroups, subjectHasErrors } from "./validation";

export const orderedHintMatchers = [
  {
    hint: HintToolTip.SUBJECT_FINDER,
    matcher: (e: IEnrollment) => !!e.subjectSearchOpen,
  },
  {
    hint: HintToolTip.ADD_MMS,
    matcher: (e: IEnrollment) => e.plan.mmsRecordIds.length === 0 && e.allowedComponents.length > 0,
  },
  {
    hint: HintToolTip.ADD_SUBJECT,
    matcher: (_e: IEnrollment) => true,
  },
  {
    hint: HintToolTip.RULE_PROGRESS,
    matcher: (e: IEnrollment) => planChecklistHasFailedGroups(getChecklistInvocations(e), e.validation.groups),
  },
  {
    hint: HintToolTip.ADD_NON_STANDARD_SUBJECT,
    matcher: (_e: IEnrollment) => true,
  },
  {
    hint: HintToolTip.SAMPLE_COURSE_PLANS,
    matcher: (_e: IEnrollment) => true,
  },
  {
    hint: HintToolTip.B_DES_MMS_WARNING,
    matcher: (e: IEnrollment) => e.course.code === "B-DES" && e.plan.mmsRecordIds.length === 1,
  },
  {
    hint: HintToolTip.MMS_RULES,
    matcher: (e: IEnrollment) => e.plan.mmsRecordIds.length > 0,
  },
  {
    hint: HintToolTip.SUBJECT_DETAILS,
    matcher: (e: IEnrollment) => !isPlanEmpty(e.plan),
  },
  {
    hint: HintToolTip.SUBJECT_PREREQ,
    matcher: (e: IEnrollment) => {
      const subjectRecordIds = getSubjectRecordIds(e.plan);
      return !!subjectRecordIds.find((recordId) =>
        subjectHasErrors(e.validation.invocations, e.validation.groups, recordId),
      );
    },
  },
  {
    hint: HintToolTip.ADD_CREDIT_ADVANCED_STANDING,
    matcher: (_e: IEnrollment) => true,
  },
  {
    hint: HintToolTip.COMPLETION,
    // TODO Currently, this logic is in the component - extract it.
    // For now, the component will decide whether to render or not
    matcher: (_e: IEnrollment) => true,
  },
];

const LOCAL_STOREAGE_KEY = "LOCAL_STORAGE_CLOSED_HINTS";

export const getClosedHints = (): HintToolTip[] => {
  return (localStorage.getItem(LOCAL_STOREAGE_KEY) || "").split(",").filter(Boolean) as HintToolTip[];
};
export const closeHint = (hint?: HintToolTip | null) => {
  if (hint) {
    const closedHints = getClosedHints();
    localStorage.setItem(LOCAL_STOREAGE_KEY, [...closedHints, hint].join(","));
  }
};
export const resetHints = () => {
  localStorage.setItem(LOCAL_STOREAGE_KEY, "");
};
export const matchingHint = (e: IEnrollment) => {
  const closedHints = getClosedHints();
  for (const m of orderedHintMatchers) {
    if (closedHints.indexOf(m.hint) < 0 && m.matcher(e)) {
      return m.hint;
    }
  }
  return null;
};
