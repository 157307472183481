import { get } from "lib/fetch";
import { max } from "lodash";
import store from "store";
import { IState } from "store/types";
export const GET_SIMILAR_SUBJECTS = "similarSubjectsSearch/GET_SIMILAR_SUBJECTS";
export const RESET_SIMILAR_SUBJECTS = "similarSubjectsSearch/RESET_SIMILAR_SUBJECTS";

export const getSimilarSubjects = (subjectRecordId: string) => {
  const currentYear = new Date().getFullYear();
  const { enrollment } = store.getState() as IState;
  const courseCode = enrollment?.course.code || "";
  const latestCourseYear = max(enrollment?.course.allowedStartYears || []) || currentYear;
  const searchYear = Math.min(latestCourseYear, currentYear);
  const now = Date.now();
  return {
    type: GET_SIMILAR_SUBJECTS,
    payload: get(
      `/v1/subject/similarSubjects?recordId=${subjectRecordId}&courseCode=${courseCode}&year=${searchYear}`,
    ).then((results) => ({
      subjectRecordId,
      results,
      searchTimestamp: now,
    })),
  };
};

export const closeSimilarSubjects = () => {
  return {
    type: RESET_SIMILAR_SUBJECTS,
  };
};
