import { DnDId, ISlotIdentifier } from "store/types";

export const makeDnDId = (id: DnDId, extraToken?: string) =>
  JSON.stringify({
    year: id.year,
    semester: id.semester,
    slotIndex: id.slotIndex,
    subjectRecordId: id.subjectRecordId,
    mmsRecordId: id.mmsRecordId,
    courseRecordIds: id.courseRecordIds,
    assignAs: id.assignAs,
    // In case we have the same card displayed in multiple places
    ...(extraToken ? { extraToken } : {}),
  });

export const parseDnDId = (dnd: string): DnDId => JSON.parse(dnd);

export const slotIdsMatch = (id1: ISlotIdentifier, id2: ISlotIdentifier) =>
  id1.year === id2.year && id1.semester === id2.semester && id1.slotIndex === id2.slotIndex;
