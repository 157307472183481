import styled from "styled-components";

export const SearchResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  & p {
    font-size: 90%;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
  }
`;
