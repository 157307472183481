import React from "react";
import { IconButton, Paper } from "@mui/material";
import styled from "styled-components";
import ViewListOutlined from "@mui/icons-material/PlaylistAddCheck";

const WrapperPaper = styled(Paper)`
  cursor: pointer;
  background: #f7f7f7;
  height: 100%;
  text-align: center;
  width: 4rem;
  margin-left: 1rem;
`;

const CenteredButton = styled(IconButton)`
  margin-top: 1rem;
`;

interface IProps {
  open: () => any;
}

export const CollapsedChecklist = (props: IProps) => {
  return (
    <WrapperPaper onClick={props.open} elevation={2}>
      <CenteredButton color="inherit" aria-label="Open">
        <ViewListOutlined />
      </CenteredButton>
    </WrapperPaper>
  );
};
