import { IComponent, ITemplateSummary } from "store/types";

export const templateMMS = (t: ITemplateSummary, componentHash: Record<string, IComponent>) => {
  const components = t.mmsRecordIds.map((id) => componentHash[id]).filter(Boolean);
  const nameParts = [...components.map((c) => `${c.name} (${c.type})`)].map((s) => s?.trim()).filter(Boolean);
  return nameParts.join(", ");
};

export const templateName = (t: ITemplateSummary, componentHash: Record<string, IComponent>) => {
  const nameParts = [templateMMS(t, componentHash), t.name].map((s) => s?.trim()).filter(Boolean);
  return nameParts.join(", ");
};
