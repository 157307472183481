import { slotIdsMatch } from "containers/slotCard/dnd";
import { IOverload, IPlan, IRuleInvocation, ISlotIdentifier, IStudyPeriod, Semester } from "store/types";
import flatten from "lodash/flatten";
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";
import range from "lodash/range";
import { getOverloadForPeriod, maxPointsForSemester } from "./subjectGrid";

export const ordinalSemester = (sem: Semester) => {
  switch (sem) {
    case Semester.ADVANCED_STANDING:
      return 0;
    case Semester.SUMMER:
      return 1;
    case Semester.SEM1:
      return 2;
    case Semester.WINTER:
      return 3;
    case Semester.SEM2:
      return 4;
    default:
      throw new Error(`Unknown Semester ${sem}`);
  }
};

const ordinalStudyPeriod = (sp: IStudyPeriod) => sp.year * 100 + ordinalSemester(sp.type);

export const orderedStudyPeriods = (studyPeriods: IStudyPeriod[]) => sortBy(studyPeriods, ordinalStudyPeriod);

export const isFirstEmptySlotInPlan = (plan: IPlan | undefined, slotId: ISlotIdentifier) => {
  if (!plan) {
    return false;
  }
  let index = 0;
  const emptyStudyPeriods = plan.studyPeriods.find((sp) =>
    sp.slots.find((s: any, i) => {
      if (s.subjectRecordId === undefined) {
        index = i;
        return s;
      }
    }),
  );

  return !!emptyStudyPeriods
    ? slotId.slotIndex === index && slotId.semester === emptyStudyPeriods.type && slotId.year === emptyStudyPeriods.year
    : false;
};

export const isLastSlotInSemester = (plan: IPlan | undefined, slotId: ISlotIdentifier) => {
  if (!plan) {
    return false;
  }
  const sp = plan.studyPeriods.find((sp) => String(sp.year) === String(slotId.year) && sp.type === slotId.semester);
  const idx = (sp?.slots ?? []).findIndex((s) => s.sequence === slotId.slotIndex);
  return idx === (sp?.slots ?? []).length - 1;
};

export const isFirstSubjectInPlan = (plan: IPlan | undefined, slotId: ISlotIdentifier) => {
  if (!plan) {
    return false;
  }

  for (const sp of plan.studyPeriods) {
    let i = 0;
    for (const slot of sp.slots) {
      if (slot.subjectRecordId) {
        return slotIdsMatch(slotId, {
          year: sp.year,
          semester: sp.type,
          slotIndex: i,
        });
      }
      i++;
    }
  }
};

export const isPlanEmpty = (plan: IPlan | undefined) => {
  if (!plan) {
    return true;
  }
  for (const sp of plan.studyPeriods) {
    for (const slot of sp.slots) {
      if (slot.subjectRecordId) {
        return false;
      }
    }
  }
  return true;
};

export const getSubjectRecordIds = (plan: IPlan | undefined) => {
  if (!plan) {
    return [];
  }
  const subjectRecordIds = flatten(plan.studyPeriods.map((sp) => sp.slots)).map((slot) => slot.subjectRecordId);
  return subjectRecordIds.filter(Boolean) as string[];
};

export const getSubjectRecordIdsNotInPlan = (plan: IPlan | undefined, recordIds?: string[]) => {
  if (!plan || !recordIds || recordIds.length === 0) {
    return [];
  }
  const subjectRecordIds = getSubjectRecordIds(plan);
  return recordIds.filter((ri) => subjectRecordIds.indexOf(ri) < 0);
};

export const getSubjectStudyPeriod = (plan: IPlan | undefined, subjectRecordId?: string) => {
  if (!plan || !subjectRecordId) {
    return null;
  }
  for (const sp of plan?.studyPeriods || []) {
    for (const slot of sp.slots) {
      if (slot.subjectRecordId === subjectRecordId) {
        return sp;
      }
    }
  }
  return null;
};

export const isSelfEvalTicked = (plan: IPlan | undefined, invocation?: IRuleInvocation) => {
  if (!plan || !invocation) {
    return false;
  }
  const selfEvalSelections = plan.selfEvalSelections || [];
  return !!selfEvalSelections.find((ses) => ses.ruleId === invocation.ruleId && ses.recordId === invocation.recordId);
};

export const computePointsOptions = (
  destination: { year?: number; semester?: Semester; slotSequence?: number | string },
  plan: IPlan | undefined | null,
  coursePoints: number | undefined,
  overloads: IOverload[],
) => {
  if (!plan) {
    return [];
  }

  const allSiblingSlots = findStudyPeriod(destination, plan.studyPeriods)?.slots || [];
  const otherSiblingSlots = allSiblingSlots.filter(
    (slot) => String(slot.sequence) !== String(destination.slotSequence),
  );
  const allPoints = otherSiblingSlots.map((slot) => (slot.subjectRecordId ? slot.displayPoints : 0));
  const totalPoints = sum(allPoints);

  const overload = getOverloadForPeriod({ year: destination.year!, type: destination.semester! }, overloads);
  const maxPts = destination.semester ? maxPointsForSemester(destination.semester, coursePoints, overload) : 50;

  return range(6.25, maxPts - totalPoints + 0.1, 6.25);
};

export const findStudyPeriod = (studyPeriodDef: { year?: number; semester?: Semester }, studyPeriods: IStudyPeriod[]) =>
  studyPeriods.find((sp) => String(sp.year) === String(studyPeriodDef.year) && sp.type === studyPeriodDef.semester);

export const findSlot = (slotId: ISlotIdentifier, studyPeriods: IStudyPeriod[]) =>
  (findStudyPeriod(slotId, studyPeriods)?.slots ?? []).find((slot) => slot.sequence === slotId.slotIndex);

export const findSlotBySubject = (subjectRecordId: string, studyPeriods: IStudyPeriod[]) =>
  studyPeriods.flatMap((sp) => sp.slots).find((slot) => slot.subjectRecordId === subjectRecordId);

export const allSelectedStudyAreaIds = (plan?: IPlan) =>
  !plan
    ? []
    : [
        ...plan.mmsRecordIds,
        ...(plan.secondaryCourses ?? []).map((sc) => sc.courseRecordId),
        ...flatten((plan.secondaryCourses ?? []).map((sc) => sc.mmsRecordIds)),
      ];
