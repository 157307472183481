import React from "react";
import { Tooltip } from "@mui/material";
import { Aligned } from "components/aligned/alignedDiv";
import { analyseSubject } from "lib/subjects";
import CalendarIcon from "@mui/icons-material/DateRangeOutlined";
import ShareIcon from "@mui/icons-material/Share";

export interface IProps {
  title: string;
  subjectMeta: ReturnType<typeof analyseSubject>;
}

export const SlotCardHeaderTitle = ({ title, subjectMeta }: IProps) => {
  if (subjectMeta.isFullYear) {
    return (
      <Aligned>
        <Tooltip arrow aria-label={"Year Long Subject"} title={"Year Long Subject"} placement="top">
          <CalendarIcon fontSize="small" />
        </Tooltip>
        &nbsp;{title}
      </Aligned>
    );
  }

  if (subjectMeta.isCrossCredit) {
    return (
      <Aligned>
        <Tooltip arrow aria-label={"Cross Credit Subject"} title={"Cross Credit Subject"} placement="top">
          <ShareIcon fontSize="small" />
        </Tooltip>
        &nbsp;&nbsp;<div>{title}</div>
      </Aligned>
    );
  }

  return <>{title}</>;
};
