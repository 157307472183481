import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "@reach/router";
import { featureToggles } from "../../config/featureToggles";

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavToTemplates = () => {
    navigate("/staff/templates");
    setAnchorEl(null);
  };

  const handleNavToEligibility = () => {
    navigate("/staff/eligibility");
    setAnchorEl(null);
  };

  const handleNavToDiscontinuedMMS = () => {
    navigate("/staff/discontinued-mms");
    setAnchorEl(null);
  };

  return (
    <div>
      <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Menu
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleNavToTemplates}>Templates</MenuItem>
        <MenuItem onClick={handleNavToEligibility}>Eligibility</MenuItem>
        {featureToggles.discontinuedMMS && <MenuItem onClick={handleNavToDiscontinuedMMS}>Discontinued MMS</MenuItem>}
      </Menu>
    </div>
  );
}
