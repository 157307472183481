import React, { useCallback } from "react";
import {
  Checkbox,
  FormControl,
  MenuItem,
  ListItemText,
  InputAdornment,
  IconButton,
  Select,
  MenuProps,
  FormControlLabel,
  SelectChangeEvent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

const SelectMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};

export const ScrollDialogContent = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6rem);
`;

export const PaginationContainer = styled.div`
  padding: 0.5rem 0rem 0.1rem 0rem;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const SubjectSearchResults = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
  padding-left: 1px;
  flex-grow: 1;
`;

export const CheckboxControlLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: start;
  padding-left: 0;
  margin-left: 0;
  height: 100%;
`;

export const SelectLabel = styled.label`
  padding: 0.5rem;
`;

const DiscoveryDisclaimerText = styled.p`
  margin-bottom: 0;
  font-style: italic;
  font-size: 0.8rem;
  color: #575757;
`;

export const DiscoveryDisclaimer = React.memo(() => (
  <DiscoveryDisclaimerText>Powered by IBM Watson Discovery</DiscoveryDisclaimerText>
));

interface IMultiSelectFormProps {
  id: string;
  name: string;
  formValue: string[];
  allValues: string[];
  placeholder: string;
  label: string;
  renderValue: (nSelected: number) => string;
  formatLabel: (l: string) => string;
  onChange: (newVals: string[] | string) => any;
}
export const MultiSelectField = (props: IMultiSelectFormProps) => {
  const { onChange } = props;
  const doChange = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  const doClear = useCallback(() => {
    onChange([]);
  }, [onChange]);

  return (
    <FormControl variant="outlined" fullWidth={true}>
      <SelectLabel id={`${props.id}-select-label`} htmlFor={`${props.id}-select`}>
        {props.label}
      </SelectLabel>
      <Select
        fullWidth={true}
        variant="outlined"
        multiple
        onChange={doChange}
        value={props.formValue}
        // input={<Input id="select-multiple-chip" />}
        MenuProps={SelectMenuProps}
        displayEmpty={true}
        renderValue={(selected: any) =>
          !selected || selected.length === 0 ? (
            <em>{props.placeholder}</em>
          ) : selected.length === 1 ? (
            props.formatLabel(selected[0])
          ) : (
            props.renderValue(selected.length)
          )
        }
        inputProps={{ id: `${props.id}-select` }}
        labelId={`${props.id}-select-label`}
        // inputProps={{ id: `${props.name}-select` }}
        endAdornment={
          props.formValue.length === 0 ? undefined : (
            <InputAdornment position="end">
              <IconButton size="small" style={{ marginRight: "15px" }} onClick={doClear} aria-label="Close button">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      >
        {props.allValues.map((l) => (
          <MenuItem key={l} value={l}>
            <Checkbox checked={props.formValue.indexOf(l) > -1} />
            <ListItemText primary={props.formatLabel(l)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
