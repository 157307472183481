import { ICourseSummary } from "../../store/types";
import { Field, useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";

import TextField from "@mui/material/TextField";
import { FormControl, Theme, InputLabel, Autocomplete, useTheme } from "@mui/material";

export const useStyles = (theme: Theme) => ({
  fieldSetStyle: {
    padding: "24px",
    marginTop: "24px",
    borderColor: theme.palette.divider,
    borderWidth: "1px",
  },
});

interface IProps {
  courses: any;
  components: any;
}

export const KeyGroup = (props: IProps) => {
  const { values, setFieldValue } = useFormikContext<any>(); // formikProps
  const theme = useTheme();
  const styles = useStyles(theme);
  const courses = props.courses;

  // course
  const selectedCourse = useMemo(
    () => courses.find((c: any) => c.code === values.course?.code) ?? null,
    [courses, values.course],
  );

  const onCourseSelected = useCallback(
    (_e: any, course: ICourseSummary | null) => {
      setFieldValue("course", { name: course?.name, code: course?.code } ?? null);
    },
    [setFieldValue],
  );

  return (
    <fieldset style={styles.fieldSetStyle}>
      <legend>Course</legend>
      <InputLabel
        aria-label="Select course"
        htmlFor="course-select"
        id="course-select-label"
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        Select course
      </InputLabel>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          id="course-select"
          options={courses}
          getOptionLabel={(option: ICourseSummary) => `${option.name} (${option.code})`}
          style={{ width: "100%" }}
          renderInput={(params: any) => <Field component={TextField} {...params} name="course" variant="outlined" />}
          value={selectedCourse}
          onChange={onCourseSelected}
        />
      </FormControl>
    </fieldset>
  );
};
