import { flatMap } from "lodash";
import { useSelector } from "react-redux";
import { IBaseCourse, IComponent, IEnrollment, IState, ISubject } from "store/types";
import { isSubjectAssignable } from "./subjects";

export const getAssignedCourseRecordIds = (enrollment?: IEnrollment | null) =>
  [
    enrollment?.plan?.courseRecordId,
    ...(enrollment?.plan.secondaryCourses ?? []).map((sc) => sc.courseRecordId),
  ].filter(Boolean) as string[];
export const useAssignedCourseRecordIds = () =>
  useSelector(({ enrollment }: IState) => getAssignedCourseRecordIds(enrollment));

export const getAssignedCourses = (enrollment?: IEnrollment | null) => {
  const assignedCourseRecordIds = getAssignedCourseRecordIds(enrollment);
  return [enrollment?.course, ...(enrollment?.allowedSecondaryCourses ?? [])]
    .filter(Boolean)
    .filter((c) => assignedCourseRecordIds.indexOf(c!.recordId) >= 0) as IBaseCourse[];
};
export const useAssignedCourses = () => useSelector(({ enrollment }: IState) => getAssignedCourses(enrollment));

export const getAssignedCourseCodes = (enrollment?: IEnrollment | null) =>
  getAssignedCourses(enrollment).map((c) => c.code);

export const useAssignedCourseCodes = () => useSelector((s: IState) => getAssignedCourseCodes(s.enrollment));

export const getAssignedMMSRecordIds = (enrollment?: IEnrollment | null) =>
  [
    ...(enrollment?.plan?.mmsRecordIds ?? []),
    ...flatMap(enrollment?.plan.secondaryCourses ?? [], (sc) => sc.mmsRecordIds),
  ].filter(Boolean) as string[];
export const useAssignedMMSRecordIds = () =>
  useSelector(({ enrollment }: IState) => getAssignedMMSRecordIds(enrollment));

export const getAssignedMMS = (enrollment?: IEnrollment | null) => {
  const assignedMMSRecordIds = getAssignedMMSRecordIds(enrollment);
  return [
    ...(enrollment?.allowedComponents ?? []),
    ...flatMap(enrollment?.allowedSecondaryCourses ?? [], (c) => c.allowedComponents),
  ]
    .filter(Boolean)
    .filter((c) => assignedMMSRecordIds.indexOf(c!.recordId) >= 0) as IComponent[];
};
export const useAssignedMMS = () => useSelector(({ enrollment }: IState) => getAssignedMMS(enrollment));

export const getCoursesFor = (enrollment?: IEnrollment | null, recordId?: string, allSubjects: ISubject[] = []) => {
  // Step 1 - check if id is a course
  const assignedCourses = getAssignedCourses(enrollment);

  let courseMatch = assignedCourses.filter((c) => c.recordId === recordId);
  if (courseMatch.length > 0) {
    return courseMatch;
  }

  // Step 2 - check if id is an MMS. If so - find parent course
  const assignedMMS = getAssignedMMS(enrollment);

  const mmsMatch = assignedMMS.find((c) => c.recordId === recordId);
  if (mmsMatch) {
    courseMatch = assignedCourses.filter((c) => c.recordId === mmsMatch?.courseParentRecordId);
  }
  if (courseMatch.length > 0) {
    return courseMatch;
  }

  // Step 3 - it's a subject - find all matching courses
  const subjectMatch = [...(enrollment?.subjects ?? []), ...(allSubjects ?? [])].find((s) => s.recordId === recordId);
  if (subjectMatch) {
    courseMatch = assignedCourses.filter((c) => isSubjectAssignable(c.code, subjectMatch));
  }
  if (courseMatch.length > 0) {
    return courseMatch;
  }

  return assignedCourses;
};

export const useCoursesFor = (recordId?: string) => {
  const allSubjects = useSelector((state: IState) => [
    ...(state.enrollment?.subjects ?? []),
    ...(state.subjectSearch.results ?? []),
  ]);

  return useSelector(({ enrollment }: IState) => getCoursesFor(enrollment, recordId, allSubjects));
};
